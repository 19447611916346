import React, { useState } from "react";
import Header from "../componets/Header";
import Sidebar from "../componets/Sidebar";
import "../styles/Campaign.css";
import "../styles/common.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  exportCampaign,
  getCampaign,
  getCampaignList,
  getCampaigns,
  getMultiSubunitData,
  getMultiWorkshiftData,
  getUnitData,
  searchPermittedUser,
  searchUser,
} from "../redux/actionCreator";
import {
  campaignList,
  campaignSearchDetails,
  CampaignStatisticsLists,
  campaignStatisticsTotalCount,
  ListUnits,
  multiSubunitLists,
  multiWorkshiftLists,
  permittedUsers,
  updateRedux,
  users,
} from "../redux/TemplateSlice";
import ReactPaginate from "react-paginate";
import { Table } from "react-bootstrap";
import "moment-timezone";
import Moment from "react-moment";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useRef } from "react";

const CampaignStatistics = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const type_ref = useRef();

  const superadmin = localStorage.getItem("super_admin");


  const [page, setPage] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const pageCount = useSelector(campaignStatisticsTotalCount);
  const campaignLists = useSelector(CampaignStatisticsLists);
  const units = useSelector(ListUnits);
  const subUnitList = useSelector(multiSubunitLists);
  const workShiftList = useSelector(multiWorkshiftLists);
  const userList = useSelector(permittedUsers);
  const campaigntitles = useSelector(campaignList);
  const campaignPreviousSearch = useSelector(campaignSearchDetails)

  const [selected, setSelected] = useState([]);
  const [unit, setUnit] = useState([]);
  const [subunit, setSubunit] = useState([]);
  const [workshift, setWorkShift] = useState([]);
  const [user, setUser] = useState([]);
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [lastdate, setLastDate] = useState("");
  const [firstdate, setFirstDate] = useState("");
  const [campaignText, setCampaignText] = useState([]);
  const [campaignTitle, setCampaignTitle] = useState([]);

  useEffect(() => {
    document.title = "TSD - إحصائيات الرسائل النصية";
  }, []);

  useEffect(() => {
    if (campaignPreviousSearch) {
      dispatch(getCampaigns(campaignPreviousSearch));
    } else {
      let formData = new FormData();
      formData.append("page", 0);
      dispatch(getCampaigns(formData));
    }
    dispatch(getCampaignList());
    dispatch(getUnitData());
  }, []);

  const handlePageChange = (e) => {
    setPage(e.selected);
    // dispatch(getCampaign(e.selected,searchKey));
    let formData = new FormData();
    if (startdate?.length != 0) {
      console.log(startdate);
      let days =
        startdate.getDate() < 10
          ? `0${startdate.getDate()}`
          : startdate.getDate();
      let month =
        startdate.getMonth() + 1 < 10
          ? `0${startdate.getMonth() + 1}`
          : startdate.getMonth() + 1;
      let year = startdate.getFullYear();
      let startDate = year + "-" + month + "-" + days;
      if (startDate) {
        formData.append("from_date", startDate);
      }
    }
    if (enddate?.length != 0) {
      let Edays =
        enddate.getDate() < 10 ? `0${enddate.getDate()}` : enddate.getDate();
      let Emonth =
        enddate.getMonth() + 1 < 10
          ? `0${enddate.getMonth() + 1}`
          : enddate.getMonth() + 1;
      let Eyear = enddate.getFullYear();
      let endDate = Eyear + "-" + Emonth + "-" + Edays;
      if (endDate) {
        formData.append("to_date", endDate);
      }
    }
    unit?.forEach((item) => formData.append("unit[]", item._id));
    subunit?.forEach((item) => formData.append("subunit[]", item._id));
    user?.forEach((item) => formData.append("user_ids[]", item._id));
    if (campaignTitle?.length != 0 && campaignText?.length != 0) {
      formData.append(
        "campaign_name",
        campaignTitle[0] ? campaignTitle[0] : campaignText[0]
      );
    }
    if (phoneNumber?.length != 0) {
      formData.append('phone', phoneNumber)
    }
    formData.append("page", e.selected);
    dispatch(updateRedux({ key: "campaign_Search_Details", value: formData }))
    dispatch(getCampaigns(formData));
  };

  const onEditReporthandler = (ele) => {
    navigate(`/reports/${ele._id}`);
  };

  const onUnitSelecthandler = (value) => {
    setUnit(value);
    setSubunit([]);
    setWorkShift([]);
    if (value?.length) {
      let formData = new FormData();
      value?.forEach((item) => formData.append("unit[]", item._id));
      dispatch(
        getMultiSubunitData(formData, (data) => {
          let temp;
          temp = data?.filter((el) => {
            return subunit?.find((element) => {
              return element._id == el._id;
            });
          });
          setSubunit(temp);
        })
      );
    }
  };

  const onSubUnitSelecthandler = (value) => {
    setSubunit(value);
    setWorkShift([]);
    if (value?.length) {
      let formData = new FormData();
      unit?.forEach((item) => formData.append("unit[]", item._id));
      value?.forEach((item) => formData.append("subunit[]", item._id));
      dispatch(
        searchPermittedUser(formData, (data) => {
          let temp;
          temp = data?.filter((el) => {
            return user?.find((element) => {
              return element._id == el._id;
            });
          });
          setUser(temp);
        })
      );
    }
  };

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const onClearHandler = () => {
    setPhoneNumber('')
    setSubunit([]);
    setUnit([]);
    setWorkShift([]);
    setUser([]);
    setCampaignText([]);
    setCampaignTitle([]);
    setStartdate("");
    setEnddate("");
    type_ref.current.clear()
    let formData = new FormData();
    formData.append("page", 0);
    dispatch(getCampaigns(formData));
  };

  const onSearchHandler = () => {
    // dispatch(getCampaign(0,searchKey));
    if (
      unit?.length == 0 &&
      subunit?.length == 0 &&
      user?.length == 0 &&
      campaignText?.length == 0 &&
      campaignTitle?.length == 0 &&
      startdate?.length == 0 &&
      enddate?.length == 0 &&
      phoneNumber?.length == 0
    ) {
      toast.warning("الرجاء إضافة بيانات البحث", {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
    else {

      let formData = new FormData();
      if (startdate?.length != 0) {
        console.log(startdate);
        let days =
          startdate.getDate() < 10
            ? `0${startdate.getDate()}`
            : startdate.getDate();
        let month =
          startdate.getMonth() + 1 < 10
            ? `0${startdate.getMonth() + 1}`
            : startdate.getMonth() + 1;
        let year = startdate.getFullYear();
        let startDate = year + "-" + month + "-" + days;
        if (startDate) {
          formData.append("from_date", startDate);
        }
      }
      if (enddate?.length != 0) {
        let Edays =
          enddate.getDate() < 10 ? `0${enddate.getDate()}` : enddate.getDate();
        let Emonth =
          enddate.getMonth() + 1 < 10
            ? `0${enddate.getMonth() + 1}`
            : enddate.getMonth() + 1;
        let Eyear = enddate.getFullYear();
        let endDate = Eyear + "-" + Emonth + "-" + Edays;
        if (endDate) {
          formData.append("to_date", endDate);
        }
      }
      unit?.forEach((item) => formData.append("unit[]", item._id));
      subunit?.forEach((item) => formData.append("subunit[]", item._id));
      user?.forEach((item) => formData.append("user_ids[]", item._id));
      if (campaignTitle?.length != 0 && campaignText?.length != 0) {
        formData.append(
          "campaign_name",
          campaignTitle[0] ? campaignTitle[0] : campaignText[0]
        );
      }
      if (phoneNumber?.length != 0) {
        formData.append('phone', phoneNumber)
      }
      formData.append("page", page);
      dispatch(updateRedux({ key: "campaign_Search_Details", value: formData }))
      dispatch(getCampaigns(formData));
    }
  };

  const onExportCampaign = () => {
    let formData = new FormData();
    if (startdate?.length != 0) {
      console.log(startdate);
      let days =
        startdate.getDate() < 10
          ? `0${startdate.getDate()}`
          : startdate.getDate();
      let month =
        startdate.getMonth() + 1 < 10
          ? `0${startdate.getMonth() + 1}`
          : startdate.getMonth() + 1;
      let year = startdate.getFullYear();
      let startDate = year + "-" + month + "-" + days;
      if (startDate) {
        formData.append("from_date", startDate);
      }
    }
    if (enddate?.length != 0) {
      let Edays =
        enddate.getDate() < 10 ? `0${enddate.getDate()}` : enddate.getDate();
      let Emonth =
        enddate.getMonth() + 1 < 10
          ? `0${enddate.getMonth() + 1}`
          : enddate.getMonth() + 1;
      let Eyear = enddate.getFullYear();
      let endDate = Eyear + "-" + Emonth + "-" + Edays;
      if (endDate) {
        formData.append("to_date", endDate);
      }
    }
    unit?.forEach((item) => formData.append("unit[]", item._id));
    subunit?.forEach((item) => formData.append("subunit[]", item._id));
    user?.forEach((item) => formData.append("user_ids[]", item._id));
    if (campaignTitle?.length != 0 && campaignText?.length != 0) {
      formData.append(
        "campaign_name",
        campaignTitle[0] ? campaignTitle[0] : campaignText[0]
      );
    }
    if (phoneNumber?.length != 0) {
      formData.append('phone', phoneNumber)
    }
    formData.append("page", page);
    dispatch(exportCampaign(formData));
  };

  return (
    <>
      <Header />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>إحصائيات الرسائل النصية</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="form__wizard">
                <div className="card">
                  <div className="card-body">
                    <div className="row justify-content-end">
                      <div className="col-md-3 text-left">
                        <button
                          className="btn btn-secondary"
                          onClick={() => onExportCampaign()}
                        >
                          تحميل الإحصائيات
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <Form.Group className="mb-3" controlId="">
                          <Form.Label>عنوان الرسالة النصية</Form.Label>
                          <AsyncTypeahead
                            id="job_typeahead"
                            ref={type_ref}
                            labelKey="job"
                            className="width-100 typeaheadrtl"
                            inputProps={{
                              className: "floating-input",
                              autoComplete: "false",
                            }}
                            maxResults={10}
                            minLength={2}
                            selected={campaignTitle}
                            options={campaigntitles}
                            onInputChange={(query) => {
                              setCampaignText([query]);
                            }}
                            onChange={(option) => {
                              setCampaignTitle(option);
                            }}
                            placeholder={"عنوان الرسالة"}
                            autoComplete="off"
                            useCache={false}
                          />
                        </Form.Group>
                      </div>
                      {!superadmin == "" ? (
                        <div className="col-md-3">
                          <Form.Group
                            controlId="formSelectMultiple"
                            className="mb-3"
                          >
                            <Form.Label>القسم</Form.Label>
                            <Select
                              isMulti
                              name="unit"
                              placeholder="إختيار"
                              options={units}
                              hideSelectedOptions={false}
                              value={unit}
                              getOptionLabel={(option) => option.unit_name}
                              getOptionValue={(option) => option._id}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => onUnitSelecthandler(e)}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#31D68F",
                                  primary: "#0D7B58",
                                },
                              })}
                            />
                          </Form.Group>
                        </div>
                      ) : null}

                      {!superadmin == "" ? (
                        <div className="col-md-3">
                          <Form.Group
                            controlId="formSelectMultiple"
                            className="mb-3"
                          >
                            <Form.Label>الشعبة</Form.Label>
                            <Select
                              isMulti
                              name="subunit"
                              placeholder="إختيار"
                              isDisabled={unit?.length == 0 ? true : false}
                              options={subUnitList}
                              hideSelectedOptions={false}
                              value={subunit}
                              getOptionLabel={(option) => option.subunit_name}
                              getOptionValue={(option) => option._id}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => onSubUnitSelecthandler(e)}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#31D68F",
                                  primary: "#0D7B58",
                                },
                              })}
                            />
                          </Form.Group>
                        </div>
                      ) : null}

                      {!superadmin == "" ? (
                        <div className="col-md-3">
                          <Form.Group
                            controlId="formSelectMultiple"
                            className="mb-3"
                          >
                            <Form.Label>Users </Form.Label>
                            <Select
                              isMulti
                              name="users"
                              placeholder="إختيار"
                              options={userList}
                              isDisabled={subunit?.length == 0 ? true : false}
                              hideSelectedOptions={false}
                              value={user}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option._id}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => setUser(e)}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#31D68F",
                                  primary: "#0D7B58",
                                },
                              })}
                            />
                          </Form.Group>
                        </div>
                      ) : null}

                      <div className="col-md-3">
                        <Form.Group
                          controlId="formSelectMultiple"
                          className="mb-3 marginDatePicker"
                        >
                          <Form.Label>تاريخ البدء </Form.Label>
                          <DatePicker
                            selected={startdate}
                            onChange={(date) => {
                              setStartdate(date);
                              setEnddate("");
                            }}
                            selectsStart
                            dateFormat="yyyy-MM-dd"
                            startDate={startdate}
                            endDate={enddate}
                            filterDate={(date) => {
                              return moment() > date;
                            }}
                            placeholderText={"Select start date"}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-3">
                        <Form.Group
                          controlId="formSelectMultiple"
                          className="mb-3 marginDatePicker"
                        >
                          <Form.Label>الى </Form.Label>
                          <DatePicker
                            selected={enddate}
                            onChange={(date) => setEnddate(date)}
                            selectsEnd
                            dateFormat="yyyy-MM-dd"
                            // disabled={startdate ? false : true}
                            startDate={startdate}
                            endDate={enddate}
                            maxDate={new Date()}
                            minDate={startdate || moment().toDate()}
                            placeholderText={"Select end date"}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <Form.Label>رقم الهاتف</Form.Label>
                          <div className="keyword__search">
                            <div className="form-group w-100">
                              <input
                                type="text"
                                className="form-control mb-0"
                                value={phoneNumber}
                                placeholder="إضافة رقم الهاتف"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button
                          className="btn btn-secondary ml-2"
                          onClick={() => onClearHandler()}
                        >
                          مسح{" "}
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => onSearchHandler(page)}
                        >
                          البحث
                        </button>
                      </div>
                    </div>
                    <hr />
                    <Table striped borderless hover responsive>
                      <thead>
                        <tr>
                          <th>رقم الرسالة</th>
                          <th>عنوان الرسالة</th>
                          <th>المستخدم</th>
                          <th>مجموع الرسائل</th>
                          <th>تاريخ الإرسال</th>
                          <th>الحالة</th>
                        </tr>
                      </thead>
                      <tbody>
                        {campaignLists?.map((ele, i) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{ele?.name}</td>
                              <td>{ele?.created_by?.username}</td>
                              <td>{ele?.number_list?.length}</td>
                              <td>
                                <Moment
                                  date={ele?.send_date}
                                  format="YYYY-MM-DD"
                                />
                              </td>
                              <td>
                                <button
                                  className="btn-sm processButton"
                                  onClick={() => onEditReporthandler(ele)}
                                >
                                  تم الأرسال{" "}
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <div className="paginationStyle">
                      {pageCount > 1 ? (
                        <ReactPaginate
                          previousLabel={<>❮</>}
                          nextLabel={<>❯</>}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          // breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={5}
                          initialPage={page}
                          onPageChange={(e) => handlePageChange(e)}
                          containerClassName="pagination"
                          activeClassName="pageActive"
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Sidebar />
    </>
  );
};

export default CampaignStatistics;
