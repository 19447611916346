import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../componets/Header";
import Sidebar from "../componets/Sidebar";
import {
  getDashboardData,
  getDashboardUserData,
  getDateDashboardData,
  getModulePermission,
  getUnitData,
} from "../redux/actionCreator";
import {
  dashboardAllChart,
  DashboardChart,
  DashboardDatas,
  dashboardDateChart,
  DashboardUserChart,
  DashboardUsersLists,
  ListUnits,
  user_role,
} from "../redux/TemplateSlice";
import ReactApexChart from "react-apexcharts";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { toast } from "react-toastify";
import Select from "react-select";
import Form from "react-bootstrap/Form";

const Home = () => {
  const dispatch = useDispatch();

  const userRole = useSelector(user_role);
  const dashboardDatas = useSelector(DashboardDatas);
  const ChartData = useSelector(DashboardChart);
  const unitLists = useSelector(ListUnits);
  const DashboardUsers = useSelector(DashboardUsersLists);
  const userLists = useSelector(DashboardUserChart);
  const dateChart = useSelector(dashboardDateChart);
  const allChart = useSelector(dashboardAllChart);

  const [lastdate, setLastDate] = useState("");
  const [firstdate, setFirstDate] = useState("");
  const [filtertype, setFilterType] = useState("users");
  const [users, setUsers] = useState([]);
  const [units, setUnits] = useState([]);
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [series, setSeries] = useState([
    {
      name: "SMS Sent",
      data: [],
    },
  ]);
  const [options, setOptions] = useState({
    annotations: {
      points: [
        {
          x: "Names",
          label: {
            borderColor: "#31d68f",
            offsetY: 0,
            style: {
              color: "#fff",
              background: "#31d68f",
            },
          },
        },
      ],
    },
    colors: ["#31d68f", "#31d68f"],
    chart: {
      height: 350,
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        columnWidth: "80%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
    },
    grid: {
      row: {
        colors: ["#fff", "#fff"],
      },
    },
    xaxis: {
      labels: {
        rotate: 0,
      },
      categories: [],
      tickPlacement: "on",
      color: "#000",
    },
    // yaxis: {
    //   title: {
    //     text: 'Servings',
    //   },
    // },
    fill: {
      type: "solid",
      color: ["#31d68f"],
      borderColor: "#31d68f",
    },
  });
  const [series1, setSeries1] = useState([
    {
      name: "",
      data: [],
    },
  ]);
  const [options1, setOptions1] = useState({
    colors: ["#31d68f", "#31d68f"],
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
  });
  const [unitCountSection, setUnitCountSection] = useState([]);
  const [unitProgressbarSection, setUntProgressBarSection] = useState([]);
  const [startProgressDate, setStartProgressDate] = useState("");
  const [endProgressDate, setEndProgressDate] = useState("");

  const superadmin = localStorage.getItem("super_admin");

  useEffect(() => {
    document.title = "TSD - لوحة البيانات";
  }, []);

  useEffect(() => {
    dispatch(getUnitData());
    dispatch(getDashboardUserData());
  }, []);

  useEffect(() => {
    if (DashboardUsers?.length) {
      setUsers(
        DashboardUsers?.map((ele) => ({ label: ele.user, value: ele._id }))
      );
    }
  }, [DashboardUsers]);

  useEffect(() => {
    let userId = [];
    users?.forEach((ele) => userId.push(ele.value));
    let unitId = [];
    units?.forEach((ele) => unitId.push(ele.value));
    let data = {
      start_date: startdate,
      end_date: enddate,
      users: userId,
      units: unitId,
      unit1: [],
      report_start_date: "",
      report_end_date: "",
      report_unit: [],
    };
    dispatch(getModulePermission());
    if (data) {
      dispatch(getDashboardData(data));
    }
  }, [users, units]);

  // useEffect(() => {
  //   let unitId = [];
  //   units?.forEach((ele) => unitId.push(ele.value));
  //   let data = {
  //     start_date: startdate,
  //     end_date: enddate,
  //     users: [],
  //     units: unitId,
  //     unit1: [],
  //     report_start_date: "",
  //     report_end_date: "",
  //     report_unit: [],
  //   };
  //   dispatch(getModulePermission());
  //   if (data) {
  //     dispatch(getDashboardData(data));
  //   }
  // }, [units]);

  useEffect(() => {
    let data = {
      start_date: startdate,
      end_date: enddate,
      users: [],
      units: [],
    };
    dispatch(getDateDashboardData(data));
  }, []);

  useEffect(() => {
    if (dateChart) {
      setLastDate(allChart?.endDate);
      setFirstDate(allChart?.startDate);
      setSeries([
        {
          ...series,
          data: dateChart?.map((ele) => ele.count),
        },
      ]);
      setOptions({
        ...options,
        xaxis: {
          ...options.xaxis,
          categories: dateChart?.map((ele) => ele.fullDate),
        },
      });
    }
  }, [dateChart]);

  useEffect(() => {
    if (DashboardUsers) {
      setSeries1([
        {
          ...series1,
          name: "SMS Sent",
          data: userLists?.map((ele) => ele?.members_count),
        },
      ]);
      setOptions1({
        ...options,
        xaxis: {
          ...options1.xaxis,
          categories: userLists?.map((ele) => ele.user),
        },
      });
    }
  }, [ChartData]);

  const filterHandler = (e) => {
    if (e.target.value == "users") {
      setFilterType("users");
      let userId = [];
      users?.forEach((ele) => userId.push(ele.value));
      let data = {
        start_date: startdate,
        end_date: enddate,
        users: userId,
        units: [],
        unit1: [],
        report_start_date: "",
        report_end_date: "",
        report_unit: [],
      };
      if (data) {
        dispatch(getDashboardData(data));
      }
    } else {
      setFilterType("units");
      if (unitLists?.length) {
        setUnits(
          unitLists?.map((ele) => ({ label: ele.unit_name, value: ele._id }))
        );
      }
      let unitId = [];
      units?.forEach((ele) => unitId.push(ele.value));
      let data = {
        start_date: startdate,
        end_date: enddate,
        users: [],
        units: unitId,
        unit1: [],
        report_start_date: "",
        report_end_date: "",
        report_unit: [],
      };
      if (data) {
        dispatch(getDashboardData(data));
      }
    }
  };

  const dateFilterhandler = (e) => {
    let days =
      startdate.getDate() < 10
        ? `0${startdate.getDate()}`
        : startdate.getDate();
    let month =
      startdate.getMonth() + 1 < 10
        ? `0${startdate.getMonth() + 1}`
        : startdate.getMonth() + 1;
    let year = startdate.getFullYear();
    let startDate = year + "-" + month + "-" + days;

    let Edays =
      enddate.getDate() < 10 ? `0${enddate.getDate()}` : enddate.getDate();
    let Emonth =
      enddate.getMonth() + 1 < 10
        ? `0${enddate.getMonth() + 1}`
        : enddate.getMonth() + 1;
    let Eyear = enddate.getFullYear();
    let endDate = Eyear + "-" + Emonth + "-" + Edays;

    let data = {
      start_date: startDate,
      end_date: endDate,
      users: [],
      units: [],
    };
    dispatch(getDateDashboardData(data));
  };

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const onCountUnitSelecthandler = (value) => {
    setUnitCountSection(value);
  };

  const onClearCountHandler = () => {
    setUnitCountSection([]);
  };

  // const onSearchCountHandler = () => {
  //   let unitId = [];
  //   unitCountSection?.forEach((ele) => unitId.push(ele._id));
  //   let data = {
  //     start_date: "",
  //     end_date: "",
  //     users: [],
  //     units: [],
  //     unit1: unitId,
  //     report_start_date: "",
  //     report_end_date: "",
  //     report_unit: [],
  //   };
  //   if (data) {
  //     dispatch(getDashboardData(data));
  //   }
  // };

  const onUnitProgressBarSelecthandler = (value) => {
    setUntProgressBarSection(value);
  };

  const onClearProgressBarHandler = () => {
    setUntProgressBarSection([]);
  };

  const onSearchProgressBarHandler = () => {
    let unitIds = [];
    unitCountSection?.forEach((ele) => unitId.push(ele._id));
    let unitId = [];
    unitProgressbarSection?.forEach((ele) => unitId.push(ele._id));
    let userId = [];
    users?.forEach((ele) => userId.push(ele.value));
    let s_date = "";
    if (startProgressDate?.length != 0) {
      let days =
        startProgressDate.getDate() < 10
          ? `0${startProgressDate.getDate()}`
          : startProgressDate.getDate();
      let month =
        startProgressDate.getMonth() + 1 < 10
          ? `0${startProgressDate.getMonth() + 1}`
          : startProgressDate.getMonth() + 1;
      let year = startProgressDate.getFullYear();
      s_date = year + "-" + month + "-" + days;
    }
    let e_date = "";
    if (endProgressDate?.length != 0) {
      let Edays =
        endProgressDate.getDate() < 10
          ? `0${endProgressDate.getDate()}`
          : endProgressDate.getDate();
      let Emonth =
        endProgressDate.getMonth() + 1 < 10
          ? `0${endProgressDate.getMonth() + 1}`
          : endProgressDate.getMonth() + 1;
      let Eyear = endProgressDate.getFullYear();
      e_date = Eyear + "-" + Emonth + "-" + Edays;
    }
    let data = {
      start_date: "",
      end_date: "",
      users: userId,
      units: [],
      unit1: unitIds,
      report_start_date: s_date,
      report_end_date: e_date,
      report_unit: unitId,
    };
    if (data) {
      dispatch(getDashboardData(data));
    }
  };

  return (
    <>
      <Header />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>لوحة البيانات</h1>
          {/* <p> لوحة البيانات - الأشهر الثلاثة الأخيرة</p> */}
        </div>
        <section className="section dashboard tsd__dashboard">
          <div className="row">
            {/* <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-3">
                    {!superadmin == "" ? (
                      <>
                        <div className="col-md-4 ml-2">
                          <Form.Group
                            controlId="formSelectMultiple"
                            className="mb-3"
                          >
                            <Form.Label>القسم</Form.Label>
                            <Select
                              isMulti
                              name="unit"
                              placeholder="إختيار"
                              options={unitLists}
                              hideSelectedOptions={false}
                              value={unitCountSection}
                              getOptionLabel={(option) => option.unit_name}
                              getOptionValue={(option) => option._id}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => onCountUnitSelecthandler(e)}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#31D68F",
                                  primary: "#0D7B58",
                                },
                              })}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6 mt-4 pt-2">
                          <button
                            className="btn btn-secondary ml-2"
                            onClick={() => onClearCountHandler()}
                          >
                            مسح{" "}
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() => onSearchCountHandler()}
                          >
                            البحث
                          </button>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="main__grids">
                        <p>اجمالي الرسائل</p>
                        <h2>{dashboardDatas?.total_messages}</h2>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="main__grids">
                        <p>الرسائل الشهرية</p>
                        <h2 className="danger">
                          {dashboardDatas?.no_of_messages_permonth}
                        </h2>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="main__grids">
                        <p>الرسائل الأسبوعية</p>
                        <h2>{dashboardDatas?.no_of_messages_perweek}</h2>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="main__grids border-0">
                        <p>الرسائل المرسلة اليوم</p>
                        <h2>{dashboardDatas?.no_of_messages_today}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <>
                      {!superadmin == "" ? (
                        <div className="col-md-3">
                          <Form.Group
                            controlId="formSelectMultiple"
                            className="mb-3"
                          >
                            <Form.Label>القسم</Form.Label>
                            <Select
                              isMulti
                              name="unit"
                              placeholder="إختيار"
                              options={unitLists}
                              hideSelectedOptions={false}
                              value={unitProgressbarSection}
                              getOptionLabel={(option) => option.unit_name}
                              getOptionValue={(option) => option._id}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) =>
                                onUnitProgressBarSelecthandler(e)
                              }
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#31D68F",
                                  primary: "#0D7B58",
                                },
                              })}
                            />
                          </Form.Group>
                        </div>
                      ) : null}

                      <div className="col-md-3">
                        <Form.Group
                          controlId="formSelectMultiple"
                          className="mb-3 marginDatePicker"
                        >
                          <Form.Label>تاريخ البدء </Form.Label>
                          <DatePicker
                            selected={startProgressDate}
                            onChange={(date) => {
                              setStartProgressDate(date);
                              setEndProgressDate("");
                            }}
                            selectsStart
                            dateFormat="yyyy-MM-dd"
                            startDate={startProgressDate}
                            endDate={endProgressDate}
                            placeholderText={"Select Start Date"}
                            filterDate={(date) => {
                              return moment() > date;
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-3">
                        <Form.Group
                          controlId="formSelectMultiple"
                          className="mb-3 marginDatePicker"
                        >
                          <Form.Label>الى </Form.Label>
                          <DatePicker
                            selected={endProgressDate}
                            onChange={(date) => setEndProgressDate(date)}
                            selectsEnd
                            dateFormat="yyyy-MM-dd"
                            startDate={startProgressDate}
                            endDate={endProgressDate}
                            maxDate={new Date()}
                            minDate={startProgressDate || moment().toDate()}
                            placeholderText={"Select End Date"}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-3 mt-4 pt-2">
                        <button
                          className="btn btn-secondary ml-2 pt-2"
                          style={{ padding: "10px 22px" }}
                          onClick={() => (onClearProgressBarHandler(), onClearCountHandler())}
                        >
                          مسح{" "}
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => onSearchProgressBarHandler()}
                        >
                          البحث
                        </button>
                      </div>
                    </>
                  </div>
                  <div
                    className={!superadmin == "" ? "col-md-12" : "col-md-12"}
                  >
                    <div className="traffic__performance">
                      <div className="performance">
                        <div className="row">
                          <div className="col-md-12 center-right">
                            <div className="title">
                              <p style={{ fontWeight: "300" }}>
                                تحليل حركة المرور اليوم
                              </p>
                            </div>
                            <div className="status__bar">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="progress__line">
                                    <h4>{dashboardDatas?.processing}%</h4>
                                    <div className="progress">
                                      <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{
                                          width: `${dashboardDatas?.processing}%`,
                                        }}
                                        aria-valuenow={10}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                      <span class="tooltip-content">
                                        {dashboardDatas?.processing_count}
                                      </span>
                                    </div>
                                    <p>قيد الاجراء</p>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="progress__line">
                                    <h4>{dashboardDatas?.delivered}%</h4>
                                    <div className="progress">
                                      <div
                                        className="progress-bar bg-primary"
                                        role="progressbar"
                                        style={{
                                          width: `${dashboardDatas?.delivered}%`,
                                        }}
                                        aria-valuenow={10}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                      <span class="tooltip-content">
                                        {dashboardDatas?.delivered_count}
                                      </span>
                                    </div>
                                    <p>تم الإرسال</p>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="progress__line">
                                    <h4>{dashboardDatas?.failed}%</h4>
                                    <div className="progress">
                                      <div
                                        className="progress-bar bg-danger"
                                        role="progressbar"
                                        style={{
                                          width: `${dashboardDatas?.failed}%`,
                                        }}
                                        aria-valuenow={10}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                      <span class="tooltip-content">
                                        {dashboardDatas?.failed_count}
                                      </span>
                                    </div>
                                    <p>لم يتم الإرسال</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="main__grids">
                        <p>الرسائل المرسلة </p>
                        <h2>{dashboardDatas?.no_of_messages_today}</h2>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="main__grids">
                        <p>الرسائل الأسبوعية</p>
                        <h2>{dashboardDatas?.no_of_messages_perweek}</h2>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="main__grids border-0">
                        <p>اجمالي الرسائل</p>
                        <h2>{dashboardDatas?.total_messages}</h2>
                      </div>
                    </div>
                    
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div
                      className={!superadmin == "" ? "col-md-6" : "col-md-12"}
                    >
                      <div className="weekly__traffic">
                        <div className="chart__filter">
                          <div className="form-group">
                            <label>تاريخ البدء</label>
                            <DatePicker
                              selected={startdate}
                              onChange={(date) => {
                                setStartdate(date);
                                setEnddate("");
                              }}
                              selectsStart
                              dateFormat="yyyy-MM-dd"
                              startDate={startdate}
                              endDate={enddate}
                              placeholderText={firstdate}
                              filterDate={(date) => {
                                return moment() > date;
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label>تاريخ الانتهاء</label>
                            <DatePicker
                              selected={enddate}
                              onChange={(date) => setEnddate(date)}
                              selectsEnd
                              dateFormat="yyyy-MM-dd"
                              disabled={startdate ? false : true}
                              startDate={startdate}
                              endDate={enddate}
                              maxDate={
                                new Date().getTime() >
                                  new Date(startdate).addDays(30).getTime()
                                  ? new Date(startdate).addDays(30)
                                  : new Date()
                              }
                              minDate={startdate || moment().toDate()}
                              placeholderText={
                                startdate == false
                                  ? lastdate
                                  : "Select end date"
                              }
                            />
                          </div>
                          <div className="form-search">
                            <button
                              type="submit"
                              onClick={(e) => dateFilterhandler(e)}
                              className="btn-filter"
                            >
                              <i className="fa fa-search"></i>
                            </button>
                          </div>
                        </div>
                        <div className="sms__chart">
                          <ReactApexChart
                            options={options}
                            series={series}
                            type="bar"
                            height={300}
                          />
                          <div className="chart__itle">
                            <h3>عدد الرسائل</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!superadmin == "" && (
                      <div className="col-md-6">
                        <div className="weekly__traffic">
                          <div className="title">
                            <div className="form-group">
                              {filtertype == "units" ? (
                                <MultiSelect
                                  options={unitLists?.map((ele) => ({
                                    label: ele.unit_name,
                                    value: ele._id,
                                  }))}
                                  value={units}
                                  onChange={setUnits}
                                  className="filter-select"
                                  labelledBy={"Select"}
                                  style={{ direction: "rtl" }}
                                />
                              ) : filtertype == "users" ? (
                                <MultiSelect
                                  options={DashboardUsers?.map((ele) => ({
                                    label: ele.user[0],
                                    value: ele._id,
                                  }))}
                                  value={users}
                                  onChange={setUsers}
                                  className="filter-select"
                                />
                              ) : null}
                            </div>
                            <div className="form-group select-filter">
                              <div className="custom-select no-label">
                                <select
                                  className="form-select form-select mb-0"
                                  aria-label="Default select example"
                                  value={filtertype}
                                  onChange={(e) => filterHandler(e)}
                                >
                                  <option value="users">الموظفين</option>
                                  <option value="units">القسم</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="sms__chart">
                            <ReactApexChart
                              options={options1}
                              series={series1}
                              type="bar"
                              height={300}
                            />
                            <div className="chart__itle">
                              <h3>المستخدمين / عدد الرسائل </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Sidebar />
    </>
  );
};

export default Home;
