import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../componets/Header";
import Sidebar from "../componets/Sidebar";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import "../styles/Campaign.css";
import "../styles/common.css";
import "../styles/contacts.css";
import {
  updateRedux,
  csvContact,
  selectedContactList,
  editUserSearchdata,
  ListUnits,
  RankLists,
  multiSubunitLists,
  multiWorkshiftLists,
} from "../redux/TemplateSlice";
import {
  getUnitData,
  getDesignationData,
  getRankData,
  updateContactBook,
  getSampleCSV,
  uploadContactCSV,
  getContactBookDetails,
  searchContactBook,
  getMultiWorkshiftData,
  getMultiSubunitData,
  searchEditContactBook,
} from "../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";

const EditContactBook = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const contactList = useSelector(selectedContactList);
  const fullContact = useSelector(csvContact);
  const editSearchData = useSelector(editUserSearchdata);
  const units = useSelector(ListUnits);
  const rankList = useSelector(RankLists);
  const subUnitList = useSelector(multiSubunitLists);
  const workShiftList = useSelector(multiWorkshiftLists);

  const [groupName, setGroupName] = useState("");
  const [userId, setUserId] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [err, setErr] = useState({
    phone: "",
  });
  const [phoneNumberArray, setPhoneNumberArray] = useState([]);
  const [page, setPage] = useState(0);
  const [usersDetails, setUsersDetails] = useState([]);
  const [pageCount, setPageCount] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [csv, setCSV] = useState([]);
  const [addedCsv, setAddedCsv] = useState([]);
  const [contact, setContact] = useState([]);
  const [csvCount, setCsvCount] = useState(null);
  const [csvPage, setCsvPage] = useState(0);
  const [csvSelected, setCSVSelected] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [username, setUsername] = useState("");
  const [gender, setGender] = useState("");
  const [subunit, setSubunit] = useState([]);
  const [unit, setUnit] = useState([]);
  const [workshift, setWorkShift] = useState([]);
  const [rank, setRank] = useState([]);
  const [searchPage, setSearchPage] = useState(0);
  const [searchPageCount, setSearchPageCount] = useState(null);
  const [searchUsersDetails, setSearchUsersDetails] = useState([]);
  const [searchUserId, setSearchUserId] = useState([]);
  const [selectedSearchUsers, setSelectedSearchUsers] = useState([]);
  const [finalPage, setFinalPage] = useState(0);
  const [finalUsersDetails, setFinalUsersDetails] = useState([]);
  const [finalPageCount, setFinalPageCount] = useState(null);
  const [finalSelectedUsers, setFinalSelectedUsers] = useState([]);
  const [finalUsers, setFinalUsers] = useState([]);
  const [finalUserId, setFinalUserId] = useState([]);

  useEffect(() => {
    document.title = "TSD - إضافة جهة اتصال";
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getContactBookDetails(id));
    }
  }, [id]);

  useEffect(() => {
    dispatch(getUnitData());
    // dispatch(getDesignationData());
    dispatch(getRankData());
  }, []);

  useEffect(() => {
    if (Object.keys(contactList)?.length) {
      let tempUser = [];
      let tempSelectedUser = [];
      contactList?.selected_users?.forEach((ele) => {
        tempUser.push(ele._id);
        tempSelectedUser.push(ele);
      });
      setUserId([...tempUser]);
      setSelectedUsers([...tempSelectedUser]);
      setGroupName(contactList.name);
      setPhoneNumberArray([...contactList?.manual_contacts]);
      setAddedCsv([...contactList?.csv_contacts]);
    }
  }, [contactList]);

  useEffect(() => {
    if (contactList?.selected_users) {
      pageDataHandler(page);
      setPageCount(Math.ceil(contactList?.selected_users?.length / 10));
    }
  }, [contactList?.selected_users]);

  const pageDataHandler = (pag) => {
    if (
      contactList?.selected_users.slice(pag * 10, pag * 10 + 10)?.length == 0 &&
      pag != 0
    ) {
      setPage(pag - 1);
      setUsersDetails(
        contactList?.selected_users.slice((pag - 1) * 10, (pag - 1) * 10 + 10)
      );
    } else {
      setUsersDetails(
        contactList?.selected_users.slice(pag * 10, pag * 10 + 10)
      );
    }
  };

  useEffect(() => {
    if (editSearchData) {
      editPageDataHandler(searchPage);
      setSearchPageCount(Math.ceil(editSearchData?.length / 10));
      let id = [];
      editSearchData?.forEach((ele) => {
        id.push(ele._id);
      });
      setSearchUserId([...id]);
      setSelectedSearchUsers([...editSearchData]);
    }
  }, [editSearchData]);

  const editPageDataHandler = (pag) => {
    if (
      editSearchData.slice(pag * 10, pag * 10 + 10)?.length == 0 &&
      pag != 0
    ) {
      setSearchPage(pag - 1);
      setSearchUsersDetails(
        editSearchData.slice((pag - 1) * 10, (pag - 1) * 10 + 10)
      );
    } else {
      setSearchUsersDetails(editSearchData.slice(pag * 10, pag * 10 + 10));
    }
  };

  const onMoveHandler = () => {
    setFinalUsers([...finalUsers, ...selectedSearchUsers]);
    setKeyword("");
    setGender("Select Gender");
    setSubunit([]);
    setUnit([]);
    setWorkShift([]);
    setRank([]);
    setEmployeeId("");
    setUsername("");
    dispatch(updateRedux({ key: "editUserSearchdata", value: [] }));
    setSearchPage(0);
    setSearchPageCount(null);
    setSearchUsersDetails([]);
    setSearchUserId([]);
    setSelectedSearchUsers([]);
  };

  useEffect(() => {
    if (finalUsers) {
      finalPageDataHandler(page);
      setFinalPageCount(Math.ceil(finalUsers?.length / 10));
      let id = [];
      finalUsers?.forEach((ele) => {
        id.push(ele._id);
      });
      setFinalUserId([...id]);
      setFinalSelectedUsers([...finalUsers]);
    }
  }, [finalUsers]);

  const finalPageDataHandler = (pag) => {
    if (finalUsers.slice(pag * 10, pag * 10 + 10)?.length == 0 && pag != 0) {
      setFinalPage(pag - 1);
      setFinalUsersDetails(
        finalUsers.slice((pag - 1) * 10, (pag - 1) * 10 + 10)
      );
    } else {
      setFinalUsersDetails(finalUsers.slice(pag * 10, pag * 10 + 10));
    }
  };

  const handleFinalPageChange = (e) => {
    setFinalPage(e.selected);
    finalPageDataHandler(Number(e.selected));
  };

  const selectAllFinalUserHandler = (e) => {
    if (e.target.checked) {
      let id = [];
      finalUsers?.forEach((ele) => {
        id.push(ele._id);
      });
      setFinalUserId([...id]);
      setFinalSelectedUsers([...finalUsers]);
    } else {
      setFinalUserId([]);
      setFinalSelectedUsers([]);
      setFinalUsersDetails([])
      setFinalUsers([])
    }
  };

  useEffect(() => {
    if (fullContact) {
      setCSVSelected([...fullContact]);
      csvDataHandler(page);
      setCsvCount(Math.ceil(fullContact?.length / 10));
    }
  }, [fullContact]);

  const csvDataHandler = (pag) => {
    setContact(fullContact.slice(pag * 10, pag * 10 + 10));
  };

  const onNumberChangeHandler = (e) => {
    if (e.key == "Enter") {
      let temp;
      let validNumber = [];
      let invalidNumber = [];
      temp = phoneNumber.split(",");
      temp.forEach((ele) => {
        if (ele.length < 9) {
          invalidNumber.push(ele);
          setErr({
            ...err,
            phone: "الرجاء إدخال رقم صحيح",
          });
        } else {
          if (ele.length == 9 || ele.length <= 13) {
            validNumber.push(ele);
            setErr({
              ...err,
              phone: "",
            });
          } else {
            invalidNumber.push(ele);
            setErr({
              ...err,
              phone: "الرجاء إدخال رقم صحيح",
            });
          }
        }
      });
      setPhoneNumberArray([...phoneNumberArray, ...validNumber]);
      if (invalidNumber.length) {
        setPhoneNumber(invalidNumber.join(","));
      } else {
        setPhoneNumber("");
      }
    }
  };

  const onDeleteNumberhandler = (num) => {
    let temp;
    temp = phoneNumberArray.filter((ele) => ele != num);
    setPhoneNumberArray([...temp]);
  };

  const onDeleteCsvhandler = (num) => {
    let temp;
    temp = addedCsv.filter((ele) => ele != num);
    setAddedCsv([...temp]);
  };

  const updateContactHandler = (e) => {
    e.preventDefault();
    if (groupName == "") {
      toast.warning("الرجاء إدخال اسم المجموعة", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else if (
      phoneNumberArray?.length == 0 &&
      csvSelected?.length == 0 &&
      selectedUsers?.length == 0 &&
      finalSelectedUsers?.length == 0 &&
      addedCsv?.length == 0
    ) {
      toast.warning("الرجاء إضافة أرقام", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      let data = {
        id: id,
        name: groupName,
      };
      if (phoneNumberArray?.length) {
        data = {
          ...data,
          manual_contacts: phoneNumberArray,
        };
      }
      if (csvSelected?.length) {
        data = {
          ...data,
          csv_contacts: csvSelected,
        };
      }
      if (addedCsv?.length) {
        if (data?.csv_contacts?.length) {
          data = {
            ...data,
            csv_contacts: [...data.csv_contacts, ...addedCsv],
          };
        } else {
          data = {
            ...data,
            csv_contacts: [data.csv_contacts, ...addedCsv],
          };
        }
      }
      if (finalSelectedUsers?.length) {
        let userArr = [];
        finalSelectedUsers?.forEach((item, i) => {
          userArr.push(item._id);
        });
        if (data.final_users?.length) {
          data = {
            ...data,
            final_users: [...data.final_users, ...userArr],
          };
        } else {
          data = {
            ...data,
            final_users: userArr,
          };
        }
      }
      if (selectedUsers?.length) {
        let userArr = [];
        selectedUsers?.forEach((item, i) => {
          userArr.push(item._id);
        });
        if (data.final_users?.length) {
          data = {
            ...data,
            final_users: [...data.final_users, ...userArr],
          };
        } else {
          data = {
            ...data,
            final_users: userArr,
          };
        }
      }
      dispatch(
        updateContactBook(data, 0, "", () => {
          toast.success("تم رفع الملف بنجاح", {
            position: "bottom-center",
            autoClose: 3000,
          });
          setGroupName("");
          setPhoneNumberArray([]);
          setUsersDetails([]);
          setPageCount(null);
          setSelectedUsers([]);
          setContact([]);
          setCsvCount(0);
          setCSVSelected([]);
          setCsvPage(0);
          setPage(0);
          dispatch(updateRedux({ key: "selectedContactList", value: {} }));
          dispatch(updateRedux({ key: "csvContact", value: [] }));
          dispatch(updateRedux({ key: "editUserSearchdata", value: [] }));
          setSearchPage(0);
          setSearchPageCount(null);
          setSearchUsersDetails([]);
          setSearchUserId([]);
          setSelectedSearchUsers([]);
          navigate("/contact");
        })
      );
    }
  };

  const onCancelHandler = () => {
    navigate(`/contact`);
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    pageDataHandler(Number(e.selected));
  };

  const handleSearchPageChange = (e) => {
    setSearchPage(e.selected);
    editPageDataHandler(Number(e.selected));
  };

  const selectAllUserHandler = (e) => {
    if (e.target.checked) {
      let id = [];
      contactList?.selected_users?.forEach((ele) => {
        id.push(ele._id);
      });
      setUserId([...id]);
      setSelectedUsers([...contactList?.selected_users]);
    } else {
      setUserId([]);
      setSelectedUsers([]);
      setUsersDetails([])
    }
  };

  const selectAllSearchUserHandler = (e) => {
    if (e.target.checked) {
      let id = [];
      editSearchData?.forEach((ele) => {
        id.push(ele._id);
      });
      setSearchUserId([...id]);
      setSelectedSearchUsers([...editSearchData]);
    } else {
      setSearchUserId([]);
      setSelectedSearchUsers([]);
    }
  };

  const handleCsvPageChange = (e) => {
    setCsvPage(e.selected);
    csvDataHandler(Number(e.selected));
  };

  const selectAllContactHandler = (e) => {
    if (e.target.checked) {
      setCSVSelected([...fullContact]);
    } else {
      setCSVSelected([]);
    }
  };

  const onDownloadSample = () => {
    dispatch(getSampleCSV());
  };

  const onFileUploadHandler = () => {
    if (csv?.length) {
      let formData = new FormData();
      formData.append("contact_list", csv[0]);
      dispatch(
        uploadContactCSV(formData, () => {
          setCSV([]);
        })
      );
    } else {
      toast.warning("يرجى تحميل ملف CSV", {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
  };

  const onUploadHandler = (e) => {
    setCSV(e.target.files);
  };

  const onSearchHandler = (pag) => {
    if (
      keyword?.length == 0 &&
      (gender?.length == 0 || gender == "Select Gender") &&
      unit?.length == 0 &&
      subunit?.length == 0 &&
      workshift?.length == 0 &&
      rank?.length == 0 &&
      username?.length == 0 &&
      employeeId?.length == 0
    ) {
      toast.warning("الرجاء إضافة بيانات البحث", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      setSearchPage(0);
      setSearchPageCount(null);
      setSearchUsersDetails([]);
      setSearchUserId([]);
      setSelectedSearchUsers([]);
      let formData = new FormData();
      formData.append("keyword", keyword);
      formData.append("employee_id", employeeId);
      formData.append("username", username);
      if (gender?.length) {
        if (gender == "Select Gender") {
          formData.append("gender", "");
        } else {
          formData.append("gender", gender);
        }
      }
      unit?.forEach((item) => formData.append("unit[]", item._id));
      subunit?.forEach((item) => formData.append("subunit[]", item._id));
      workshift?.forEach((item) => formData.append("workshift[]", item._id));
      rank?.forEach((item) => formData.append("rank[]", item._id));
      dispatch(searchEditContactBook(formData));
    }
  };

  const onClearHandler = () => {
    setKeyword("");
    setGender("Select Gender");
    setSubunit([]);
    setUnit([]);
    setWorkShift([]);
    setRank([]);
    setEmployeeId("");
    setUsername("");
    dispatch(updateRedux({ key: "editUserSearchdata", value: [] }));
    setSearchPage(0);
    setSearchPageCount(null);
    setSearchUsersDetails([]);
    setSearchUserId([]);
    setSelectedSearchUsers([]);
    dispatch(updateRedux({ key: "multiWorkshiftLists", value: [] }))
    dispatch(updateRedux({ key: "multiSubunitLists", value: [] }))
  };

  const onUnitSelecthandler = (value) => {
    setUnit(value);
    setSubunit([]);
    setWorkShift([]);
    if (value?.length) {
      let formData = new FormData();
      value?.forEach((item) => formData.append("unit[]", item._id));
      dispatch(
        getMultiSubunitData(formData, (data) => {
          let temp;
          temp = data?.filter((el) => {
            return subunit?.find((element) => {
              return element._id == el._id;
            });
          });
          setSubunit(temp);
        })
      );
    } else {
      dispatch(updateRedux({ key: "multiSubunitLists", value: [] }))
    }
  };

  const onSubUnitSelecthandler = (value) => {
    setSubunit(value);
    setWorkShift([]);
    if (value?.length) {
      let formData = new FormData();
      value?.forEach((item) => formData.append("subunit[]", item._id));
      dispatch(
        getMultiWorkshiftData(formData, (data) => {
          let temp;
          temp = data?.filter((el) => {
            return workshift?.find((element) => {
              return element._id == el._id;
            });
          });
          setWorkShift(temp);
        })
      );
    } else {
      dispatch(updateRedux({ key: "multiWorkshiftLists", value: [] }))
    }
  };

  const onRemoveAllSearchUserHandler = () => {
    setSearchUserId([]);
    setSelectedSearchUsers([]);
  };

  const onAddAllSearchUserHandler = () => {
    let id = [];
    editSearchData?.forEach((ele) => {
      id.push(ele._id);
    });
    setSearchUserId([...id]);
    setSelectedSearchUsers([...editSearchData]);
  };

  const onRemoveSearchUserHandler = (value) => {
    setSearchUserId(searchUserId.filter((item) => item != value._id));
    setSelectedSearchUsers(
      selectedSearchUsers?.filter((item) => item._id != value._id)
    );
  };

  const onAddSearchUserHandler = (value) => {
    setSearchUserId([...searchUserId, value._id]);
    setSelectedSearchUsers([...selectedSearchUsers, value]);
  };

  const onRemoveAllFinalHandler = () => {
    setFinalUserId([]);
    setFinalSelectedUsers([]);
    setFinalUsersDetails([])
    setFinalUsers([])
  };

  const onAddAllFinalHandler = () => {
    let id = [];
    finalUsers?.forEach((ele) => {
      id.push(ele._id);
    });
    setFinalUserId([...id]);
    setFinalSelectedUsers([...finalUsers]);
  };

  const onRemoveFinalHandler = (value) => {
    setFinalUserId(finalUserId.filter((item) => item != value._id));
    setFinalSelectedUsers(
      finalSelectedUsers?.filter((item) => item._id != value._id)
    );
    setFinalUsersDetails(finalUsersDetails?.filter((item) => item._id != value._id))
    setFinalUsers(finalUsers?.filter((item) => item._id != value._id))
  };

  const onAddFinalHandler = (value) => {
    setFinalUserId([...finalUserId, value._id]);
    setFinalSelectedUsers([...finalSelectedUsers, value]);
  };

  const onRemoveAllUserHandler = () => {
    setUserId([]);
    setSelectedUsers([]);
    setUsersDetails([])
  };

  const onAddAllUserHandler = () => {
    let id = [];
    contactList?.selected_users?.forEach((ele) => {
      id.push(ele._id);
    });
    setUserId([...id]);
    setSelectedUsers([...contactList?.selected_users]);
  };

  const onRemoveUserHandler = (value) => {
    setUserId(userId.filter((item) => item != value._id));
    setSelectedUsers(selectedUsers?.filter((item) => item._id != value._id));
    setUsersDetails(
      usersDetails?.filter(
        (item) => item._id != value._id
      )
    );
  };

  const onAddUserHandler = (value) => {
    setUserId([...userId, value._id]);
    setSelectedUsers([...selectedUsers, value]);
  };

  const onRemoveContactAllHandler = () => {
    setCSVSelected([]);
  };

  const onAddAllContactHandler = () => {
    setCSVSelected([...fullContact]);
  };

  const onRemoveContactHandler = (value) => {
    setCSVSelected(csvSelected.filter((item) => item != value));
  };

  const onAddContactHandler = (value) => {
    setCSVSelected([...csvSelected, value]);
  };

  return (
    <>
      <Header />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1> كتاب الاتصال</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="form__wizard">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between cardMtop align-items-center">
                      <h5 className="m-0 fw-bold">إنشاء مجموعة</h5>
                      <button className="btn btn-secondary"
                        onClick={() => onCancelHandler()}
                      >
                        Back{" "}
                      </button>
                      {/* <button className = 'btn btn-danger m-0 mr-3' onClick = {() => onCancelHandler()}>Back </button> */}
                    </div>
                    <hr />
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="form-group">
                            <label htmlFor="">اسم المجموعة</label>
                            <div className="d-flex align-items-center">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="اسم المجموعة"
                                value={groupName}
                                onChange={(e) => setGroupName(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-md-3">
                          <Form.Group
                            controlId="formSelectMultiple"
                            className="mb-3"
                          >
                            <Form.Label>التصنيف</Form.Label>
                            <MultiSelect
                              options={rankList?.map((ele) => ({
                                label: ele.rank,
                                value: ele.rank,
                                _id: ele._id
                              }))}
                              value={rank}
                              onChange={(e) => setRank(e)}
                              className="basic-multi-select"
                              labelledBy={"Select"}
                              style={{ direction: "rtl" }}
                            />
                            {/* <Select
                              isMulti
                              name="rank"
                              placeholder="إختيار"
                              options={rankList}
                              hideSelectedOptions={false}
                              value={rank}
                              getOptionLabel={(option) => option.rank}
                              getOptionValue={(option) => option._id}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => setRank(e)}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#31D68F",
                                  primary: "#0D7B58",
                                },
                              })}
                            /> */}
                          </Form.Group>
                        </div>
                        <div className="col-md-3">
                          <Form.Group
                            controlId="formSelectMultiple"
                            className="mb-3"
                          >
                            <Form.Label>الوحدة</Form.Label>
                            <MultiSelect
                              options={units?.map((ele) => ({
                                label: ele.unit_name,
                                value: ele.unit_name,
                                _id: ele._id
                              }))}
                              value={unit}
                              onChange={(e) => onUnitSelecthandler(e)}
                              className="basic-multi-select"
                              labelledBy={"Select"}
                              style={{ direction: "rtl" }}
                            />
                            {/* <Select
                              isMulti
                              name="unit"
                              placeholder="إختيار"
                              options={units}
                              hideSelectedOptions={false}
                              value={unit}
                              getOptionLabel={(option) => option.unit_name}
                              getOptionValue={(option) => option._id}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => onUnitSelecthandler(e)}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#31D68F",
                                  primary: "#0D7B58",
                                },
                              })}
                            /> */}
                          </Form.Group>
                        </div>
                        <div className="col-md-3">
                          <Form.Group
                            controlId="formSelectMultiple"
                            className="mb-3"
                          >
                            <Form.Label>الوحدة الفرعية</Form.Label>
                            <MultiSelect
                              options={subUnitList?.map((ele) => ({
                                label: ele.subunit_name,
                                value: ele.subunit_name,
                                _id: ele._id
                              }))}
                              value={subunit}
                              onChange={(e) => onSubUnitSelecthandler(e)}
                              className="basic-multi-select"
                              labelledBy={"Select"}
                              style={{ direction: "rtl" }}
                            />
                            {/* <Select
                              isMulti
                              name="subunit"
                              placeholder="إختيار"
                              isDisabled={unit?.length == 0 ? true : false}
                              options={subUnitList}
                              hideSelectedOptions={false}
                              value={subunit}
                              getOptionLabel={(option) => option.subunit_name}
                              getOptionValue={(option) => option._id}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => onSubUnitSelecthandler(e)}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#31D68F",
                                  primary: "#0D7B58",
                                },
                              })}
                            /> */}
                          </Form.Group>
                        </div>
                        <div className="col-md-3">
                          <Form.Group
                            controlId="formSelectMultiple"
                            className="mb-3"
                          >
                            <Form.Label>وردية العمل</Form.Label>
                            <MultiSelect
                              options={workShiftList?.map((ele) => ({
                                label: ele.workshift_name,
                                value: ele.workshift_name,
                                _id: ele._id
                              }))}
                              value={workshift}
                              onChange={(e) => setWorkShift(e)}
                              className="basic-multi-select"
                              labelledBy={"Select"}
                              style={{ direction: "rtl" }}
                            />
                            {/* <Select
                              isMulti
                              name="workshift"
                              placeholder="إختيار"
                              options={workShiftList}
                              isDisabled={subunit?.length == 0 ? true : false}
                              hideSelectedOptions={false}
                              value={workshift}
                              getOptionLabel={(option) => option.workshift_name}
                              getOptionValue={(option) => option._id}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => setWorkShift(e)}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#31D68F",
                                  primary: "#0D7B58",
                                },
                              })}
                            /> */}
                          </Form.Group>
                        </div>
                        <div className="col-md-3">
                          <Form.Group className="mb-3" controlId="">
                            <div className="custom-select">
                              <Form.Label>الجنس</Form.Label>
                              <select
                                className="form-control form-select"
                                value={gender}
                                // placeholder='Select Gender'
                                aria-label="Gender"
                                onChange={(e) => {
                                  if (e.target.value == "Select Gender") {
                                    setGender("");
                                  } else {
                                    setGender(e.target.value);
                                  }
                                }}
                              >
                                <option selected value={"Select Gender"}>
                                  حدد الجنس
                                </option>
                                <option value="ذكر">ذكر</option>
                                <option value="انثى">انثى</option>
                                {/* <option value="male">Male</option>
                                <option value="female">Female</option> */}
                              </select>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <Form.Label>اسم المستخدم</Form.Label>
                            <div className="keyword__search">
                              <div className="form-group w-100">
                                <input
                                  type="text"
                                  className="form-control mb-0"
                                  value={username}
                                  placeholder="اسم المستخدم"
                                  onChange={(e) => setUsername(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <Form.Label>رمز الموظف</Form.Label>
                            <div className="keyword__search">
                              <div className="form-group w-100">
                                <input
                                  type="text"
                                  className="form-control mb-0"
                                  value={employeeId}
                                  placeholder="رمز الموظف"
                                  onChange={(e) =>
                                    setEmployeeId(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <Form.Label>الكلمة المعرفة</Form.Label>
                            <div className="keyword__search">
                              <div className="form-group w-100">
                                <input
                                  type="text"
                                  className="form-control mb-0"
                                  value={keyword}
                                  placeholder="الكلمة المعرفة"
                                  onChange={(e) => setKeyword(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button
                            className="btn btn-secondary ml-2"
                            onClick={() => onClearHandler()}
                          >
                            مسح
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() => onSearchHandler(page)}
                          >
                            البحث
                          </button>
                        </div>
                      </div>
                      {searchUsersDetails?.length ? (
                        <>
                          <Table striped bordered hover className="mt-5">
                            <thead>
                              <tr>
                                <th style={{ display: "flex" }}>
                                  <Form.Check
                                    type="checkbox"
                                    id={`valueOne`}
                                    style={{ marginLeft: "5px" }}
                                    checked={
                                      editSearchData?.length ==
                                      searchUserId?.length
                                    }
                                    onClick={(e) =>
                                      selectAllSearchUserHandler(e)
                                    }
                                  />
                                  تحديد الكل
                                </th>
                                <th>الاسم</th>
                                <th>رقم الهاتف</th>
                                {/* <th>
                                  {editSearchData?.length ==
                                  searchUserId?.length ? (
                                    <button
                                      className="btn btn-sm btn-danger btn-table"
                                      onClick={() =>
                                        onRemoveAllSearchUserHandler()
                                      }
                                    >
                                      Remove All
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-sm btn-primary btn-table"
                                      onClick={() =>
                                        onAddAllSearchUserHandler()
                                      }
                                    >
                                      Select All
                                    </button>
                                  )}
                                </th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {searchUsersDetails?.map((ele, i) => {
                                return (
                                  <tr>
                                    <td>
                                      <Form.Check
                                        type="checkbox"
                                        id={`valueOne`}
                                        checked={
                                          searchUserId.includes(ele._id)
                                            ? true
                                            : false
                                        }
                                        onClick={(e) => {
                                          if (e.target.checked) {
                                            setSearchUserId([
                                              ...searchUserId,
                                              ele._id,
                                            ]);
                                            setSelectedSearchUsers([
                                              ...selectedSearchUsers,
                                              ele,
                                            ]);
                                          } else {
                                            setSearchUserId(
                                              searchUserId.filter(
                                                (item) => item != ele._id
                                              )
                                            );
                                            setSelectedSearchUsers(
                                              selectedSearchUsers?.filter(
                                                (item) => item._id != ele._id
                                              )
                                            );
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>{ele.name}</td>
                                    <td>{ele.phone}</td>
                                    {/* <td>
                                      <button
                                        className={`btn btn-sm btn-table ${
                                          searchUserId.includes(ele._id)
                                            ? "btn-danger"
                                            : "btn-primary"
                                        }`}
                                        onClick={() =>
                                          searchUserId.includes(ele._id)
                                            ? onRemoveSearchUserHandler(ele)
                                            : onAddSearchUserHandler(ele)
                                        }
                                      >
                                        {searchUserId.includes(ele._id)
                                          ? "Remove"
                                          : "Select"}
                                      </button>
                                    </td> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      ) : null}
                      <div className="paginationStyle">
                        {searchPageCount > 1 ? (
                          <>
                            <ReactPaginate
                              previousLabel={<>❮</>}
                              nextLabel={<>❯</>}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              // breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              pageCount={searchPageCount}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={5}
                              initialPage={searchPage}
                              onPageChange={(e) => handleSearchPageChange(e)}
                              containerClassName="pagination"
                              activeClassName="pageActive"
                              forcePage={searchPage}
                            />
                          </>
                        ) : null}
                      </div>
                      <hr />
                      <Row>
                        <Col lg={2}>
                          <button
                            className="btn btn-primary w-100"
                            style={{ padding: "10px 30px" }}
                            onClick={() => onMoveHandler()}
                          >
                            اضافة
                          </button>
                        </Col>
                      </Row>
                      {finalUsersDetails?.length ? (
                        <Table striped bordered hover className="mt-5">
                          <thead>
                            <tr>
                              <th style={{ display: "flex" }}>
                                <Form.Check
                                  type="checkbox"
                                  id={`valueOne`}
                                  style={{ marginLeft: "5px" }}
                                  checked={
                                    finalUsers?.length == finalUserId?.length
                                  }
                                  onClick={(e) => selectAllFinalUserHandler(e)}
                                />
                                تحديد الكل
                              </th>
                              <th> الاسم</th>
                              <th> رقم الهاتف</th>
                              <th>
                                {finalUsers?.length == finalUserId?.length ? (
                                  <button
                                    className="btn btn-sm btn-danger btn-table"
                                    onClick={() => onRemoveAllFinalHandler()}
                                  >
                                    حذف الكل 
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-sm btn-primary btn-table"
                                    onClick={() => onAddAllFinalHandler()}
                                  >
                                    Select All
                                  </button>
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {finalUsersDetails?.map((ele, i) => {
                              return (
                                <tr>
                                  <td>
                                    <Form.Check
                                      type="checkbox"
                                      id={`valueOne`}
                                      checked={
                                        finalUserId.includes(ele._id)
                                          ? true
                                          : false
                                      }
                                      onClick={(e) => {
                                        if (e.target.checked) {
                                          setFinalUserId([
                                            ...finalUserId,
                                            ele._id,
                                          ]);
                                          setFinalSelectedUsers([
                                            ...finalSelectedUsers,
                                            ele,
                                          ]);
                                        } else {
                                          setFinalUserId(
                                            finalUserId.filter(
                                              (item) => item != ele._id
                                            )
                                          );
                                          setFinalSelectedUsers(
                                            finalSelectedUsers?.filter(
                                              (item) => item._id != ele._id
                                            )
                                          );
                                          setFinalUsersDetails(finalUsersDetails?.filter((item) => item._id != ele._id))
                                          setFinalUsers(finalUsers?.filter((item) => item._id != ele._id))
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>{ele.name}</td>
                                  <td>{ele.phone}</td>
                                  <td>
                                    <button
                                      className={`btn btn-sm  btn-table ${finalUserId?.includes(ele._id)
                                        ? "btn-danger"
                                        : "btn-primary"
                                        }`}
                                      onClick={() =>
                                        finalUserId?.includes(ele._id)
                                          ? onRemoveFinalHandler(ele)
                                          : onAddFinalHandler(ele)
                                      }
                                    >
                                      {finalUserId?.includes(ele._id)
                                        ? "حذف"
                                        : "Select"}
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      ) : null}
                      <div className="paginationStyle">
                        {finalPageCount > 1 ? (
                          <ReactPaginate
                            previousLabel={<>❮</>}
                            nextLabel={<>❯</>}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            // breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={finalPageCount}
                            initialPage={finalPage}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={(e) => handleFinalPageChange(e)}
                            containerClassName="pagination"
                            activeClassName="pageActive"
                            forcePage={page}
                          />
                        ) : null}
                      </div>
                      <hr />
                      {usersDetails?.length ? (
                        <>
                          <p>Allready Added Users</p>
                          <Table striped bordered hover className="mt-5">
                            <thead>
                              <tr>
                                <th style={{ display: "flex" }}>
                                  <Form.Check
                                    type="checkbox"
                                    id={`valueOne`}
                                    style={{ marginLeft: "5px" }}
                                    checked={
                                      // contactList?.selected_users?.length ==
                                      userId?.length
                                    }
                                    onClick={(e) => selectAllUserHandler(e)}
                                  />
                                  تحديد الكل
                                </th>
                                <th>الاسم</th>
                                <th>رقم الهاتف</th>
                                <th>
                                  {
                                    // contactList?.selected_users?.length ==
                                    userId?.length ? (
                                      <button
                                        className="btn btn-sm btn-danger btn-table"
                                        onClick={() => onRemoveAllUserHandler()}
                                      >
                                        Remove All
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-sm btn-primary btn-table"
                                        onClick={() => onAddAllUserHandler()}
                                      >
                                        Select All
                                      </button>
                                    )}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {usersDetails?.map((ele, i) => {
                                return (
                                  <tr>
                                    <td>
                                      <Form.Check
                                        type="checkbox"
                                        id={`valueOne`}
                                        checked={
                                          userId.includes(ele._id)
                                            ? true
                                            : false
                                        }
                                        onClick={(e) => {
                                          if (e.target.checked) {
                                            setUserId([...userId, ele._id]);
                                            setSelectedUsers([
                                              ...selectedUsers,
                                              ele,
                                            ]);
                                          } else {
                                            setUserId(
                                              userId.filter(
                                                (item) => item != ele._id
                                              )
                                            );
                                            setSelectedUsers(
                                              selectedUsers?.filter(
                                                (item) => item._id != ele._id
                                              )
                                            );
                                            setUsersDetails(
                                              usersDetails?.filter(
                                                (item) => item._id != ele._id
                                              )
                                            );
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>{ele.name}</td>
                                    <td>{ele.phone}</td>
                                    <td>
                                      <button
                                        className={`btn btn-sm btn-table ${userId?.includes(ele._id)
                                          ? "btn-danger"
                                          : "btn-primary"
                                          }`}
                                        onClick={() =>
                                          userId?.includes(ele._id)
                                            ? onRemoveUserHandler(ele)
                                            : onAddUserHandler(ele)
                                        }
                                      >
                                        {userId?.includes(ele._id)
                                          ? "حذف"
                                          : "Select"}
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      ) : null}
                      <div className="paginationStyle">
                        {pageCount > 1 ? (
                          <>
                            <ReactPaginate
                              previousLabel={<>❮</>}
                              nextLabel={<>❯</>}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              // breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              pageCount={pageCount}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={5}
                              initialPage={page}
                              onPageChange={(e) => handlePageChange(e)}
                              containerClassName="pagination"
                              activeClassName="pageActive"
                              forcePage={page}
                            />
                          </>
                        ) : null}
                      </div>
                      <hr />
                      <Row className="align-items-center mb-3">
                        <Form.Label>رفع ملف CSV</Form.Label>
                        <Col md={6} className="d-flex flexColumn">
                          <input
                            type="file"
                            name="uploadfile"
                            id="img"
                            onChange={(e) => onUploadHandler(e)}
                            style={{ display: "none" }}
                          />
                          <label htmlFor="img" className="form-control mb-0">
                            {csv?.length ? csv[0].name : "تحميل ملف CSV"}
                          </label>
                        </Col>
                        <Col lg={3}>
                          <button
                            className="btn btn-primary w-100"
                            style={{ padding: "10px 30px" }}
                            onClick={() => onFileUploadHandler()}
                          >
                            رفع ملف CSV
                          </button>
                        </Col>
                        <Col lg={3}>
                          <button
                            className="btn btn-info"
                            onClick={() => onDownloadSample()}
                          >
                            تنزيل نموذج CSV
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        {contact?.length ? (
                          <Table striped bordered hover className="">
                            <thead>
                              <tr>
                                <th style={{ display: "flex" }}>
                                  <Form.Check
                                    type="checkbox"
                                    id={`valueOne`}
                                    style={{ marginLeft: "5px" }}
                                    checked={
                                      fullContact?.length == csvSelected?.length
                                    }
                                    onClick={(e) => selectAllContactHandler(e)}
                                  />
                                  تحديد الكل
                                </th>
                                <th>رقم الهاتف</th>
                                <th>
                                  {fullContact?.length ==
                                    csvSelected?.length ? (
                                    <button
                                      className="btn btn-sm btn-danger btn-table"
                                      onClick={() =>
                                        onRemoveContactAllHandler()
                                      }
                                    >
                                      حذف الكل 
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-sm btn-primary btn-table"
                                      onClick={() => onAddAllContactHandler()}
                                    >
                                      Select All
                                    </button>
                                  )}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {contact?.map((ele, i) => {
                                return (
                                  <tr>
                                    <td>
                                      <Form.Check
                                        type="checkbox"
                                        id={`valueOne`}
                                        checked={
                                          csvSelected.includes(ele)
                                            ? true
                                            : false
                                        }
                                        onClick={(e) => {
                                          if (e.target.checked) {
                                            setCSVSelected([
                                              ...csvSelected,
                                              ele,
                                            ]);
                                          } else {
                                            setCSVSelected(
                                              csvSelected.filter(
                                                (item) => item != ele
                                              )
                                            );
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>{ele}</td>
                                    <td>
                                      <button
                                        className={`btn btn-sm btn-table ${csvSelected?.includes(ele)
                                          ? "btn-danger"
                                          : "btn-primary"
                                          }`}
                                        onClick={() =>
                                          csvSelected?.includes(ele)
                                            ? onRemoveContactHandler(ele)
                                            : onAddContactHandler(ele)
                                        }
                                      >
                                        {userId?.includes(ele)
                                          ? "حذف"
                                          : "Select"}
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        ) : null}
                      </Row>
                      <div className="paginationStyle">
                        {csvCount > 1 ? (
                          <ReactPaginate
                            previousLabel={<>❮</>}
                            nextLabel={<>❯</>}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            // breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={csvCount}
                            initialPage={csvPage}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={(e) => handleCsvPageChange(e)}
                            containerClassName="pagination"
                            activeClassName="pageActive"
                            forcePage={csvPage}
                          />
                        ) : null}
                      </div>
                      <div className="row mt-3">
                        {addedCsv?.map((ele) => {
                          return (
                            <div className="col-md-2 mb-4">
                              <div className="tags tagStyle">
                                <img
                                  src="assets/img/icons/close.svg"
                                  alt=""
                                  onClick={() => onDeleteCsvhandler(ele)}
                                />
                                <span>{ele}</span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-md-8">
                          <div className="form-group">
                            <label htmlFor="">إضافة يدوية</label>
                            <div className="d-flex align-items-center">
                              <input
                                type="text"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                onKeyDown={(e) => onNumberChangeHandler(e)}
                                className="form-control"
                                placeholder="الرجاء إدخال رقم هاتف صحيح
                                                            "
                              />
                            </div>
                            {err && <p className="text-danger">{err.phone}</p>}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {phoneNumberArray?.map((ele) => {
                          return (
                            <div className="col-md-2 mb-4">
                              <div className="tags tagStyle">
                                <img
                                  src="assets/img/icons/close.svg"
                                  alt=""
                                  onClick={() => onDeleteNumberhandler(ele)}
                                />
                                <span>{ele}</span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="mBottom40 d-flex">
                        <Button
                          style={{ marginLeft: "10px" }}
                          onClick={(e) => updateContactHandler(e)}
                        >
                          حفظ جهة الاتصال
                        </Button>
                        <button
                          className="btn btn-danger m-0 mr-3"
                          onClick={() => onCancelHandler()}
                        >
                          الغاء{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Sidebar />
    </>
  );
};

export default EditContactBook;
