import React,{useEffect, useState} from 'react';
import { Accordion, Col } from 'react-bootstrap';
import Header from '../componets/Header';
import Sidebar from '../componets/Sidebar';
import Form from 'react-bootstrap/Form';
import '../styles/Faq.css';
import { baseUrl } from '../Constants';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axiosInstance from "../utils/axiosInterceptor";

function Faq() {

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [faqlists, setFAQlists] = useState([]);
  const [faqDetail, setFAQDetail] = useState({});
  const [search, setSearch] = useState('');
  const [deleteId, setDeleteID] = useState('');
  const [modalshow, setModalShow] = useState(false);
  const handleClose  = () => setModalShow(false);
  const handleShow  = () => setModalShow(true);

  useEffect(() => {
    document.title = 'TSD - بحث';
  }, []);
  
  useEffect(() => {
    fetchFaq();
  }, [])

  const openAdd  = () => {
    setShow(!show);
  }

  function fetchFaq(){
    axiosInstance.get(baseUrl+"faq").then((res) => {
      if(res.data.status) {
        setFAQlists(res.data.faq_details);
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        });
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if(question != '' && answer != '') {
      axiosInstance.post(baseUrl+"insertFaq", {
        question,
        answer
      }).then((res) => {
        if(res.data.status) {
          fetchFaq();
          e.target.reset();
          setQuestion("");
          setAnswer("")
        } else {
          toast.error(res.data.message, {
            position: "bottom-center",
            autoClose: 3000
          });
        }
      }).catch((err) => {
        console.log(err)
      })
    } else {
      toast.warning('الرجاء إضافة سؤال وجواب', {
        position: "bottom-center",
        autoClose: 3000
      });
    } 
  }

  const handleRemove = (id) => {
    setModalShow(true);
    setDeleteID(id);
  }


  const deleteFAQ = () => {
    axiosInstance.post(baseUrl+"deleteFaq/", {
      del_id:deleteId
    }).then((res) => {
      if(res.data.status) {
        setModalShow(false);
        fetchFaq();
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        });
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  const editFAQ = (id) => {
    setEdit(true)
    setShow(false)
    axiosInstance.get(baseUrl+`faqDetails/${id}`).then((res) => { 
      if(res.data.status) {
        setFAQDetail(res.data.faq_details)
        const divElement = document.getElementById('faqForm');
        divElement.scrollIntoView({ behavior: 'smooth' });
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleUpdate = (id,e) => {
    setEdit(false);
    e.preventDefault();
    let question =  faqDetail.question;
    let answer =  faqDetail.answer;
    if(faqDetail.question != '' && faqDetail.answer != '') {
      axiosInstance.post(baseUrl+"updateFaq", {
        question,
        answer,
          id
        }).then((res) => {
          if(res.data.status) {
            fetchFaq();
            setFAQDetail({})
          } else {
            toast.error(res.data.message, {
              position: "bottom-center",
              autoClose: 3000
            });
          }
        }).catch((err) => {
          console.log(err);
        })
    }else {
      toast.warning('الرجاء إضافة سؤال وجواب', {
          position: "bottom-center",
          autoClose: 3000
      });
    } 
  }

  return (
    <>
        <Modal show = {modalshow} centered onHide={handleClose} className = 'removeModfal'>
          <Modal.Body>هل انت متأكد من الحذف؟</Modal.Body>
          <Modal.Footer>
            <Button className = 'btn btn-modalClose' onClick = {handleClose}>
            اغلاق
            </Button>
            <Button className = 'btn btn-modalSubmit' onClick = {deleteFAQ}>
            حذف
            </Button>
          </Modal.Footer>
        </Modal>
        <Header/>
        <main id = "main" className = "main">
          <section className = "faq">
            <div className = "container">
              <div className = "card">
                <div className = "faq__section">
                  <div className = "card-body">
                    <div className = "row">
                        <div className = "form__search">
                          <div className = "row justify-content-between">
                            <div className = "col-md-6">
                              <div className = "input-group faq__search">
                                <input className = "form-control bg-white" 
                                  onChange = {(e) => setSearch(e.target.value)}
                                  type = "text" 
                                  id = "example-search-input" 
                                  placeholder = 'بحث' />
                                <span className = "input-group-append">
                                  <button className = "btn btn-outline-secondary bg-white" type = "button" id = 'faqForm'>
                                    <i className = "fa fa-search" />
                                  </button>
                                </span>
                              </div>
                            </div>
                            <div className = "col-md-6 text-left">
                              <button className = "btn btn-primary" onClick = {openAdd} type = "button">اضافة اسئلة متكررة</button>
                            </div>
                            {
                              show 
                              && <div className = "col-md-12">
                                  <Form className = 'add__faq' onSubmit = {handleSubmit}>
                                    <Form.Group className = "mb-3" controlId = "exampleForm.ControlInput1">
                                      <Form.Control type = "text" 
                                      placeholder = 'سؤال*'  
                                      onChange = {(e) => setQuestion(e.target.value)}/>
                                    </Form.Group>
                                    <Form.Group className = "mb-3" controlId = "exampleForm.ControlTextarea1">
                                      <Form.Control as="textarea" 
                                        rows = {4} 
                                        placeholder = 'جواب*' 
                                        onChange = {(e) => setAnswer(e.target.value)} />
                                    </Form.Group>
                                    <Col className = 'd-flex justify-content-end'>
                                      <button className = "btn btn-primary" type = "submit">إضافة</button>
                                    </Col>
                                  </Form>
                                </div>
                            }
                            {
                              !show &&       
                              edit 
                              && <div className = "col-md-12"> 
                                  <Form className = 'add__faq' onSubmit = {(e) => handleUpdate(faqDetail._id,e)}> 
                                    <Form.Group className = "mb-3" controlId = "exampleForm.ControlInput1">
                                      <Form.Control type = "text" 
                                        value = {faqDetail.question} placeholder = 'سؤال*'  
                                        onChange = {(e) => setFAQDetail({...faqDetail,question:e.target.value})}
                                        />
                                    </Form.Group>
                                    <Form.Group className = "mb-3" controlId = "exampleForm.ControlTextarea1">
                                      <Form.Control as="textarea"
                                        rows = {4}
                                        value = {faqDetail.answer} 
                                        placeholder = 'جواب*' 
                                        onChange = {(e) => setFAQDetail({...faqDetail,answer:e.target.value})} />
                                    </Form.Group>
                                    <Col className = 'd-flex justify-content-end'>
                                      <button className = "btn btn-primary" type = "submit">تحديث</button>
                                    </Col>
                                  </Form>
                                </div>
                            }
                          </div>
                        </div>
                    </div>
                    <div className = "default__heading">
                    </div>
                    <Accordion defaultActiveKey="0" className = 'faq__accordian'>
                      {
                        faqlists?.filter((val) => {
                          if(search == ''){
                            return val
                          }else if(val.question.toLowerCase().includes(search.toLocaleLowerCase())
                            || val.answer.toLowerCase().includes(search.toLocaleLowerCase())) {
                            return val
                          }
                        }).map((ele,i) => {
                          return(
                              <Accordion.Item eventKey={`${i}`}>
                                <Accordion.Header className = 'wordBreak'><p>{ele.question}</p></Accordion.Header>
                                <Accordion.Body>
                                  <p>{ele.answer}</p>
                                  <div className = "delete__faq actions">
                                    <button><img src="assets/img/icons/edit.svg" onClick = {(e) => editFAQ(ele._id,e)} alt="" /></button>
                                    <button><img src="assets/img/icons/delete.png" onClick = {(e) => handleRemove(ele._id,e)} alt="" /></button>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                          )
                        })
                      }
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Sidebar/>
    </>
  )
}

export default Faq