import { createSlice } from '@reduxjs/toolkit'

export const commonSlice = createSlice({
  name: 'commonReducer',
  initialState: {
    templatelists: [],
    DesignationLists: [],
    RankLists: [],
    ListUnits: [],
    StaffLists: [],
    userContact: [],
    userContactDetails: [],
    contact_list: [],
    totalPageCount: 0,
    userGroupContactDetails: [],
    userLoader: false,
    selectedContactList: {},
    campaignTotalCount: 0,
    DashboardUsersLists: [],
    multiSubunitLists: [],
    multiWorkshiftLists: []
  },
  reducers: {
    updateRedux: (state, data) => {
      state[data.payload.key] = data.payload.value
    },
  },
})

export const { updateRedux } = commonSlice.actions
export const templatelists = (state) => state.commonReducer.templatelists;
export const DesignationLists = (state) => state.commonReducer.DesignationLists;
export const RankLists = (state) => state.commonReducer.RankLists;
export const ListUnits = (state) => state.commonReducer.ListUnits;
export const StaffLists = (state) => state.commonReducer.StaffLists;
export const subunitlists = (state) => state.commonReducer.subunitlists;
export const workshift_details = (state) => state.commonReducer.workshift_details;
export const user_details = (state) => state.commonReducer.user_details;
export const ad_count = (state) => state.commonReducer.ad_count;
export const manual_count = (state) => state.commonReducer.manual_count;
export const csv_count = (state) => state.commonReducer.csv_count;
export const modules = (state) => state.commonReducer.modules;
export const userRoles = (state) => state.commonReducer.userRoles;
export const users = (state) => state.commonReducer.users;
export const multiSubunitLists = (state) => state.commonReducer.multiSubunitLists;
export const multiWorkshiftLists = (state) => state.commonReducer.multiWorkshiftLists;
export const userContact = (state) => state.commonReducer.userContact;
export const userContactDetails = (state) => state.commonReducer.userContactDetails;
export const contact_list = (state) => state.commonReducer.contact_list;
export const userGroupContact = (state) => state.commonReducer.userGroupContact;
export const userGroupContactDetails = (state) => state.commonReducer.userGroupContactDetails;
export const permissionModules = (state) => state.commonReducer.permissionModules;
export const user_role = (state) => state.commonReducer.user_role;
export const totalPageCount = (state) => state.commonReducer.totalPageCount;
export const currentPage = (state) => state.commonReducer.currentPage;
export const csvContact = (state) => state.commonReducer.csvContact;
export const contactTotalCount = (state) => state.commonReducer.contactTotalCount;
export const contactTotalPageCount = (state) => state.commonReducer.contactTotalPageCount;
export const groupTotalCount = (state) => state.commonReducer.groupTotalCount;
export const groupTotalPageCount = (state) => state.commonReducer.groupTotalPageCount;
export const userContactBook = (state) => state.commonReducer.userContactBook;
export const userContactDetailsBook = (state) => state.commonReducer.userContactDetailsBook;
export const contactBookTotalCount = (state) => state.commonReducer.contactBookTotalCount;
export const contactBookTotalPageCount = (state) => state.commonReducer.contactBookTotalPageCount;
export const userTotalCount = (state) => state.commonReducer.userTotalCount;
export const userTotalPageCount = (state) => state.commonReducer.userTotalPageCount;
export const csvCampaign = (state) => state.commonReducer.csvCampaign;
export const userCampaignContactDetails = (state) => state.commonReducer.userCampaignContactDetails;
export const rolesTotalCount = (state) => state.commonReducer.rolesTotalCount;
export const templateTotalCount = (state) => state.commonReducer.templateTotalCount;
export const contactTotalPages = (state) => state.commonReducer.contactTotalPages;
export const contactFullList = (state) => state.commonReducer.contactFullList;
export const templateFullLists = (state) => state.commonReducer.templateFullLists;
export const userLoader = (state) => state.commonReducer.userLoader;
export const jobLists = (state) => state.commonReducer.jobLists;
export const userGroupNumberDetails = (state) => state.commonReducer.userGroupNumberDetails;
export const selectedContactList = (state) => state.commonReducer.selectedContactList;
export const editUserSearchdata = (state) => state.commonReducer.editUserSearchdata;
export const recieverList = (state) => state.commonReducer.recieverList;
export const campaignName = (state) => state.commonReducer.campaignName;
export const campaignMessage = (state) => state.commonReducer.campaignMessage;

export const DashboardDatas = (state) => state.commonReducer.DashboardDatas;
export const DashboardChart = (state) => state.commonReducer.DashboardChart;

export const CampaignStatisticsLists = (state) => state.commonReducer.CampaignStatisticsLists;
export const campaignStatisticsTotalCount = (state) => state.commonReducer.campaignStatisticsTotalCount;
export const DashboardUsersLists = (state) => state.commonReducer.DashboardUsersLists;
export const DashboardUserChart = (state) => state.commonReducer.DashboardUserChart;
export const dashboardDateChart = (state) => state.commonReducer.dashboardDateChart;
export const dashboardAllChart = (state) => state.commonReducer.dashboardAllChart;
export const permittedUsers = (state) => state.commonReducer.permittedUsers;
export const campaignList = (state) => state.commonReducer.campaignList;
export const signature_details = (state) => state.commonReducer.signature_details;

export const campaignSearchDetails = (state) => state.commonReducer.campaign_Search_Details

export default commonSlice.reducer