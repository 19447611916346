import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  createNewlogin,
  getModulePermission,
  loginUser,
  loginverification,
  manualLogin,
} from "../redux/actionCreator";
import "../styles/Login.css";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [manual, setManual] = useState(false);
  const [loginType1, setLoginType1] = useState(false);
  const [loginType2, setLoginType2] = useState(false);
  const [showManualPassword, setManualPassword] = useState(false);
  const [manualUsername, setManualusername] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewassword] = useState("");
  const [cPassword, setConfirmassword] = useState("");
  const [manualLoginPass, setManualLoginPass] = useState("");

  const onEnterLogin = (e) => {
    if (e.key == "Enter") {
      handleSubmit(e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username == "" || password == "") {
      toast.error("يرجى التحقق من الحقل", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      let data = {
        username,
        password,
      };
      dispatch(
        loginUser(data, (msg) => {
          if (msg.status == true) {
            dispatch(
              getModulePermission(() => {
                navigate("/");
              })
            );
            toast.success("تسجيل الدخول بنجاح", {
              position: "bottom-center",
              autoClose: 3000,
            });
          }
        })
      );
    }
  };

  const manualSubmit = (e) => {
    e.preventDefault();
    if (manualUsername == "") {
      toast.error("الرجاء إدخال اسم المستخدم بشكل صحيح", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      let data = {
        username: manualUsername,
      };
      dispatch(
        manualLogin(data, (callback) => {
          setManualPassword(true);
          if (callback.login_type == 1) {
            setLoginType1(true);
          } else if (callback.login_type == 2) {
            setLoginType2(true);
          }
        })
      );
    }
  };

  const manuaLoginSubmit = (e) => {
    e.preventDefault();
    if (manualLoginPass == "") {
      toast.error("كلمة المرور غير صحيحة", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      let data = {
        username: manualUsername,
        password: manualLoginPass,
      };
      dispatch(
        loginverification(data, (msg) => {
          if (msg.status == true) {
            dispatch(
              getModulePermission(() => {
                navigate("/");
              })
            );
            toast.success("تسجيل الدخول بنجاح", {
              position: "bottom-center",
              autoClose: 3000,
            });
          } else if (msg.status == false) {
            toast.error("كلمة المرور غير صحيحة", {
              position: "bottom-center",
              autoClose: 3000,
            });
          }
        })
      );
    }
  };

  const handleChange = (e) => {
    if (e.target.value == "Manual") {
      setManual(!manual);
      setUsername("");
      setManualusername("");
    } else {
      setManual(false);
      setUsername("");
      setManualusername("");
    }
  };

  function checkPassword(str) {
    let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }

  const handleNewlogin = (e) => {
    e.preventDefault();
    if (cPassword != newPassword || cPassword == "" || newPassword == "") {
      toast.error("كلمة السر غير مطابقة", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      if (!checkPassword(cPassword)) {
        toast.error(
          "كلمة المرور غير صحيحة. ثمانية أحرف على الأقل ، حرف كبير واحد على الأقل ، حرف واحد صغير ، رقم واحد وحرف خاص واحد",
          {
            position: "bottom-center",
            autoClose: 3000,
          }
        );
      } else {
        let data = {
          username: manualUsername,
          password: cPassword,
        };
        dispatch(
          createNewlogin(data, (msg) => {
            if (msg.status == true) {
              localStorage.setItem("token", msg.accessToken);
              localStorage.setItem("refreshToken", msg.refreshToken);
              dispatch(
                getModulePermission(() => {
                  navigate("/");
                })
              );
              toast.success("تسجيل الدخول بنجاح", {
                position: "bottom-center",
                autoClose: 3000,
              });
            }
          })
        );
      }
    }
  };

  const backBtn = () => {
    console.log("ss")
    //window.location = "http://localhost:3000/#/login";
    window.location.reload()
    //navigate('/login');
  };

  return (
    <>
      <section
        className="smsPortal__login"
        style={{ backgroundImage: "url(assets/img/login.jpg)" }}
      >
        <div className="container">
          <div className="police__logo"></div>
          <div className="login__form">
            {manual && (
              <div className="back__form" onClick={() => backBtn()}>
                <img src="assets/img/icons/left-arrow.svg" alt="" />
              </div>
            )}
            <div className="login-logo">
              <img src="assets/img/tsd-logo.svg" alt="" />
              <h4>  نظام الرسائل النصية   </h4>
            </div>
            {!loginType2 && !showManualPassword && !loginType1 ? (
              <>
                <div className="form-group">
                  <div className="custom-select without-label">
                    <select
                      className="form-control form-select"
                      onChange={handleChange}
                    >
                      <option selected value="ActiveDirectory">
                        احقية TSD
                      </option>
                      <option value="Manual">يدوي</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={!manual == true ? username : manualUsername}
                    className="form-control"
                    onChange={(e) =>
                      !manual == true
                        ? setUsername(e.target.value)
                        : setManualusername(e.target.value)
                    }
                    placeholder="اسم المستخدم"
                    autoComplete="off"
                  />
                </div>
                {manual === false && (
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="كلمة المرور "
                      onKeyDown={(e) => onEnterLogin(e)}
                    />
                  </div>
                )}
              </>
            ) : null}
            {loginType2 === true && (
              <>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    onChange={(e) => setNewassword(e.target.value)}
                    placeholder="كلمة مرور جديدة"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    onChange={(e) => setConfirmassword(e.target.value)}
                    placeholder="تأكيد كلمة المرور"
                  />
                </div>
              </>
            )}
            {showManualPassword == true && loginType2 == false ? (
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  onChange={(e) => setManualLoginPass(e.target.value)}
                  placeholder="كلمة المرور "
                />
              </div>
            ) : (
              ""
            )}
            {loginType2 === false
              ? showManualPassword == true && loginType2 == false
                ? null
                : loginType1 == false && (
                    <div className="form-group mt-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={(e) =>
                          !manual == true ? handleSubmit(e) : manualSubmit(e)
                        }
                      >
                        {!manual ? "تسجيل الدخول " : "التالي"}
                      </button>
                    </div>
                  )
              : ""}
            {showManualPassword == true && loginType2 == false ? (
              <div className="form-group mt-3">
                <button
                  className="btn btn-primary w-100"
                  onClick={(e) => manuaLoginSubmit(e)}
                >
                  تسجيل دخول
                </button>
              </div>
            ) : null}
            {loginType2 === true && (
              <div className="form-group mt-3">
                <button
                  className="btn btn-primary w-100"
                  onClick={handleNewlogin}
                >
                  تسجيل دخول
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
