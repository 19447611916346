import React from 'react';
import './index.css';
import { Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Login from './pages/Login';
import Campaign from './pages/Campaign';
import CampaignStatistics from './pages/CompaignStatistics';
import Reports from './pages/Reports';
import Template from './pages/Template';
import ContactBook from './pages/ContactBook';
import Faq from './pages/Faq';
import Contacts from './pages/Contacts';
import Rank from './pages/Rank';
import Unit from './pages/Unit';
import User from './pages/User';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Role from './pages/Role';
import ManageRole from './pages/ManageRole';
import PrivateRoutes from './utils/PrivateRoutes';
import { permissionModules, user_role } from './redux/TemplateSlice';
import { useDispatch, useSelector } from 'react-redux';
import NotFound from './pages/NotFound';
import { modulePermission } from './utils/helpers';
import EditContactBook from './pages/EditContact';

function App() {

  const dispatch = useDispatch();

  const permission= useSelector(permissionModules);
  const userRole= useSelector(user_role);

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route element={<PrivateRoutes/>}>
          <Route path="/" element={<Home />} exact />
            { 
              (modulePermission()?.includes('campaing'))
              ? <Route path="/Campaign" element={<Campaign/>} /> 
              : null
            }
            { 
              (modulePermission()?.includes('statistics'))
              ? <Route path="/campaign-statistics" element={<CampaignStatistics/>}/> 
              : null
            }
            { 
              (modulePermission()?.includes('template'))
              ? <Route path="/templates" element={<Template/>}/> 
              : null
            }
            { 
              (modulePermission()?.includes('faq'))
              ? <Route path="/faq" element={<Faq/>} /> 
              : null
            }
            {
              (modulePermission()?.includes('groups'))
              ? <Route path="/contact" element={<Contacts/>} /> 
              : null
            }
            {
              (modulePermission()?.includes('groups'))
              ? <Route path="/contact-book" element={<ContactBook/>} /> 
              : null
            }
            {
              (modulePermission()?.includes('groups'))
              ? <Route path="/contact-edit/:id" element={<EditContactBook/>} /> 
              : null
            }
            { 
              (modulePermission()?.includes('rank'))
              ? <Route path="/rank" element={<Rank/>} /> 
              : null
            }
            { 
              (modulePermission()?.includes('unit'))
              ? <Route path="/unit" element={<Unit/>} /> 
              : null
            }
            <Route path="/reports/:id" element={<Reports/>} />
            {
              (modulePermission()?.includes('users'))
              ? <Route path="/user" element={<User/>} />
              : null
            }
            { 
              (modulePermission()?.includes('roles'))
              ? <Route path="/role" element={<Role/>} /> 
              : null
            }
            { 
              (modulePermission()?.includes('roles'))
              ? <Route path="/manage-role" element={<ManageRole/>} /> 
              : null
            }
          </Route>
          <Route path="/login" element={<Login/>} />
          <Route path="*" element={<NotFound/>} />
      </Routes>
    </>
  );
}

export default App;
