import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom' ;
import Header from '../componets/Header';
import Sidebar from '../componets/Sidebar';
import { contactTotalPages, contact_list } from '../redux/TemplateSlice';
import '../styles/contacts.css';
import Table from 'react-bootstrap/Table';
import { deleteContactBook, getContactBookData } from '../redux/actionCreator';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactPaginate from 'react-paginate';

function Contacts() {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const contactList = useSelector(contact_list);
    const pageCount = useSelector(contactTotalPages);

    const [deleteId, setDeleteID] = useState('');
    const [modalshow, setModalShow] = useState(false);
    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);
    const [page, setPage] = useState(0);
    const [searchKey, setSearchKey] = useState("");

    useEffect(() => {
        document.title = 'TSD - مجموعة الاتصال';
    }, []);

    useEffect(() => {
        dispatch(getContactBookData(page,searchKey));
    },[])

    const handleRemove=(id)=>{
        setModalShow(true);
        setDeleteID(id);
    }

    const onDeleteContactHandler = (e) => {
        e.preventDefault();
        dispatch(deleteContactBook(deleteId,page,searchKey,()=> {
            setModalShow(false);
            toast.success('تم حذف جهة الاتصال بنجاح', {
                position: "bottom-center",
                autoClose: 3000
            });
        }))
    }

    const onEditContacthandler = (ele) => {
        navigate(`/contact-edit/${ele._id}`);
    }

    const handlePageChange = (e) => {
        setPage(e.selected);
        dispatch(getContactBookData(e.selected,searchKey));
    }
    
    return (
        <>
            <Modal show = {modalshow} centered onHide = {handleClose} className = 'removeModfal'>
                <Modal.Body>هل تريد أن تحذف؟</Modal.Body>
                <Modal.Footer>
                <Button className = 'btn btn-modalClose' onClick = {handleClose}>
                    اغلاق
                </Button>
                <Button className = 'btn btn-modalSubmit' onClick = {onDeleteContactHandler}>
                    حذف   
                </Button>
                </Modal.Footer>
            </Modal>
            <Header/>
            <main id = "main" className = "main">
                <section className = "section contacts">
                    <div className = "row">
                        <div className = "col-lg-12">
                            <div className = "pagetitle">
                                <h1>  إدارة مجموعات التوزيع</h1>
                            </div>
                            <div className = "form__wizard">
                                <div className = "card">
                                    <div className = "card-body">
                                        <div className = "table__top">
                                            <p>تحرير / حذف / إضافة مجموعة</p>
                                            <Link to = {'/contact-book'}>
                                                <button className = "btn btn-primary" >إضافة جهة اتصال</button>
                                            </Link>
                                        </div>
                                        <Table striped bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th style = {{minWidth:"200px"}}> الاسم</th>
                                                    <th style = {{minWidth:"200px"}}> عدد جهات الاتصال</th>
                                                    <th style = {{minWidth:"200px"}}> تاريخ الانشاء</th>
                                                    <th style = {{minWidth:"200px"}}>اجراء </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    contactList?.map((ele, i) => {
                                                        return(
                                                            <tr>
                                                                <td style = {{minWidth:"200px"}}>{ele.name}</td>
                                                                <td style = {{minWidth:"200px"}}>{ele.selected_users?.length + ele.csv_contacts?.length + ele.manual_contacts?.length}</td>
                                                                <td style = {{minWidth:"200px"}}>{new Date(ele.createdAt).toDateString()}</td>
                                                                <td style = {{minWidth:"200px"}} > 
                                                                    <button className = "btn btn-sm" onClick = {() => onEditContacthandler(ele)}><img src="assets/img/icons/table-edit.png" alt="" /></button>
                                                                    <button className = "btn btn-sm" onClick = {(e)=>handleRemove(ele._id, e)}><img src="assets/img/icons/table-delete.png" alt="" /></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                        <div className = 'paginationStyle'>
                                            {
                                                pageCount > 1
                                                ?<ReactPaginate
                                                    previousLabel={<>❮</>}
                                                    nextLabel={<>❯</>}
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    // breakLabel="..."
                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={5}
                                                    initialPage = {page}
                                                    onPageChange={(e)=>handlePageChange(e)}
                                                    containerClassName="pagination"
                                                    activeClassName="pageActive"
                                                />
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Sidebar/>
        </>
    )
}

export default Contacts