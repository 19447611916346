import React, { useState, useEffect } from "react";
import Header from "../componets/Header";
import Sidebar from "../componets/Sidebar";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import "../styles/Campaign.css";
import "../styles/common.css";
import { baseUrl } from "../Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  signature_details,
  templatelists,
  templateTotalCount,
} from "../redux/TemplateSlice";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  getSignature,
  getTemplateDetails,
  updateSignature,
} from "../redux/actionCreator";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import axiosInstance from "../utils/axiosInterceptor";
import ReactPaginate from "react-paginate";

const Template = () => {
  const dispatch = useDispatch();

  const templates = useSelector(templatelists);
  const pageCount = useSelector(templateTotalCount);
  const signatureDetails = useSelector(signature_details);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [searchTer, setSearchTer] = useState("");
  const [show, setShow] = useState(true);
  const [detail, setDetail] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [deleteId, setDeleteID] = useState("");
  const [deleteshow, setDeleteShow] = useState(false);
  const handleClose = () => setDeleteShow(false);
  const handleShow = () => setDeleteShow(true);
  const [page, setPage] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [lists, setLists] = useState("");
  const [signatureKey, setSignatureKey] = useState({});
  const [showSignEdit, setShowSignEdit] = useState(false);

  useEffect(() => {
    document.title = "TSD - إدارة النماذج";
  }, []);

  useEffect(() => {
    dispatch(getTemplateDetails(page, searchKey));
    dispatch(getSignature());
  }, []);

  useEffect(() => {
    if (signatureDetails?.length) {
      setSignatureKey(signatureDetails[0]);
    }
  }, [signatureDetails]);

  const superadmin = localStorage.getItem("super_admin");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title != "" && description != "") {
      axiosInstance
        .post(baseUrl + "insertTemplates", {
          title,
          description,
        })
        .then((res) => {
          if (res.data.status) {
            templateLists();
            e.target.reset();
            setTitle("");
            setDescription("");
            toast.success("Template added succesfully", {
              position: "bottom-center",
              autoClose: 3000,
            });
          } else {
            toast.error(res.data.message, {
              position: "bottom-center",
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.warning("Please add title and description", {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
  };

  const templateLists = () => {
    dispatch(getTemplateDetails(page, searchKey));
  };

  const handleDelete = (id) => {
    setDeleteShow(true);
    setDeleteID(id);
  };

  const deleteTemp = () => {
    const data = {
      del_id: deleteId,
    };
    axiosInstance
      .post(baseUrl + "deleteTemplates/", data)
      .then((res) => {
        if (res.data.status) {
          setDeleteShow(false);
          templateLists();
        } else {
          toast.error(res.data.message, {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editTemp = (id) => {
    setShow(false);
    axiosInstance
      .get(baseUrl + `templateDetails/${id}`)
      .then((res) => {
        if (res.data.status) {
          setDetail(res.data.template_details);
          const divElement = document.getElementById("templateForm");
          divElement.scrollIntoView({ behavior: "smooth" });
        } else {
          toast.error(res.data.message, {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const tempDetail = (id, e) => {
    setModalShow(true);
    axiosInstance
      .get(baseUrl + `templateDetails/${id}`)
      .then((res) => {
        if (res.data.status) {
          setDetail(res.data.template_details);
        } else {
          toast.error(res.data.message, {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateTemp = (id, e) => {
    e.preventDefault();
    let title = detail.title;
    let description = detail.description;
    if (detail.title != "" && detail.description != "") {
      axiosInstance
        .post(baseUrl + "updateTemplates", {
          title,
          description,
          id,
        })
        .then((res) => {
          if (res.data.status) {
            templateLists();
            setShow(true);
            setDetail({});
          } else {
            toast.error(res.data.message, {
              position: "bottom-center",
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.warning("Please add title and description", {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    dispatch(getTemplateDetails(e.selected, searchKey));
  };

  const onSearchHandler = () => {
    dispatch(getTemplateDetails(0, searchKey));
  };

  const onUpdateSignatureKey = () => {
    let data = {
      description: signatureKey.description,
      id: signatureKey._id,
    };
    dispatch(
      updateSignature(data, () => {
        setShowSignEdit(false);
      })
    );
  };

  return (
    <>
      <Modal
        show={deleteshow}
        centered
        onHide={handleClose}
        className="removeModfal"
      >
        <Modal.Body>هل انت متأكد من الحذف؟</Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-modalClose" onClick={handleClose}>
            اغلاق
          </Button>
          <Button className="btn btn-modalSubmit" onClick={deleteTemp}>
            حذف
          </Button>
        </Modal.Footer>
      </Modal>
      <Header />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1> إدارة النماذج</h1>
        </div>
        <section className="section template__section">
          <div className="row">
            <div className="col-lg-12">
              <div className="form__wizard">
                <div className="card">
                  <div className="card-body">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="input-group faq__search">
                          <input
                            className="form-control bg-white m-0 search-control"
                            onChange={(e) => setSearchKey(e.target.value)}
                            type="text"
                            id="example-search-input"
                            placeholder="يبحث"
                          />
                          <span className="input-group-append">
                            <button
                              className="btn btn-outline-secondary bg-white"
                              onClick={() => onSearchHandler()}
                              id="templateForm"
                              type="button"
                            >
                              <i className="fa fa-search" />
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr className="marginB0" />
                    <Row>
                      <Col md={12} className="marginTop50">
                        {show && (
                          <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="">
                              <Form.Label>
                                عنوان النموذج
                                <span className="mandatory">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                onChange={(e) => setTitle(e.target.value)}
                              />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="">
                              <Form.Label>
                                وصف النموذج<span className="mandatory">*</span>
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={7}
                                onChange={(e) => setDescription(e.target.value)}
                              />
                            </Form.Group>
                            <Col className="d-flex justify-content-end p25">
                              <button className="btn btn-primary" type="submit">
                                إضافة النموذج
                              </button>
                            </Col>
                          </Form>
                        )}
                        {!show && (
                          <>
                            <Form.Group className="mb-3" controlId="">
                              <Form.Label>
                                عنوان النموذج
                                <span className="mandatory">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={detail.title}
                                onChange={(e) =>
                                  setDetail({
                                    ...detail,
                                    title: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="">
                              <Form.Label>
                                وصف النموذج<span className="mandatory">*</span>
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={7}
                                value={detail.description}
                                onChange={(e) =>
                                  setDetail({
                                    ...detail,
                                    description: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Col className="d-flex justify-content-end p25">
                              <button
                                className="btn btn-secondary ml-2"
                                onClick={() => setShow(true)}
                              >
                                اغلاق
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={(e) => updateTemp(detail._id, e)}
                                type="submit"
                              >
                                {" "}
                                تحديث النموذج{" "}
                              </button>
                            </Col>
                          </>
                        )}
                      </Col>
                      {/* {!superadmin == "" ? (
                        <Col md={12} className="marginTop50 d-flex">
                          <Col md={8} className="ml-2">
                            <Form.Group className="mb-3" controlId="">
                              <Form.Label>Signature</Form.Label>
                              <Form.Control
                                type="text"
                                value={signatureKey.description}
                                onChange={(e) =>
                                  setSignatureKey({
                                    ...signatureKey,
                                    description: e.target.value,
                                  })
                                }
                                disabled={!showSignEdit ? true : false}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={4} className="mt-2 mr-2">
                            {showSignEdit ? (
                              <button
                                className="btn btn-primary mt-4 pt-3"
                                onClick={(e) => onUpdateSignatureKey()}
                              >
                                {" "}
                                Update{" "}
                              </button>
                            ) : (
                              <button
                                className="btn btn-secondary mt-4 pt-3"
                                onClick={(e) => setShowSignEdit(true)}
                              >
                                {" "}
                                Edit{" "}
                              </button>
                            )}
                          </Col>
                        </Col>
                      ) : null} */}
                      <Col md={12}>
                        <Table
                          striped
                          borderless
                          hover
                          className="mt-5 template__table"
                          responsive
                        >
                          <thead>
                            <tr>
                              <th style={{ minWidth: "300px" }}>
                                {" "}
                                عنوان النموذج
                              </th>
                              <th style={{ minWidth: "400px" }}>
                                {" "}
                                وصف النموذج
                              </th>
                              <th style={{ minWidth: "200px" }}> اجراء</th>
                            </tr>
                          </thead>
                          <tbody>
                            {templates?.map((ele, i) => {
                              return (
                                <tr key={i}>
                                  <td style={{ minWidth: "300px" }}>
                                    <p>{ele.title}</p>
                                  </td>
                                  <td style={{ minWidth: "400px" }}>
                                    <p>{ele.description}</p>
                                  </td>
                                  <td
                                    style={{ minWidth: "200px" }}
                                    className="actions"
                                  >
                                    <button>
                                      <img
                                        src="assets/img/icons/eye.svg"
                                        onClick={(e) => tempDetail(ele._id, e)}
                                        alt=""
                                      />
                                      <Modal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                        size="lg"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                        className="template__modal"
                                      >
                                        <Modal.Body>
                                          <h4>{detail.title}</h4>
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: detail.description,
                                            }}
                                            style={{ whiteSpace: " pre-wrap" }}
                                          ></p>
                                        </Modal.Body>
                                      </Modal>
                                    </button>
                                    <button>
                                      <img
                                        src="assets/img/icons/edit.svg"
                                        onClick={() => editTemp(ele._id)}
                                        alt=""
                                      />
                                    </button>
                                    <button>
                                      <img
                                        src="assets/img/icons/delete.png"
                                        onClick={(e) =>
                                          handleDelete(ele._id, e)
                                        }
                                        alt=""
                                      />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Col>
                      <div className="paginationStyle">
                        {pageCount > 1 ? (
                          <ReactPaginate
                            previousLabel={<>❮</>}
                            nextLabel={<>❯</>}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            // breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            initialPage={page}
                            onPageChange={(e) => handlePageChange(e)}
                            containerClassName="pagination"
                            activeClassName="pageActive"
                          />
                        ) : null}
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Sidebar />
    </>
  );
};

export default Template;
