import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Header from '../componets/Header';
import Sidebar from '../componets/Sidebar';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { currentPage, 
    jobLists, 
    ListUnits, 
    RankLists, 
    subunitlists, 
    totalPageCount, 
    userLoader, 
    user_details, 
    workshift_details,
    manual_count,
    ad_count,
    csv_count
} from '../redux/TemplateSlice';
import {
    getSubunitData,
    getWorkShiftData,
    getUnitData,
    getUser,
    getRankData,
    insertUser,
    deleteUser,
    updateUser,
    uploadUserCSV,
    getUserSampleCSV,
    getJobList,
    syncAd
} from "../redux/actionCreator";
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useRef } from 'react';

function User() {

    const dispatch = useDispatch();
    const type_ref = useRef();

    const units = useSelector(ListUnits);
    const subUnitList = useSelector(subunitlists);
    const workShiftList = useSelector(workshift_details);
    const userList = useSelector(user_details);
    const rankList = useSelector(RankLists);
    const pageCount = useSelector(totalPageCount);
    const jobtitles = useSelector(jobLists);
    const currentPageNumber = useSelector(currentPage);
    const loader = useSelector(userLoader);
    const manul_cnt = useSelector(manual_count);
    const ad_cnt = useSelector(ad_count);
    const csv_cnt = useSelector(csv_count);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [username, setUsername] = useState('');
    const [employee_id, setEmpolyee_Id] = useState('');
    const [subunit, setSubunit] = useState('Select subunit');
    const [unit, setUnit] = useState("Select Unit");
    const [workshift, setWorkShift] = useState("Select workshift");
    const [rank, setRank] = useState("Select Rank");
    const [job_title, setJobTitle] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const [id, setId] = useState("");
    const [err, setErr] = useState({});
    const [delId, setDelId] = useState();
    const [type, setType] = useState("manual");
    const [gender, setGender] = useState("Select Gender");
    const [page, setPage] = useState(0);
    const [searchKey, setSearchKey] = useState("");
    const [csv, setCSV] = useState([]);
    const [jobText, setJobText] = useState([]);

    useEffect(() => {
        document.title = 'TSD - إدارة الموظفين';
    }, []);


    useEffect(() => {
        dispatch(getUser(searchKey, page));
        dispatch(getUnitData());
        dispatch(getRankData());
        dispatch(getJobList());
    }, [])

    const onUnitSelecthandler  = (e) => {
        if (e.target.value == "Select Unit") {
            setUnit("");
            setSubunit("Select subunit")
            setWorkShift("Select workshift")
        } else {
            setUnit(e.target.value);
            setWorkShift("Select workshift");
            dispatch(getSubunitData(e.target.value));
        }
    }

    const onSubUnitSelecthandler  = (e) => {
        if (e.target.value == "Select subunit") {
            setSubunit("");
            setWorkShift("Select workshift");
        } else {
            setSubunit(e.target.value);
            dispatch(getWorkShiftData(e.target.value));
        }
    }

    const validate  = () => {
        let temp = {}
        if (name == "" || name.trim()?.length==0) {
            temp = { ...temp, name: "الرجاء إدخال الاسم" }
        }
        if (email == "" || email.trim()?.length==0) {
            temp = { ...temp, email: "الرجاء إدخال البريد الإلكتروني" }
        } else {
            if (typeof email !== "undefined") {
                let lastAtPos = email.lastIndexOf("@");
                let lastDotPos = email.lastIndexOf(".");
                if (
                    !(
                        lastAtPos < lastDotPos &&
                        lastAtPos > 0 &&
                        email.indexOf("@@") == -1 &&
                        lastDotPos > 2 &&
                        email.length - lastDotPos > 2
                    )
                ) {
                    temp = { ...temp, email: "الرجاء إدخال بريد إلكتروني صحيح" }
                }
            }
        }
        if (phone == "" || phone.trim()?.length==0) {
            temp = { ...temp, phone: "الرجاء إدخال رقم الهاتف" }
        } else {
            // if (phone.length < 9 || phone.length > 13) {
            //     temp = { ...temp, phone: "الرجاء إدخال رقم هاتف صحيح" }
            // }
            if (phone.length < 9) {
                temp = { ...temp, phone: "الرجاء إدخال رقم صحيح"}
            } else {
                if (phone.length  ==  9 || phone.length <=  13) {
                    let str  =  phone.replace("+","");
                    if(!str.match(/^[0-9]+$/)) {
                        temp = { ...temp, phone: "الرجاء إدخال رقم صحيح" }
                    } 
                } else {
                    temp = { ...temp, phone: "الرجاء إدخال رقم صحيح" }
                }
            }
        }
        if (employee_id == "" || employee_id.trim()?.length==0) {
            temp = { ...temp, employee_id: "الرجاء ادخال الرقم الوظيفي " }
        }
        if (job_title?.length == 0 && jobText?.length == 0) {

            temp = { ...temp, job_title: "الرجاء تحديد المسمى الوظيفي" }
        }
        if (username == "" || username.trim()?.length==0) {
            temp = { ...temp, username: "الرجاء إدخال اسم المستخدم" }
        }
        if (rank == "" || rank == "Select Rank") {
            temp = { ...temp, rank: "الرجاء تحديد الدرجة" }
        }
        if (unit == "" || unit == "Select Unit") {
            temp = { ...temp, unit: "الرجاء تحديد الوحدة" }
        }
        if (gender == "" || gender == "Select Gender") {
            temp = { ...temp, gender: "يرجى تحديد الجنس" }
        }
        if (Object.values(temp).length != 0) {
            setErr(temp)
            return false
        } else {
            setErr({})
            return true
        }
    }

    const handleSubmit  = (e) => {
        e.preventDefault();
        if (validate()) {
            let data = {
                name,
                unit,
                subunit: subunit == 'Select subunit' ? "" : subunit,
                workshift: workshift == "Select workshift" ? "" : workshift,
                rank,
                job_title: job_title[0] ? job_title[0] : jobText[0],
                email,
                phone,
                username,
                employee_id,
                gender
            }
            dispatch(insertUser(data, (msg) => {
                if (msg) {
                    toast.error(`${msg[0].message}`, {
                        position: "bottom-center",
                        autoClose: 3000
                    });
                } else {
                    toast.success('تمت إضافة المستخدم بنجاح', {
                        position: "bottom-center",
                        autoClose: 3000
                    });
                    setName("");
                    setEmail("");
                    setPhone("");
                    setUsername("");
                    setEmpolyee_Id("");
                    setJobTitle([]);
                    setJobText([]);
                    setRank("Select Rank");
                    setUnit("Select Unit");
                    setSubunit("Select subunit");
                    setWorkShift("Select workshift");
                    setGender("Select Gender");
                    document.getElementById('unit').value = "Select Unit";
                    document.getElementById('subUnit').value = "Select subunit";
                    document.getElementById('workshift').value = "Select workshift";
                    document.getElementById('rank').value = "Select rank";
                    setErr({});
                    type_ref.current.clear()
                }
            }))
        }
    }

    const update  = (e) => {
        e.preventDefault();
        if (validate()) {
            let data = {
                name,
                unit,
                subunit: subunit == 'Select subunit' ? "" : subunit,
                workshift: workshift == "Select workshift" ? "" : workshift,
                rank,
                job_title: job_title[0] ? job_title[0] : jobText[0],
                email,
                phone,
                username,
                employee_id,
                gender,
                id
            }
            dispatch(updateUser(data, searchKey, page, () => {
                setShowEdit(false);
                toast.success('تم تحديث المستخدم بنجاح', {
                    position: "bottom-center",
                    autoClose: 3000
                });
                setName("");
                setEmail("");
                setPhone("");
                setUsername("");
                setEmpolyee_Id("");
                setJobTitle([]);
                setJobText([]);
                setRank("Select Rank");
                setUnit("Select Unit");
                setSubunit("Select subunit");
                setWorkShift("Select workshift");
                setGender("Select Gender");
                setId("");
                document.getElementById('unit').value = "Select Unit";
                document.getElementById('subUnit').value = "Select subunit";
                document.getElementById('workshift').value = "Select workshift";
                document.getElementById('rank').value = "Select rank";
                setErr({});
                type_ref.current.clear();
            }))
        }
    }

    const handleClose  = () => setShowDeleteModal(false);

    const onDeleteHandler  = () => {
        dispatch(deleteUser(delId, searchKey, page, () => {
            setShowDeleteModal(false);
            setDelId("");
            toast.success('تم حذف المستخدم بنجاح', {
                position: "bottom-center",
                autoClose: 3000
            });
        }))
    }

    const deleteUserData  = (id) => {
        setShowDeleteModal(true);
        setDelId(id);
    }

    const editUser  = (ele) => {
        setId(ele._id);
        setShowEdit(true);
        if (ele.unit?.length) {
            dispatch(getSubunitData(ele.unit[0]._id));
        }
        if (ele.subunit?.length) {
            dispatch(getWorkShiftData(ele.subunit[0]._id));
        }
        setName(ele.name);
        setEmail(ele.email);
        setPhone(ele.phone);
        setUsername(ele.username);
        setEmpolyee_Id(ele.employee_id);
        setJobTitle([ele.job_title]);
        setGender(ele.gender);
        if (ele.rank?.length) {
            setRank(ele.rank[0]);
        }
        if (ele.unit?.length) {
            setUnit(ele.unit[0]._id);
        }
        if (ele.subunit?.length) {
            setSubunit(ele.subunit[0]._id);
        }
        if (ele.workshift?.length) {
            setWorkShift(ele.workshift[0]._id);
        }
        const divElement = document.getElementById('userForm');
        divElement.scrollIntoView({ behavior: 'smooth' });
    }

    const handlePageChange  = (e) => {
        setPage(e.selected);
        dispatch(getUser(searchKey, e.selected));
    }

    const onUploadHandler  = (e) => {
        setCSV(e.target.files);
    }

    const onFileUploadHandler  = () => {
        if (csv?.length) {
            let formData = new FormData();
            formData.append("user_list", csv[0]);
            dispatch(uploadUserCSV(formData, () => {
                setCSV([]);
            }))
        } else {
            toast.warning('يرجى تحميل ملف csv', {
                position: "bottom-center",
                autoClose: 3000
            });
        }
    }

    const onSearchHandler  = () => {
        dispatch(getUser(searchKey, 0));
    }

    const userSampleCsv  = () => {
        dispatch(getUserSampleCSV());
    }

    const onGetSyncHandler  = () => {
        dispatch(syncAd());
    }

    return (
        <>
            <Modal show = {showDeleteModal} centered className = 'removeModfal' onHide = {handleClose}>
                <Modal.Body>هل تريد أن تحذف؟</Modal.Body>
                <Modal.Footer>
                    <Button className = 'btn btn-modalClose' onClick = {handleClose}>
                    اغلاق
                    </Button>
                    <Button className = 'btn btn-modalSubmit' onClick = {() => onDeleteHandler()}>
                    حذف
                    </Button>
                </Modal.Footer>
            </Modal>
            <Header />
            <main id = "main" className = "main">
                <div className = "pagetitle">
                    <h1>إدارةالموظفين </h1>
                </div>
                <section className = "section template__section">
                    <div className = "row">
                        <div className = "col-lg-12">
                            <div className = "form__wizard">
                                <div className = "card">
                                    <div className = "card-body">
                                        <div className = 'd-flex justify-content-end cardMtop align-items-center mb-3'>
                                            <button className = "btn btn-primary m-2" onClick = {() => onGetSyncHandler()}>Sync</button>
                                        </div>
                                        <div className = "row">
                                            <div className = "col-md-12" id = 'userForm'>
                                                <div className = "row">
                                                    <div className = "col-md-3">
                                                        <Form.Group className = "mb-3" controlId = "">
                                                            <Form.Label>الاسم<span className = 'mandatory'>*</span></Form.Label>
                                                            <Form.Control type = "text"
                                                                value = {name}
                                                                placeholder = 'الاسم'
                                                                onChange = {e => setName(e.target.value)} />
                                                            <div className = "text-danger">
                                                                <small>{err.name}</small>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className = "col-md-3">
                                                        <Form.Group className = "mb-3" controlId = "">
                                                            <Form.Label>البريد الالكتروني<span className = 'mandatory'>*</span></Form.Label>
                                                            <Form.Control type = "email"
                                                            placeholder = 'البريد الالكتروني'
                                                                value = {email}
                                                                onChange = {e => setEmail(e.target.value)} />
                                                            <div className = "text-danger">
                                                                <small>{err.email}</small>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className = "col-md-3">
                                                        <Form.Group className = "mb-3" controlId = "">
                                                            <Form.Label>رقم الهاتف<span className = 'mandatory'>*</span></Form.Label>
                                                            <Form.Control type = "text"
                                                            placeholder = 'رقم الهاتف'
                                                                value = {phone}
                                                                onChange = {e => setPhone(e.target.value)} />
                                                            <div className = "text-danger">
                                                                <small>{err.phone}</small>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className = "col-md-3">
                                                        <Form.Group className = "mb-3" controlId = "">
                                                            <Form.Label>اسم المستخدم <span className = 'mandatory'>*</span></Form.Label>
                                                            <Form.Control type = "text"
                                                            placeholder = 'اسم المستخدم '
                                                                value = {username}
                                                                onChange = {e => setUsername(e.target.value)} />
                                                            <div className = "text-danger">
                                                                <small>{err.username}</small>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className = "col-md-3">
                                                        <Form.Group className = "mb-3" controlId = "">
                                                            <Form.Label>الرقم  الوظيفي<span className = 'mandatory'>*</span></Form.Label>
                                                            <Form.Control type = "text"
                                                            placeholder = 'الرقم  الوظيفي'
                                                                value = {employee_id}
                                                                onChange = {e => setEmpolyee_Id(e.target.value)} />
                                                            <div className = "text-danger">
                                                                <small>{err.employee_id}</small>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className = "col-md-3">
                                                        <Form.Group className = "mb-3" controlId = "">
                                                            <Form.Label>المسمى الوظيفي<span className = 'mandatory'>*</span></Form.Label>
                                                            <AsyncTypeahead id = "job_typeahead"
                                                                ref= {type_ref}
                                                                labelKey="job"
                                                                className = "width-100 typeaheadrtl"
                                                                inputProps={{
                                                                    className: 'floating-input',
                                                                    autoComplete: "false"
                                                                }}
                                                                maxResults={10}
                                                                minLength={2}
                                                                selected={job_title}
                                                                options={jobtitles}
                                                                onInputChange={query => {
                                                                    setJobText([query])
                                                                }}
                                                                onChange = {option => {
                                                                    setJobTitle(option)
                                                                }}
                                                                placeholder={"البحث عن الوظيفة"}
                                                                autoComplete="off"
                                                                useCache={false} />
                                                            <div className = "text-danger">
                                                                <small>{err.job_title}</small>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className = "col-md-3">
                                                        <Form.Group className = "mb-3" controlId = "">
                                                            <Form.Label>الرتبة<span className = 'mandatory'>*</span></Form.Label>
                                                            <div className = "form-group">
                                                                <div className = "custom-select without-label">
                                                                    <select className = "form-control form-select"
                                                                        aria-label=""
                                                                        id = "rank"
                                                                        value = {rank}
                                                                        onChange = {(e) => setRank(e.target.value)}
                                                                    >
                                                                        <option disabled selected value = {"Select Rank"}>إختيار</option>
                                                                        <option value = {""}>Select</option>
                                                                        {
                                                                            rankList?.map((ele, i) => {
                                                                                return (
                                                                                    <option value = {ele._id} >{ele.rank}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className = "text-danger">
                                                                <small>{err.rank}</small>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className = "col-md-3">
                                                        <Form.Group className = "mb-3" controlId = "">
                                                            <div className = "custom-select">
                                                                <Form.Label>الجنس<span className = 'mandatory'>*</span></Form.Label>
                                                                <select className = "form-control form-select"
                                                                    aria-label=""
                                                                    value = {gender}
                                                                    onChange = {(e) => setGender(e.target.value)}
                                                                >
                                                                    <option disabled selected value = {"Select Gender"}>إختيار</option>
                                                                    {/* <option value="male">Male</option>
                                                                    <option value="female">Female</option> */}
                                                                    <option value = "ذكر">ذكر</option>
                                                                    <option value = "انثى">انثى</option>
                                                                </select>
                                                            </div>
                                                            <div className = "text-danger">
                                                                <small>{err.gender}</small>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className = "col-md-3">
                                                        <Form.Group className = "mb-3" controlId = "">
                                                            <Form.Label>القسم<span className = 'mandatory'>*</span></Form.Label>
                                                            <div className = "form-group">
                                                                <div className = "custom-select without-label">
                                                                    <select className = "form-control form-select"
                                                                        id = "unit"
                                                                        aria-label=""
                                                                        value = {unit}
                                                                        onChange = {(e) => onUnitSelecthandler(e)}
                                                                    >
                                                                        <option selected value = {"Select Unit"}>إختيار</option>
                                                                        {
                                                                            units?.map((ele, i) => {
                                                                                return (
                                                                                    <option value = {ele._id} >{ele.unit_name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className = "text-danger">
                                                                <small>{err.unit}</small>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className = "col-md-3">
                                                        <Form.Group className = "mb-3" controlId = "">
                                                            <Form.Label>الشعبة </Form.Label>
                                                            <div className = "form-group">
                                                                <div className = "custom-select without-label">
                                                                    <select className = "form-control form-select"
                                                                        disabled={unit?.length == 0 || unit == "Select Unit"}
                                                                        aria-label=""
                                                                        id = "subUnit"
                                                                        value = {subunit}
                                                                        onChange = {(e) => onSubUnitSelecthandler(e)}
                                                                    >
                                                                        <option selected value = {"Select subunit"}>إختيار</option>
                                                                        {
                                                                            subUnitList?.map((ele, i) => {
                                                                                return (
                                                                                    <option value = {ele._id} >{ele.subunit_name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className = "col-md-3">
                                                        <Form.Group className = "mb-3" controlId = "">
                                                            <Form.Label>الفئة </Form.Label>
                                                            <div className = "form-group">
                                                                <div className = "custom-select without-label">
                                                                    <select className = "form-control form-select"
                                                                        aria-label=""
                                                                        id = "workshift"
                                                                        disabled={subunit?.length == 0 || subunit == "Select subunit"}
                                                                        value = {workshift}
                                                                        onChange = {(e) => {
                                                                            if (e.target.value == "Select workshift") {
                                                                                setWorkShift("")
                                                                            } else {
                                                                                setWorkShift(e.target.value)
                                                                            }
                                                                        }}
                                                                    >
                                                                        <option disabled selected value = {"Select workshift"}>إختيار</option>
                                                                        {
                                                                            workShiftList?.map((ele, i) => {
                                                                                return (
                                                                                    <option value = {ele._id} >{ele.workshift_name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <button className = "btn btn-primary"
                                                    onClick = {(e) =>
                                                    !showEdit ? handleSubmit(e) : update(e)}>
                                                        {!showEdit ? "إضافة" : "تحديث "}
                                                </button>
                                            </div>
                                            <div className = 'row align-items-center mt-5'>
                                                <Form.Label>تحميل Xlsx</Form.Label>
                                                <div className = "col-md-6 d-flex flexColumn">
                                                    <input type = "file"
                                                        name="uploadfile"
                                                        onChange = {(e) => onUploadHandler(e)}
                                                        id = "img" style={{ display: "none" }} />
                                                    <label htmlFor="img"
                                                        className = 'form-control mb-0'>{csv?.length ? csv[0].name : "تحميل ملف Xlsx"}</label>
                                                </div>
                                                <div className = "col-md-6 d-flex">
                                                    <button className = 'btn btn-primary'
                                                        onClick = {() => onFileUploadHandler()}
                                                    >تحميل Xlsx
                                                    </button>
                                                    <button className = 'btn btn-secondary' style={{ marginRight: "10px" }} onClick = {() => userSampleCsv()}>
                                                    تنزيل نموذج Xlsx
                                                    </button> 
                                                </div>
                                            </div>
                                            <div className = "col-md-6">
                                                <div className = "table__search">
                                                    <input className = "form-control bg-white" onChange = {(e) => setSearchKey(e.target.value)} type = "text" id = "example-search-input" placeholder = 'بحث' />
                                                    <span className = "input-group-append">
                                                        <button className = "btn btn-primary" type = "button" onClick = {() => onSearchHandler()}>بحث</button>
                                                    </span>
                                                </div>
                                            </div>	
                                            <div className="col-md-12 mt-4">
                                            <Table striped borderless hover responsive>
                                            <thead className='count__lists'>
                                                    <tr>
                                                        <th>active directory : <span>{ad_cnt}</span></th>
                                                        <th>Xlsx Upload : <span>{csv_cnt}</span></th>
                                                        <th>Manual : <span>{manul_cnt}</span></th>
                                                     </tr>
                                                </thead>
                                            </Table>
                                            </div>
                                            <div className = "col-md-12">
                                                {
                                                    loader
                                                        ? <div className = "d-flex justify-content-center">
                                                            <div className = "spinner-border text-success" role="status">
                                                                <span className = "sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                        : <>
                                                            {userList?.length != 0
                                                                ? <Table striped borderless hover responsive>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>الاسم </th>
                                                                            <th>البريد الالكتروني</th>
                                                                            <th>الجنس</th>
                                                                            <th>الرقم  الوظيفي</th>
                                                                            <th>المسمى الوظيفي </th>
                                                                            <th>رقم الهاتف</th>
                                                                            <th>اسم المستخدم </th>
                                                                            <th style={{minWidth:"125px"}}>اجراء</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            userList?.map((ele, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{ele.name}</td>
                                                                                        <td style={{maxWidth:"180px"}}>{ele.email}</td>
                                                                                        <td>{ele.gender}</td>
                                                                                        <td>{ele.employee_id}</td>
                                                                                        <td>{ele.job_title}</td>
                                                                                        <td>{ele.phone}</td>
                                                                                        <td>{ele.username}</td>
                                                                                        <td>
                                                                                            <button className = "btn btn-sm" onClick = {() => editUser(ele)}><img src="assets/img/icons/table-edit.png" alt="" /></button>
                                                                                            <button className = "btn btn-sm" onClick = {() => deleteUserData(ele._id)}><img src="assets/img/icons/table-delete.png" alt="" /></button>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            : null
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className = 'paginationStyle'>
                                            {
                                                pageCount > 1
                                                    ? <ReactPaginate
                                                        previousLabel={<>❮</>}
                                                        nextLabel={<>❯</>}
                                                        pageClassName="page-item"
                                                        pageLinkClassName="page-link"
                                                        previousClassName="page-item"
                                                        previousLinkClassName="page-link"
                                                        nextClassName="page-item"
                                                        nextLinkClassName="page-link"
                                                        // breakLabel="..."
                                                        breakClassName="page-item"
                                                        breakLinkClassName="page-link"
                                                        pageCount={pageCount}
                                                        marginPagesDisplayed={1}
                                                        pageRangeDisplayed={5}
                                                        initialPage={page}
                                                        onPageChange={(e) => handlePageChange(e)}
                                                        containerClassName="pagination"
                                                        activeClassName="pageActive"
                                                    />
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Sidebar />
        </>
    )
}

export default User