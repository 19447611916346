import React,{useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Table } from 'react-bootstrap';
import Header from '../componets/Header';
import Sidebar from '../componets/Sidebar';
import { getUserRoles,
  deleteUserRoles,
  getModules,
  updateUserRoles
} from "../redux/actionCreator";
import {userRoles ,modules, rolesTotalCount } from '../redux/TemplateSlice';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactPaginate from 'react-paginate';

const ManageRole  = () => {
  
  const dispatch = useDispatch();

  const roles = useSelector(userRoles);
  const moduleList = useSelector(modules);
  const pageCount = useSelector(rolesTotalCount);

  const [showEdit, setShowEdit] = useState(false);
  const [module, setModule] = useState([]);
  const [access, setAccess] = useState([]);
  const [details, setDetails] = useState(null);
  const [deleteId, setDeleteID] = useState('');
  const [modalshow, setModalShow] = useState(false);
  const handleClose  = () => setModalShow(false);
  const handleShow  = () => setModalShow(true);
  const [page, setPage] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [signature,setSignature] = useState("");

  useEffect(() => {
    document.title = 'TSD - إدارة الصلاحيات';
  }, []);

  useEffect(() => {
    dispatch(getUserRoles(page,searchKey));
    dispatch(getModules());
  },[])

  const editUserRole  = (ele) =>  {
    setShowEdit(true);
    setDetails(ele);
    setModule(ele.permissions);
    setAccess(ele.type);
    setSignature(ele.signature)
  }

  const deleteUserRole  = () => {
    dispatch(deleteUserRoles(deleteId,page,searchKey,() =>  {
      setModalShow(false);
      toast.success('تمت إضافة الصلاحية المستخدم بنجاح', {
          position: "bottom-center",
          autoClose: 3000
      });
    }))
  }

  const onCancelEdithandler = () => {
    setShowEdit(false);
    setModule([]);
    setAccess([]);
    setDetails(null);
  }

  const manageRoleHandler  = () => {
    let formData = new FormData();
    module.forEach(item => formData.append('modules[]', item))
    let type;
    if(access?.length == 2){
      type = 3
    } else if(access?.includes("ad")) {
      type = 1
    } else if(access?.includes("manual")) {
      type = 2
    } else {
      type = 0
    }
    formData.append('type', type);
    formData.append('users', details._id);
    formData.append('signature', signature);
    dispatch(updateUserRoles(formData,() => {
      toast.success('تم تحديث صلاحية المستخدم بنجاح', {
        position: "bottom-center",
        autoClose: 3000
      });
      setShowEdit(false);
    }))
  }

  const handleRemove = (id) => {
    setModalShow(true);
    setDeleteID(id);
  }

  const backBtn = () => {
    setShowEdit(false);
  }

  const handlePageChange  = (e) => {
    setPage(e.selected);
    dispatch(getUserRoles(e.selected,searchKey));
  }

  const onSearchHandler  = () =>  {
    dispatch(getUserRoles(0,searchKey));
  }

  return (
    <>
      <Modal show = {modalshow} centered onHide = {handleClose} className = 'removeModfal'>
      <Modal.Body>هل تريد أن تحذف؟</Modal.Body>
      <Modal.Footer>
        <Button className = 'btn btn-modalClose' onClick = {handleClose}>
          اغلاق
        </Button>
        <Button className = 'btn btn-modalSubmit' onClick = {deleteUserRole}>
          حذف 
        </Button>
      </Modal.Footer>
      </Modal>
      <Header/>
      <main id = "main" className = "main">
          <div className = "pagetitle">
              <h1>إدارة الصلاحية</h1>
          </div>
          <section className = "section template__section">
              <div className = "row">
                  <div className = "col-lg-12">
                      <div className = "form__wizard">
                        {
                          !showEdit
                          ? <div className = "card">
                              <div className = "card-body">
                                <div className = 'd-flex justify-content-between cardMtop align-items-center'>
                                  <h6 className = 'm-0 form__title'>إدارة الصلاحية</h6>
                                  <div className = "form__wizard">
                                    <div className = "form-group">
                                      <div className = "keyword__search table__search">
                                        <div className = "form-group w-100">
                                          <input type = "text"
                                            className = "form-control mb-0 border-left-0 bg-transparent"
                                            onChange = {e=>setSearchKey(e.target.value)}
                                          /> 
                                        </div>
                                        <button type = "button"
                                          style = {{padding: "11px"}}
                                          onClick = {() => onSearchHandler()}
                                          className = "btn btn-search bg-transparent">
                                            <img src="assets/img/icons/search-form.svg" alt="" />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                              <hr/>
                              <div className = "selected__users">
                                  <Table striped borderless hover responsive>
                                    <thead>
                                      <tr>
                                        <th> الاسم</th>
                                        <th> البريد الالكتروني</th>
                                        <th> اسم المستخدم</th>
                                        <th> الوظيفة</th>
                                        <th>الاجراءات</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        roles?.map((ele, i) => {
                                          return(
                                          <tr>
                                            <td>{ele.name}</td>
                                            <td>{ele.email}</td>
                                            <td>{ele.username}</td>
                                            <td>{ele.job_title}</td>
                                            <td> 
                                              <button className = "btn btn-sm" onClick = {() => editUserRole(ele)}>
                                                <img src="assets/img/icons/table-edit.png" alt="" />
                                              </button>
                                              <button className = "btn btn-sm" onClick = {() => handleRemove(ele._id)}>
                                                <img src="assets/img/icons/table-delete.png" alt="" />
                                              </button>
                                            </td>
                                          </tr>
                                          )
                                        })
                                      }
                                    </tbody>
                                  </Table>
                              </div>
                              <div className = 'paginationStyle'>
                                {
                                    pageCount > 1
                                    ? <ReactPaginate
                                        previousLabel={<>❮</>}
                                        nextLabel={<>❯</>}
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        // breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        pageCount={pageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={5}
                                        initialPage = {page}
                                        onPageChange={(e) => handlePageChange(e)}
                                        containerClassName="pagination"
                                        activeClassName="pageActive"
                                        />
                                    : null
                                }
                            </div>
                          </div>
                        </div>
                      : <div className = "card">
                          <div className = "card-body">
                            <div className = 'd-flex justify-content-between cardMtop align-items-center'>
                              <h6 className = 'm-0 form__title'>تعديل الصلاحيات</h6>
                              <div className = "arrow__role" onClick = {backBtn}>
                                <img src="assets/img/icons/left-arrow.svg" alt="" />
                              </div>
                            </div>
                            <hr/>
                            <div className = "selected__users">
                              <Table borderless>
                                <thead>
                                  <tr>
                                    <th> الاسم</th>
                                    <th> البريد الالكتروني</th>
                                    <th> اسم المستخدم</th>
                                    <th> الوظيفة</th>
                                    <th> رقم الهاتف</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{details?.name}</td>
                                    <td>{details?.email}</td>
                                    <td>{details?.username}</td>
                                    <td>{details?.job_title}</td>
                                    <td>{details?.phone}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                            <div className = 'd-flex justify-content-between cardMtop align-items-center'>
                              <h6 className = 'm-0 form__title'>تعديل صلاحيات المستخدم</h6>
                            </div>
                            <hr/>
                            <div className = "row">
                              <div className = "module__list">
                                  {
                                    moduleList?.map((ele, i) => {
                                        return(
                                            <div className = "select__box">
                                              <Form.Check type='checkbox'
                                                  id = {`${i}`}
                                                  checked = {module.includes(ele.key) ? true : false}
                                                  onClick = {(e) => 
                                                      e.target.checked
                                                      ? setModule([...module, ele.key])
                                                      : setModule(module.filter(item => item != ele.key))
                                                  }
                                              />
                                                <Form.Label className = 'ml-2 mt-2' for = {i}>{ele.value}</Form.Label>
                                            </div>
                                          )
                                      }
                                    )
                                  }
                              </div>
                            </div>
                            <div className = 'd-flex justify-content-between cardMtop align-items-center'>
                              <h6 className = 'm-0 form__title'>صلاحية الدخول</h6>
                            </div>
                            <hr/>
                            <div className = "row">
                              <div className = "module__list">
                                <div className = "select__box ml-2">
                                  <Form.Check type='checkbox'
                                      id={`DU1`}
                                      checked = {access.includes("manual") ? true : false}
                                      onClick = {(e) => 
                                          e.target.checked
                                          ? setAccess([...access, "manual"])
                                          : setAccess(access.filter(item => item != "manual"))
                                      }
                                  />
                                  <Form.Label className = 'ml-2 mt-2' for='DU1'>تسجيل الدخول اليدوي</Form.Label>
                                </div>
                                <div className = "select__box">
                                      <Form.Check type='checkbox'
                                          id={`DU2`}
                                          checked = {access.includes("ad") ? true : false}
                                          onClick = {(e) => 
                                              e.target.checked
                                              ? setAccess([...access, "ad"])
                                              : setAccess(access.filter(item => item != "ad"))
                                          }
                                      />
                                      <Form.Label className = 'ml-2 mt-2' for='DU2'>احقية TSD</Form.Label>
                                  </div>
                                </div>
                                <div className = "row">
                                <textarea
                                                    rows={3}
                                                    className="form-control"
                                                    value={signature}
                                                    onChange={(e) => setSignature(e.target.value)}
                                                >
                                                    
                                                </textarea>
                                    {/* <Form.Group className="mb-3" controlId="">
                                        <Form.Label>Signature</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={signature}
                                            onChange={(e) => setSignature(e.target.value)}
                                        />
                                    </Form.Group> */}
                                </div>
                                <div className = "col-md-12 mt-4">
                                  <button className = 'btn btn-danger' onClick = {() => onCancelEdithandler()}>الغاء</button>
                                  <button className = 'btn btn-black' onClick = {() => manageRoleHandler()}>تحديث</button>
                              </div>
                          </div>
                      </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </section>
      </main>
      <Sidebar/>
    </>
  )
}

export default ManageRole