import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../componets/Header';
import Sidebar from '../componets/Sidebar';
import { baseUrl } from '../Constants';
import {RankLists } from '../redux/TemplateSlice';
import Table from 'react-bootstrap/Table';
import { getRankData } from '../redux/actionCreator';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axiosInstance from "../utils/axiosInterceptor";
import ReactPaginate from 'react-paginate';

function Rank() {

  const dispatch = useDispatch();

  const FetchRanklists= useSelector(RankLists);

  const [rank, setRank] = useState('');
  const [detail, setDetail] = useState({});
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteID] = useState('');
  const [modalshow, setModalShow] = useState(false);
  const handleClose  = () => setModalShow(false);
  const handleShow  = () => setModalShow(true);
  const [page, setPage] = useState(0);
  const [rankList, setRankList] = useState([]);
  const [pageCount,setPageCount] = useState(null)

  useEffect(() => {
    document.title = 'TSD - الرتبة ';
  }, []);

  useEffect(() => {
      Ranklists();
  }, [])

  useEffect(() =>  {
    if(FetchRanklists) {
        setPageCount(Math.ceil(FetchRanklists?.length/10));
        pageDataHandler(page);
    }
  },[FetchRanklists])

  const pageDataHandler  = (pag) => {
    if(FetchRanklists.slice(pag*10,pag*10+10)?.length == 0 && pag != 0) {
      setPage(pag-1);
      setRankList(FetchRanklists.slice((pag-1)*10,(pag-1)*10+10));
    } else {
      setRankList(FetchRanklists.slice(pag*10,pag*10+10));
    }
  }
  
  const Ranklists = () => {
    dispatch(getRankData());
  }

  const handleSubmit  = (e) => {
    e.preventDefault();
    if(rank==''){
      toast.warning('الرجاء إدخال الرتبة', {
        position: "bottom-center",
        autoClose: 3000
      });
    }else{
      axiosInstance.post(baseUrl+"insertRank", {
        rank
      }).then((res) => {
        if(res.data.status) {
            Ranklists();
            e.target.reset();
            toast.success('تم إدراج الرتبة بنجاح', {
                position: "bottom-center",
                autoClose: 3000
            });
            setRank("")
          } else {
            toast.error(res.data.message, {
              position: "bottom-center",
              autoClose: 3000
            })
          }
      }).catch((err) => {
        console.log(err)
      })
    }
  };

  const rankEdit = (id) => {
    setShow(true);
    axiosInstance.get(baseUrl+"getRankDetail/"+id).then((res) => {
        if(res.data.status) {
          setDetail(res.data.rank_details);
        } else {
          toast.error(res.data.message, {
            position: "bottom-center",
            autoClose: 3000
          });
        }
      }).catch((err) => {
        console.log(err);
      })
  }

  const handleUpdate = (id,e) => {
    e.preventDefault();
    const rank = detail.rank;
    const data = {
      rank,
      id
    };
    axiosInstance.post(baseUrl+'updateRank',data).then((res) => {
      if(res.data.status) {
          Ranklists();
          setShow(false);
          toast.success('تم تحديث التصنيف بنجاح', {
              position: "bottom-center",
              autoClose: 3000
          });
        } else {
          toast.error(res.data.message, {
            position: "bottom-center",
            autoClose: 3000
          });
        }
    }).catch((err) => {
      console.log(err);
    })
  }

  const handleRemove = (id) => {
    setModalShow(true);
    setDeleteID(id);
  }

  const rankDelete  = () => {
    const data = {
      del_id: deleteId
    };
    axiosInstance.post(baseUrl+'deleteRank/',data).then((res) => {
      if(res.data.status) {
        Ranklists();
        setModalShow(false);
        toast.success('تم حذف التصنيف بنجاح', {
            position: "bottom-center",
            autoClose: 3000
        });
      } else {
        setModalShow(false);
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        });
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const handlePageChange  = (e) => {
    setPage(e.selected)
    pageDataHandler(Number(e.selected))
  }

  return (
    <>
      <Modal show = {modalshow} centered onHide = {handleClose} className = 'removeModfal'>
        <Modal.Body>هل تريد أن تحذف</Modal.Body>
        <Modal.Footer>
          <Button className = 'btn btn-modalClose' onClick = {handleClose}>
            اغلاق
          </Button>
          <Button className = 'btn btn-modalSubmit' onClick = {rankDelete}>
            حذف
          </Button>
        </Modal.Footer>
      </Modal>
      <Header/>
      <main id = "main" className = "main">
        <div className = "pagetitle">
          <h1>الرتبة </h1>
        </div>
        <section className = "section template__section">
          <div className = "row">
            <div className = "col-lg-12">
              <div className = "form__wizard">
                <div className = "card">
                  <div className = "card-body">
                  <div className = "default__heading mb-5"><h1>الرتبة </h1></div>
                    <div className = "row">
                      <div className = "col-md-6">
                        {
                          !show 
                          && <Form onSubmit = {handleSubmit}>
                                <Form.Group className = "mb-3" controlId = "">
                                  <Form.Label>الرتبة</Form.Label>
                                  <Form.Control type = "text" onChange = {e=>setRank(e.target.value)}/>
                                </Form.Group>
                                <button className = "btn btn-primary" type = "submit">إضافة</button>
                            </Form> 
                        }
                        {
                          show 
                          && <Form>
                              <Form.Group className = "mb-3" controlId = "">
                                <Form.Label>الرتبة</Form.Label>
                                <Form.Control type = "text" 
                                  value = {detail.rank} 
                                  onChange = {e => setDetail({...detail,rank:e.target.value})}/>
                              </Form.Group>
                              <button className = "btn btn-primary"
                                type = "submit" 
                                onClick = {(e) => handleUpdate(detail._id,e)} >
                                تحديث</button>
                            </Form> 
                          }
                      </div>
                      <div className = "col-md-6">
                        <Table striped bordered hover>
                          <thead>
                              <tr>
                              <th className = 'w-100'>الرتبة</th>
                              <th style={{minWidth:"150px"}}>اجراء</th>
                              </tr>
                          </thead>
                          <tbody>
                            {
                              rankList?.map((ele,i) => {
                                  return(
                                      <tr key ={i}>
                                          <td className = 'w-100'>{ele.rank}</td>
                                          <td style={{minWidth:"150px"}}> 
                                              <button className = "btn btn-sm" onClick = {() => rankEdit(ele._id)}>
                                                <img src="assets/img/icons/table-edit.png" alt="" />
                                              </button>
                                              <button className = "btn btn-sm" onClick = {() => handleRemove(ele._id)}>
                                                <img src="assets/img/icons/table-delete.png" alt="" />
                                              </button>
                                          </td>
                                      </tr>
                                  )
                              })
                            }
                          </tbody>
                        </Table>
                        <div className = 'paginationStyle'>
                          {
                            pageCount > 1
                            ? <ReactPaginate
                                previousLabel={<>❮</>}
                                nextLabel={<>❯</>}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                // breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={pageCount}
                                initialPage = {page}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={5}
                                onPageChange={(e) => handlePageChange(e)}
                                containerClassName="pagination"
                                activeClassName="pageActive"
                                forcePage={page}
                                />
                            : null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Sidebar/>
    </>
  )
}

export default Rank
