import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { webUrl } from '../Constants';
import { getModulePermission } from '../redux/actionCreator';
import { user_role } from '../redux/TemplateSlice';
import { modulePermission } from '../utils/helpers';

function Sidebar() {

  const dispatch  =  useDispatch();

  const userRole  =  useSelector(user_role);

  const [Active, setActive]  =  useState(false);
  const [Active2, setActive2]  =  useState(false);
  
  
  useEffect(() => {
    if (window.location.href ==  webUrl + 'designation' 
      || window.location.href ==  webUrl + 'rank' 
      || window.location.href ==  webUrl + 'unit'
    ) {
      setActive(true);
    }
    if (window.location.href ==  webUrl + 'role' || window.location.href == webUrl + 'manage-role') {
      setActive2(true);
    }
  }, [])

  useEffect(() => {
    dispatch(getModulePermission());
  }, [])

  const closeSidebar = () => {
    document.body.classList.remove('toggle-sidebar');
  }

  return (
    <React.Fragment>
      <aside id = "sidebar" className = "sidebar">
        <div className = "logo__section textAlignCenter">
          <img src = "assets/img/innerLogo.svg" alt = "logo" style = {{ height: "98px" }} />
          <div className = "devider"></div>
        </div>
        <ul className = "sidebar-nav" id = "sidebar-nav" onClick = {closeSidebar}>
          <NavLink to = "/" className = "nav-link" end>
            <li className = "nav-item">
              <img src = "assets/img/icons/1.png" alt = "" />
              <span>لوحة البيانات</span>
            </li>
          </NavLink>
          {
            (modulePermission()?.includes('campaing'))
            ? <NavLink className = "nav-link" to = "/campaign" onClick = {closeSidebar}>
                <li className = "nav-item">
                  <img src = "assets/img/icons/2.png" alt = "" />
                  <span>إدارة الرسائل</span>
                </li>
              </NavLink>
            : null
          }
          {
            (modulePermission()?.includes('statistics'))
            ? <li className = "nav-item">
                <NavLink className = "nav-link" to = "/campaign-statistics">
                  <img src = "assets/img/icons/3.png" alt = "" />
                  <span>إحصائيات الرسائل</span>
                </NavLink>
              </li>
            : null
          }
          {
            (modulePermission()?.includes('template'))
            ? <li className = "nav-item">
                <NavLink className = "nav-link" to = "/templates" onClick = {closeSidebar}>
                  <img src = "assets/img/icons/4.png" alt = "" />
                  <span>إدارة النماذج</span>
                </NavLink>
              </li>
            : null
          }
          {
            (modulePermission()?.includes('faq')) 
            ? <li className = "nav-item">
                <NavLink className = "nav-link" to = "/faq" onClick = {closeSidebar}>
                  <img src = "assets/img/icons/6.png" alt = "" />
                  <span>التعليمات / الاسئلة المتكررة </span>
                </NavLink>
              </li>
            : null
          }
          {
            (modulePermission()?.includes('groups'))
            ? <li className = "nav-item">
                <NavLink className = "nav-link" to = "/contact" onClick = {closeSidebar}>
                  <img src = "assets/img/icons/7.png" alt = "" />
                  <span>إدارة المجموعات </span>
                </NavLink>
              </li>
            : null
          }
          {
            (modulePermission()?.includes('users'))
            ? <li className = "nav-item">
                <NavLink className = "nav-link" to = "/user" onClick = {closeSidebar}>
                  <img src = "assets/img/icons/5.png" alt = "" />
                  <span>إدارة الموظفين</span>
                </NavLink>
              </li>
            : null
          }
          {
            (modulePermission()?.includes('roles'))
            ? <li className = "nav-item collap">
                <a className = "nav-link collapsed"
                  data-bs-target = "#roles" 
                  data-bs-toggle = "collapse" 
                  href = "#">
                  <img src = "assets/img/icons/3.png" alt = "" />
                  <span>الصلاحيات</span>
                  <i className = "bi bi-chevron-down mr-auto"></i>
                </a>
                <ul id = "roles" 
                  className = { Active2 ==  true ? "nav-content collapse show" : "nav-content collapse"} 
                  data-bs-parent = "#sidebar-nav">
                  <li>
                    <Link to = {'/role'} onClick = {closeSidebar}>
                      <i className = "bi bi-circle-fill"></i>
                      <span className = {window.location.href == webUrl + 'role' ? 'active' : ""}>إنشاء صلاحية </span>
                    </Link>
                  </li>
                  <li>
                    <Link to = {'/manage-role'} onClick = {closeSidebar}>
                      <i className = "bi bi-circle-fill"></i>
                      <span className = {window.location.href == webUrl + 'manage-role' ? 'active' : ""}>إدارة الصلاحيات</span>
                    </Link>
                  </li>
                </ul>
              </li>
            : null
          }
          {
            (modulePermission()?.includes('rank')) || (modulePermission()?.includes('unit'))
              ? <li className = "nav-item collap" >
                  <a className = "nav-link collapsed"
                    data-bs-target = "#forms-nav" 
                    data-bs-toggle = "collapse" 
                    href = "#">
                    <img src = "assets/img/icons/3.png" alt = "" />
                    <span>عام</span>
                    <i className = "bi bi-chevron-down mr-auto"></i>
                  </a>
                  <ul id = "forms-nav" 
                    className = {Active == true ? "nav-content collapse show" : "nav-content collapse"} 
                    data-bs-parent = "#sidebar-nav">
                    {
                      (modulePermission()?.includes('rank'))
                      ? <li>
                          <Link to = {'/rank'} onClick = {closeSidebar}>
                            <i className = "bi bi-circle-fill"></i>
                            <span className = {window.location.href == webUrl + 'rank' ? 'active' : ""}>الرتبة</span>
                          </Link>
                        </li>
                      : null
                    }
                    {
                      (modulePermission()?.includes('unit'))
                      ? <li>
                          <Link to = {'/unit'} onClick = {closeSidebar}>
                            <i className = "bi bi-circle-fill"></i>
                            <span className = {window.location.href == webUrl + 'unit' ? 'active' : ""}>القسم</span>
                          </Link>
                        </li>
                      : null
                    }
                </ul>
              </li>
            : null
          }
        </ul>
      </aside>
    </React.Fragment>
  )
};

export default Sidebar;
