import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../componets/Header';
import Sidebar from '../componets/Sidebar';
import '../styles/Campaign.css';
import '../styles/common.css';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';

import { getCampaignDetails } from '../redux/actionCreator';
import { recieverList, campaignMessage, campaignName } from '../redux/TemplateSlice';
import moment from 'moment/moment';

const Reports = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [groupPage, setGroupPage] = useState(0);
    const [userGroupContactDetail, setUserGroupContactDetail] = useState([])
    const [groupCount, setGroupCount] = useState(null)
    const [deliveryStatus, setDeliveryStatus] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [filteredData, setFilteredData] = useState([])

    const receiverFullList = useSelector(recieverList)
    const campaingname = useSelector(campaignName)
    const campaignmessage = useSelector(campaignMessage)

    useEffect(() => {
        document.title = 'TSD - إضافة جهة اتصال';
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(getCampaignDetails(id));
        }
    }, [id])


    const filterData = (deliveryStatus, searchValue) => {
        let data = receiverFullList?.filter(obj => Object.values(obj).some(val => val.includes(deliveryStatus)) && Object.values(obj).some(val => val.includes(searchValue)));
        //console.log(res,"res")
        setFilteredData(data)
        //groupDataHandler(groupPage);
    }
    //console.log(deliveryStatus,"deliveryStatus")


    useEffect(() => {
        if (receiverFullList) {
            filterData(deliveryStatus, searchValue)
            groupDataHandler(groupPage);
            //setGroupCount(Math.ceil(receiverFullList?.length/10));
            let id = [];
        }
    }, [receiverFullList])

    useEffect(() => {
        if (filteredData) {
            //filterData(deliveryStatus,searchValue)
            groupDataHandler(groupPage);
            setGroupCount(Math.ceil(filteredData?.length / 10));
            let id = [];
        }
    }, [filteredData])

    const groupDataHandler = (pag) => {
        // setUserGroupContactDetail(receiverFullList.slice(pag*10,pag*10+10));
        setUserGroupContactDetail(filteredData?.slice(pag * 10, pag * 10 + 10))
    }

    const handleGroupPageChange = (e) => {
        setGroupPage(e.selected);
        groupDataHandler(Number(e.selected));
    }

    const onSearchHandler = () => {
        filterData(deliveryStatus, searchValue)
    }
    const onCancelHandler = () => {
        navigate("/campaign-statistics");
    };

    // const data = [
    //     {
    //         id : "fc1e03cf-db0f-4b41- b302-e6f2e7",
    //         source :"شرطة دبي",
    //         destination : "+9715512345678",
    //         message : "لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.",
    //         date : "2022-11- 11 06:35:16",
    //         color : "#EC9E28",
    //         buttontext : "يعالج"
    //     },
    //     {
    //         id : "fc1e03cf-db0f-4b41- b302-e6f2e7",
    //         source :"شرطة دبي",
    //         destination : "+9715512345678",
    //         message : "لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.",
    //         date : "2022-11- 11 06:35:16",
    //         color : "#F35252",
    //         buttontext : "باءت بالفشل"
    //     },
    //     {
    //         id : "fc1e03cf-db0f-4b41- b302-e6f2e7",
    //         source :"شرطة دبي",
    //         destination : "+9715512345678",
    //         message : "لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.",
    //         date : "2022-11- 11 06:35:16",
    //         color : "#31D68F",
    //         buttontext : "تم التوصيل"
    //     },
    //     {
    //         id : "fc1e03cf-db0f-4b41- b302-e6f2e7",
    //         source :"شرطة دبي",
    //         destination : "+9715512345678",
    //         message : "لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.",
    //         date : "2022-11- 11 06:35:16",
    //         color : "#31D68F",
    //         buttontext : "تم التوصيل"
    //     },
    //     {
    //         id : "fc1e03cf-db0f-4b41- b302-e6f2e7",
    //         source :"شرطة دبي",
    //         destination : "+9715512345678",
    //         message : "لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.",
    //         date : "2022-11- 11 06:35:16",
    //         color : "#31D68F",
    //         buttontext : "تم التوصيل"
    //     } 
    // ]

    return (
        <>
            <Header />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1> التقارير</h1>
                </div>
                <section className="section">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form__wizard">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between cardMtop align-items-center">
                                            <h5 className="m-0 fw-bold"></h5>
                                            <button
                                                className="btn btn-secondary m-0 mr-3"
                                                onClick={() => onCancelHandler()}
                                            >
                                                Back{" "}
                                            </button>
                                        </div>
                                        <div className='d-flex'>

                                            <div className='col-lg-1'>
                                                <span className='selectMenuText'>الحالة</span>
                                            </div>
                                            {/* <div className = 'col-lg-4'>
                                                <div className = "form-group">
                                                    <div className = "custom-select selectCustomStyle">
                                                        <select className = "form-control form-select" aria-label="الكل"
                                                        value={deliveryStatus}
                                                        onChange = {(e) => setDeliveryStatus(e.target.value)}
                                                        >
                                                           
                                                            <option>Select Status</option>
                                                            <option value="Delivered">Delivered</option>
                                                            <option value="Failed">Failed</option>
                                                            <option value="Undeliverable">Undeliverable</option>
                                                            <option value="Expired">Expired</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <div className = "col-lg-6 mr15">
                                                <div className = "form-group">
                                                    <div className = "text-serach searchStyleDiv">
                                                        <div className = "d-flex align-items-center">
                                                            <input type = "text" className = "form-control" placeholder = '  بحث'
                                                            value={searchValue}
                                                            onChange = {e => setSearchValue(e.target.value)} 
                                                            
                                                            />
                                                            
                                                        <button className = "btn btn-primary" type = "button" onClick = {() => onSearchHandler()}>بحث</button>
                                                    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                        <hr />
                                        <table className="table">
                                            <thead>
                                                <tr className='tableHeaderColor'>
                                                    <th scope="col"> #</th>
                                                    <th scope="col" className='whiteSpaceNowrap'>المستلم</th>
                                                    <th scope="col" className='whiteSpaceNowrap'>عنوان الرسالة</th>
                                                    <th scope="col" className='whiteSpaceNowrap'>محتوى الرسالة </th>
                                                    <th scope="col" className='whiteSpaceNowrap'>تاريخ الإرسال </th>
                                                    <th scope="col" className='whiteSpaceNowrap'>الحالة</th>
                                                </tr>
                                            </thead>
                                            <tbody className='tableBody'>
                                                {
                                                    userGroupContactDetail?.map((ele, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td className=''><span className=''>{i + 1}</span></td>
                                                                <td className=''><span className=''>{ele.recipient}</span></td>
                                                                <td className=''><span className=''>{campaingname}</span></td>
                                                                <td className=''><span className=''>{campaignmessage}</span></td>
                                                                <td className=''><span className=''>{ele.delivery_time ? moment(parseInt(ele.delivery_time)).format("DD-MM-YYYY h:mm:ss") : ''}</span></td>


                                                                <td className=''>
                                                                    <div className="processButton whiteSpaceNowrap textAlignCenter"
                                                                        style={{
                                                                            backgroundColor: `${(ele.delivery_status_code == '2')
                                                                                    ? '#31D68F'
                                                                                    : (ele.delivery_status_code == 3 || ele.delivery_status_code == 5 || ele.delivery_status_code == 8 || ele.delivery_status_code == 11)
                                                                                        ? '#f35252'
                                                                                        : '#ec9e28'
                                                                                } `
                                                                        }}>
                                                                        {ele.delivery_status?.toUpperCase()}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='paginationStyle'>
                                        {
                                            groupCount > 1
                                                ? <ReactPaginate
                                                    previousLabel={<>❮</>}
                                                    nextLabel={<>❯</>}
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    // breakLabel = "..."
                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    pageCount={groupCount}
                                                    initialPage={groupPage}
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={(e) => handleGroupPageChange(e)}
                                                    containerClassName="pagination"
                                                    activeClassName="pageActive"
                                                />
                                                : null
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            <Sidebar />
        </>
    )
}

export default Reports;