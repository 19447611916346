import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Table  from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Header from '../componets/Header';
import Sidebar from '../componets/Sidebar';
import { baseUrl } from '../Constants';
import { deleteSubunitData, 
    getSubunitData, 
    getUnitData, 
    insertSubUnit, 
    updateSubUnit,
    deleteWorkShiftData,
    updateWorkShift, 
    insertWorkShift, 
    getWorkShiftData
} from '../redux/actionCreator';
import { ListUnits, subunitlists, workshift_details } from '../redux/TemplateSlice';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axiosInstance from "../utils/axiosInterceptor";
import axiosFormInstance from '../utils/axiosFormInterceptor';
import ReactPaginate from 'react-paginate';

function Unit() {
    
    const dispatch = useDispatch();

    let formData = new FormData();
    const fullUnitList= useSelector(ListUnits);
    const fullSubUnitList = useSelector(subunitlists);
    const fullworkShiftList = useSelector(workshift_details);

    const [department, setDepartment] = useState('');
    const [subunit, setSubunit] = useState('');
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [showEditSubunit, setShowEditSubunit] = useState(false);
    const [detail, setDetail] = useState({});
    const [show, setShow] = useState(false);
    const [editSubunit, setEditSubunit] = useState("");
    const [workshift, setWorkshift] = useState('');
    const [showEditWorkShift, setShowEditWorkShift] = useState(false);
    const [editWorkShift, setEditWorkShift] = useState("");
    const [deleteId, setDeleteID] = useState('');
    const [subunitDID, setSubunitDID] = useState({});
    const [workshiftDID, setWorkshiftDID] = useState({});
    const [viewSubunit, setViewsubunit] = useState(false);
    const [viewWorkshift, setViewWorkshift] = useState(false);
    const [workSubunit, setWorkSubunit] = useState('');
    const [modalshow, setModalShow] = useState(false);
    const handleClose  = () => setModalShow(false);
    const handleShow  = () => setModalShow(true);
    const [modalsubunitshow, setSubunitShow] = useState(false);
    const handleCloseSub  = () => setSubunitShow(false);
    const handleShowSub  = () => setSubunitShow(true);
    const [modalWorkshiftshow, setSubunitWorkshift] = useState(false);
    const handleCloseWork  = () => setSubunitWorkshift(false);
    const handleShoorkshift  = () => setSubunitWorkshift(true);
    const [units, setUnits] = useState([]);
    const [unitPage, setUnitPage] = useState(0);
    const [unitPageCount,setUnitPageCount] = useState(null);
    const [subUnitList, setSubUnitList] = useState([]);
    const [subUnitPage, setSubUnitPage] = useState(0);
    const [subUnitPageCount,setSubUnitPageCount] = useState(null);
    const [workShiftList, setWorkShiftList] = useState([]);
    const [workshiftPage, setWorkshiftPage] = useState(0);
    const [workshiftPageCount,setWorkshiftPageCount] = useState(null);

    useEffect(() => {
        document.title = 'TSD - الاقسام';
    }, []);

    useEffect(() => {
        UnitLists();
    }, [])
    
    const UnitLists = () => {
        dispatch(getUnitData());
    }

    useEffect(() =>  {
        if(fullUnitList) {
            unitPageDataHandler(unitPage);
            setUnitPageCount(Math.ceil(fullUnitList?.length/10));
        }
    },[fullUnitList])

    const unitPageDataHandler  = (pag) => {
        if(fullUnitList.slice(pag*10,pag*10+10)?.length == 0 && pag != 0){
            setUnitPage(pag-1);
            setUnits(fullUnitList.slice((pag-1)*10,(pag-1)*10+10));
        }else{
            setUnits(fullUnitList.slice(pag*10,pag*10+10));
        }
    }

    useEffect(() =>  {
        if(fullSubUnitList) {
            subUnitPageDataHandler(subUnitPage);
            setSubUnitPageCount(Math.ceil(fullSubUnitList?.length/10));
        }
    },[fullSubUnitList])

    const subUnitPageDataHandler  = (pag) => {
        if(fullSubUnitList.slice(pag*10,pag*10+10)?.length == 0 && pag != 0){
            setSubUnitPage(pag-1);
            setSubUnitList(fullSubUnitList.slice((pag-1)*10,(pag-1)*10+10));
        }else{
            setSubUnitList(fullSubUnitList.slice(pag*10,pag*10+10));
        }
    }

    useEffect(() =>  {
        if(fullworkShiftList) {
            workshiftPageDataHandler(workshiftPage);
            setWorkshiftPageCount(Math.ceil(fullworkShiftList?.length/10));
        }
    },[fullworkShiftList])

    const workshiftPageDataHandler  = (pag) => {
        if(fullworkShiftList.slice(pag*10,pag*10+10)?.length == 0 && pag != 0){
            setWorkshiftPage(pag-1);
            setWorkShiftList(fullworkShiftList.slice((pag-1)*10,(pag-1)*10+10));
        }else{
            setWorkShiftList(fullworkShiftList.slice(pag*10,pag*10+10));
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(department?.length == '') {
            toast.warning('الرجاء إدخال القسم', {
                position: "bottom-center",
                autoClose: 3000
            });
        }else {
            formData.append('unit_name', department);
            axiosFormInstance.post(baseUrl+"insertUnit",formData).then((res) => { 
            if(res.data.status) {
                UnitLists();
                toast.success('تمت الاضافة بنجاح', {
                    position: "bottom-center",
                    autoClose: 3000
                });
                setDepartment("");
              } else {
                toast.error(res.data.message, {
                  position: "bottom-center",
                  autoClose: 3000
                });
              }
          }).catch((err) => {
            console.log(err);
          })
        }  
    }

    const unitDelete  = () => {
        if(deleteId?.length == '') {
            toast.warning(' تحديد', {
                position: "bottom-center",
                autoClose: 3000
            });
        } else {
            const data = {
                del_id: deleteId
            };
            axiosInstance.post(baseUrl+'deleteUnit/',data).then((res) => {
                if(res.data.status) {
                    UnitLists();
                    setDeleteID('');
                    toast.success('تم الحذف بنجاح', {
                        position: "bottom-center",
                        autoClose: 3000
                    });
                    setModalShow(false);
                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        autoClose: 3000
                    });
                }
            }).catch((err) => {
                console.log(err);
            })
        }
      }

      const handleRemove = (id) => {
        setModalShow(true);
        setDeleteID(id);
        setSubunitDID("");
        setWorkshiftDID('');
        setViewsubunit(false);
        setViewWorkshift(false);
      }

      const unitEdit = (id) => {
        setShow(true);
        axiosInstance.get(baseUrl+"getUnitDetail/"+id).then((res) => {
            if(res.data.status) {
                setDetail(res.data.unit_details);
              } else {
                toast.error(res.data.message, {
                  position: "bottom-center",
                  autoClose: 3000
                });
              }
          }).catch((err) => {
            console.log(err);
          })
      }

      const handleUpdate = (id,e) => {
        e.preventDefault();
        if(detail.unit_name?.length == ''){
            toast.warning('الرجاء إدخال القسم', {
                position: "bottom-center",
                autoClose: 3000
            });
        } else{
            formData.append('id', id);
            let data= detail.unit_name;
            formData.append('unit_name', data);
            axiosFormInstance.post(baseUrl+'updateUnit/',formData).then((res) => {
                if(res.data.status) {
                    UnitLists();
                    setShow(false);
                    toast.success('تم التحديث بنجاح', {
                        position: "bottom-center",
                        autoClose: 3000
                    });
                } else {
                    toast.error(res.data.message, {
                      position: "bottom-center",
                      autoClose: 3000
                    });
                }
            }).catch((err) => {
                console.log(err);
            })
        } 
    }

    const onAddSubunitHandler  = (e) => {
        e.preventDefault()
        if(subunit?.length == '' || selectedUnit?._id?.length == '') {
            toast.warning('الرجاء إدخال البيانات', {
                position: "bottom-center",
                autoClose: 3000
            });
        } else {
            formData.append('subunit_name', subunit);
            formData.append('unit', selectedUnit?._id);
            dispatch(insertSubUnit(formData, selectedUnit?._id, () => {
                setSubunit("");
            }))
        }  
    }

    const subunitDelete  = () => {
        if(subunitDID._id?.length == '') {
            toast.warning('الرجاء تحديد الشعبة ', {
                position: "bottom-center",
                autoClose: 3000
            });
        } else {
            dispatch(deleteSubunitData(subunitDID._id,selectedUnit?._id,() => {
                setModalShow(false)
                setSubunitShow(false)
            }));
        }
    }

    const handleSubunitDelete = (ele) => {
        setSubunitShow(true);
        setSubunitDID(ele);
        setViewWorkshift(false);
    }

    const subunitEdit  = (ele) => {
        setEditSubunit(ele);
        setShowEditSubunit(true);
        setSubunit(ele.subunit_name);
        setSelectedUnit(ele.unit[0]);
    }

    const onUpdateSubunitHandler  = (e) => {
        e.preventDefault()
        if(subunit?.length == '' || selectedUnit._id?.length == '') {
            toast.warning('الرجاء إدخال البيانات', {
                position: "bottom-center",
                autoClose: 3000
            });
        } else {
            formData.append('subunit_name', subunit);
            formData.append('unit', selectedUnit._id);
            formData.append('id', editSubunit._id);
            dispatch(updateSubUnit(formData, editSubunit.unit[0]._id, () => {
                setSubunit("");
                setEditSubunit(null);
                setShowEditSubunit(false);
            }))
        }  
    }

    const ClickviewSubunit  = (ele) => {
        setViewsubunit(true);
        setViewWorkshift(false);
        setSelectedUnit(ele);
        dispatch(getSubunitData(ele._id));
        setSubunit("")
        setShowEditSubunit(false)
        setTimeout(() => {
            const divElement = document.getElementById("subunitForm");
            divElement.scrollIntoView({ behavior: 'smooth'});
        },1000)
    }

    const ClickWorkshift = (ele) => {
        setViewWorkshift(true);
        setSelectedUnit(ele?.unit[0]);
        setWorkSubunit(ele);
        dispatch(getWorkShiftData(ele._id));
        setWorkshift("")
        setShowEditWorkShift(false)
        setTimeout(() => {
            const divElement = document.getElementById("workshiftForm");
            divElement.scrollIntoView({ behavior: 'smooth'});
        },1000)
    }


    const onAddWorkshiftHandler  = (e) => {
        e.preventDefault();
        if(workshift?.length == '' || workSubunit?._id?.length == '') {
            toast.warning('الرجاء إضافة البيانات', {
                position: "bottom-center",
                autoClose: 3000
            });
        }else {
            formData.append('workshift_name', workshift);
            formData.append('subunit', workSubunit?._id);
            dispatch(insertWorkShift(formData, workSubunit?._id, () => {
                setWorkshift("");
                setShowEditSubunit(false);
            }))
        }
    }

    const workshiftDlt = (ele) => {
        setWorkshiftDID(ele);
        setSubunitDID('');
        setSubunitWorkshift(true);
    }

    const workShiftDelete  = () => {
        if(workshiftDID?.length == '') {
            toast.warning('يرجى تحديد الفئة', {
                position: "bottom-center",
                autoClose: 3000
            });
        } else {
            dispatch(deleteWorkShiftData(workshiftDID._id,workshiftDID.subunit[0]._id,(callback) => {
                setModalShow(false);
                setWorkshiftDID({});
                setSubunitWorkshift(false);
            }))
        }
    }

    const workShiftEdit  = (ele) => {
        setEditWorkShift(ele);
        setShowEditWorkShift(true);
        setWorkshift(ele.workshift_name);
        setSelectedUnit(ele.unit[0]._id);
    }

    const onUpdateWorkShiftHandler  = (e) => {
        e.preventDefault();
        if(workshift?.length == '' || workSubunit?.length == '') {
            toast.warning('الرجاء إضافة البيانات', {
                position: "bottom-center",
                autoClose: 3000
            });
        }else {
            formData.append('workshift_name', workshift);
            formData.append('subunit', workSubunit._id);
            formData.append('id', editWorkShift._id);
            dispatch(updateWorkShift(formData, editWorkShift.subunit[0]._id, () => {
                setShowEditWorkShift(false);
                setWorkshift("");
            }))
        }
    }

    const handleUnitPageChange  = (e) => {
        setUnitPage(e.selected);
        unitPageDataHandler(Number(e.selected));
    }

    const handleSubUnitPageChange  = (e) => {
        setSubUnitPage(e.selected);
        subUnitPageDataHandler(Number(e.selected));
    }

    const handleWorkshiftPageChange  = (e) => {
        setWorkshiftPage(e.selected);
        workshiftPageDataHandler(Number(e.selected));
    }

    return (
        <>
            <Modal show = {modalshow} centered onHide = {handleClose} className = 'removeModfal'>
                <Modal.Body>هل تريد أن تحذف؟</Modal.Body>
                <Modal.Footer>
                    <Button className = 'btn btn-modalClose' onClick = {handleClose}>
                        اغلاق
                    </Button>
                    <Button className = 'btn btn-modalSubmit' onClick = {unitDelete}>
                        حذف
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show = {modalsubunitshow} centered onHide = {handleCloseSub} className = 'removeModfal'>
                <Modal.Body>هل تريد أن تحذف؟</Modal.Body>
                <Modal.Footer>
                <Button className = 'btn btn-modalClose' onClick = {handleCloseSub}>
                    اغلاق
                </Button>
                <Button className = 'btn btn-modalSubmit' onClick = {subunitDelete}>
                    حذف
                </Button>
                </Modal.Footer>
            </Modal>
            <Modal show = {modalWorkshiftshow} centered onHide = {handleCloseWork} className = 'removeModfal'>
                <Modal.Body>هل تريد أن تحذف؟</Modal.Body>
                <Modal.Footer>
                <Button className = 'btn btn-modalClose' onClick = {handleCloseWork}>
                    اغلاق
                </Button>
                <Button className = 'btn btn-modalSubmit' onClick = {workShiftDelete}>
                    حذف
                </Button>
                </Modal.Footer>
            </Modal>
            <Header/>
            <main id = "main" className = "main">
                <div className = "pagetitle">
                    <h1>القسم</h1>
                </div>
                <section className = "section template__section">
                    <div className = "row">
                        <div className = "col-lg-12">
                            <div className = "form__wizard">
                                <div className = "card">
                                    <div className = "card-body">
                                    <div className = "default__heading mb-5"><h1>القسم</h1></div>
                                        <div className = "row">
                                            <div className = "col-md-6">
                                                {
                                                    !show 
                                                    && <Form onSubmit = {handleSubmit}>
                                                            <Form.Group className = "mb-3" controlId = "">
                                                                <Form.Label> القسم</Form.Label>
                                                                <Form.Control type = "text" 
                                                                    value = {department} 
                                                                    onChange = {e=>setDepartment(e.target.value)}/>
                                                            </Form.Group>
                                                            <button className = "btn btn-primary" type = "submit">إضافة</button>
                                                        </Form> 
                                                }
                                                {
                                                    show 
                                                    && <Form>
                                                            <Form.Group className = "mb-3" controlId = "">
                                                                <Form.Label>القسم</Form.Label>
                                                                <Form.Control type = "text" 
                                                                    value = {detail?.unit_name} 
                                                                    onChange = {e=>setDetail({...detail,unit_name:e.target.value})}/>
                                                            </Form.Group>
                                                            <button className = "btn btn-primary" type = "submit" onClick = {(e) => handleUpdate(detail._id,e)}>تحديث</button>
                                                        </Form> 
                                                }
                                            </div>
                                            <div className = "col-md-6">
                                                <Table striped bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th className = 'w-100'>القسم </th>
                                                            <th style={{minWidth:"150px"}}>اجراء</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            units?.map((ele,i) => {
                                                                return(
                                                                    <tr key={i}>
                                                                        <td className = 'w-100'>{ele.unit_name}</td>
                                                                        <td style={{minWidth:"150px"}}> 
                                                                            <button className = "btn btn-sm" onClick = {() => unitEdit(ele._id)}><img src="assets/img/icons/table-edit.png" alt="" /></button>
                                                                            <button className = "btn btn-sm" onClick = {() => handleRemove(ele._id)}><img src="assets/img/icons/table-delete.png" alt="" /></button>
                                                                            <button className = "btn btn-sm" onClick = {() => ClickviewSubunit(ele)}><img src="assets/img/icons/table-view.svg" alt="" /></button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                                <div className = 'paginationStyle'>
                                                    {
                                                        unitPageCount > 1
                                                        ? <ReactPaginate
                                                            previousLabel={<>❮</>}
                                                            nextLabel={<>❯</>}
                                                            pageClassName="page-item"
                                                            pageLinkClassName="page-link"
                                                            previousClassName="page-item"
                                                            previousLinkClassName="page-link"
                                                            nextClassName="page-item"
                                                            nextLinkClassName="page-link"
                                                            // breakLabel="..."
                                                            breakClassName="page-item"
                                                            breakLinkClassName="page-link"
                                                            pageCount={unitPageCount}
                                                            initialPage = {unitPage}
                                                            marginPagesDisplayed={1}
                                                            pageRangeDisplayed={5}
                                                            onPageChange={(e) => handleUnitPageChange(e)}
                                                            containerClassName="pagination"
                                                            activeClassName="pageActive"
                                                            forcePage={unitPage}
                                                            />
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    viewSubunit 
                                    && <div className = "card">
                                            <div className = "card-body">
                                                <div className = "default__heading mb-5"><h1>الشعبة </h1></div>
                                                <div className = "row">
                                                    <div className = "col-md-6">
                                                        <Form.Group className = "mb-3" controlId = "">
                                                            <Form.Label>القسم</Form.Label>
                                                            <div className = "form-group"  id = "subunitForm">
                                                                <div className = "custom-select without-label">
                                                                <Form.Control type = "text" value = {selectedUnit?.unit_name} disabled/>
                                                                </div>
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group className = "mb-3" controlId = "">
                                                            <Form.Label>الشعبة </Form.Label>
                                                            <Form.Control type = "text"
                                                                value = {viewWorkshift ? subunit?.subunit_name : subunit}
                                                                onChange = {e =>setSubunit(e.target.value)}/>
                                                        </Form.Group>
                                                        <button className = "btn btn-primary" type = "submit"
                                                            onClick = {(e) =>  showEditSubunit ? onUpdateSubunitHandler(e) : onAddSubunitHandler(e) }>
                                                            {showEditSubunit ? "تحديث" : "إضافة"}
                                                        </button>
                                                    </div>
                                                    <div className = "col-md-6">
                                                        {
                                                            subUnitList?.length
                                                            ? 
                                                                <Table striped borderless hover>
                                                                    <thead>
                                                                        <tr>
                                                                            <th className = 'w-100'>الشعبة  </th>
                                                                            <th style={{minWidth:"150px"}}>اجراء</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            subUnitList?.map((ele,i) => {
                                                                                return(
                                                                                    <tr key={i}>
                                                                                        <td className = 'w-100'>{ele.subunit_name}</td>
                                                                                        <td style={{minWidth:"150px"}}> 
                                                                                            <button className = "btn btn-sm" onClick = {() => subunitEdit(ele)}><img src="assets/img/icons/table-edit.png" alt="" /></button>
                                                                                            <button className = "btn btn-sm" onClick = {() => handleSubunitDelete(ele)}><img src="assets/img/icons/table-delete.png" alt="" /></button>
                                                                                            <button className = "btn btn-sm" onClick = {() => ClickWorkshift(ele)}><img src="assets/img/icons/table-view.svg" alt="" /></button>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            : null
                                                        }
                                                        <div className = 'paginationStyle'>
                                                            {
                                                                subUnitPageCount > 1
                                                                ? <ReactPaginate
                                                                    previousLabel={<>❮</>}
                                                                    nextLabel={<>❯</>}
                                                                    pageClassName="page-item"
                                                                    pageLinkClassName="page-link"
                                                                    previousClassName="page-item"
                                                                    previousLinkClassName="page-link"
                                                                    nextClassName="page-item"
                                                                    nextLinkClassName="page-link"
                                                                    // breakLabel="..."
                                                                    breakClassName="page-item"
                                                                    breakLinkClassName="page-link"
                                                                    pageCount={subUnitPageCount}
                                                                    initialPage = {subUnitPage}
                                                                    marginPagesDisplayed={1}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={(e) => handleSubUnitPageChange(e)}
                                                                    containerClassName="pagination"
                                                                    activeClassName="pageActive"
                                                                    forcePage={subUnitPage}
                                                                    />
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        viewWorkshift 
                                        && 
                                            <div className = "card">
                                                <div className = "card-body">
                                                    <div className = "row">
                                                        <div className = "col-md-12">
                                                            <div className = "default__heading mb-5"><h1>الفئة</h1></div>
                                                            <div className = 'row'>
                                                                <div className = "col-md-4">
                                                                    <Form.Group className = "mb-3" controlId = "">
                                                                        <Form.Label>القسم</Form.Label>
                                                                        <div className = "form-group" id = "workshiftForm">
                                                                            <div className = "custom-select without-label">
                                                                            <Form.Control type = "text" value = {selectedUnit?.unit_name} disabled/>
                                                                            </div>
                                                                        </div>
                                                                    </Form.Group>
                                                                </div>
                                                                <div className = "col-md-4">
                                                                    <Form.Group className = "mb-3" controlId = "">
                                                                        <Form.Label>الشعبة </Form.Label>
                                                                        <div className = "form-group">
                                                                            <div className = "custom-select without-label">
                                                                            <Form.Control type = "text" value = {workSubunit?.subunit_name} disabled/>
                                                                            </div>
                                                                        </div>
                                                                    </Form.Group>
                                                                </div>
                                                                <div className = "col-md-4">
                                                                    <Form.Group className = "mb-3" controlId = "">
                                                                        <Form.Label>الفئة</Form.Label>
                                                                        <Form.Control type = "text"
                                                                        value = {workshift}
                                                                        onChange = {e =>setWorkshift(e.target.value)}/>
                                                                    </Form.Group>
                                                                </div>
                                                                <div className = "col-md-3">
                                                                    <button className = "btn btn-primary"
                                                                        type = "submit"
                                                                        onClick = {(e) =>  !showEditWorkShift ? onAddWorkshiftHandler(e) : onUpdateWorkShiftHandler(e)}>
                                                                        {!showEditWorkShift ? "إضافة" : "تحديث"} 
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {
                                                                workShiftList?.length
                                                                ? 
                                                                    <Table striped borderless hover className = 'mt-4'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{minWidth:"150px"}}>القسم </th>
                                                                                <th style={{minWidth:"150px"}}>الشعبة  </th>
                                                                                <th style={{minWidth:"150px"}}>الفئة </th>
                                                                                <th style={{minWidth:"150px"}}>اجراء</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                workShiftList?.map((ele,i) => {
                                                                                    return(
                                                                                        <tr key={i}>
                                                                                            <td  style={{minWidth:"150px"}}>{ele.unit[0]?.unit_name}</td>
                                                                                            <td  style={{minWidth:"150px"}}>{ele.subunit[0]?.subunit_name}</td>
                                                                                            <td  style={{minWidth:"150px"}}>{ele.workshift_name}</td>
                                                                                            <td style={{minWidth:"150px"}}> 
                                                                                                <button className = "btn btn-sm" onClick = {() => workShiftEdit(ele)}><img src="assets/img/icons/table-edit.png" alt="" /></button>
                                                                                                <button className = "btn btn-sm" onClick = {() => workshiftDlt(ele)}><img src="assets/img/icons/table-delete.png" alt="" /></button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </Table>
                                                                : null
                                                            }
                                                            <div className = 'paginationStyle'>
                                                                {
                                                                    workshiftPageCount > 1
                                                                    ? <ReactPaginate
                                                                        previousLabel={<>❮</>}
                                                                        nextLabel={<>❯</>}
                                                                        pageClassName="page-item"
                                                                        pageLinkClassName="page-link"
                                                                        previousClassName="page-item"
                                                                        previousLinkClassName="page-link"
                                                                        nextClassName="page-item"
                                                                        nextLinkClassName="page-link"
                                                                        // breakLabel="..."
                                                                        breakClassName="page-item"
                                                                        breakLinkClassName="page-link"
                                                                        pageCount={workshiftPageCount}
                                                                        initialPage = {workshiftPage}
                                                                        marginPagesDisplayed={1}
                                                                        pageRangeDisplayed={5}
                                                                        onPageChange={(e) => handleWorkshiftPageChange(e)}
                                                                        containerClassName="pagination"
                                                                        activeClassName="pageActive"
                                                                        forcePage={workshiftPage}
                                                                        />
                                                                    : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Sidebar/>
        </>
    )
}

export default Unit