import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import Header from "../componets/Header";
import Sidebar from "../componets/Sidebar";
import Table from "react-bootstrap/Table";
import {
  ListUnits,
  modules,
  multiSubunitLists,
  multiWorkshiftLists,
  RankLists,
  updateRedux,
  users,
  user_details,
} from "../redux/TemplateSlice";
import {
  getUnitData,
  getRankData,
  getModules,
  searchUser,
  insertuserRoles,
  getMultiSubunitData,
  getMultiWorkshiftData,
} from "../redux/actionCreator";
import Select from "react-select";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

const Role = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const units = useSelector(ListUnits);
  const subUnitList = useSelector(multiSubunitLists);
  const workShiftList = useSelector(multiWorkshiftLists);
  const userList = useSelector(user_details);
  const rankList = useSelector(RankLists);
  const moduleList = useSelector(modules);
  const fullUserList = useSelector(users);

  const [subunit, setSubunit] = useState([]);
  const [unit, setUnit] = useState([]);
  const [workshift, setWorkShift] = useState([]);
  const [rank, setRank] = useState([]);
  const [module, setModule] = useState([]);
  const [access, setAccess] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [userId, setUserId] = useState([]);

  const [nodata, setNodata] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(0);
  const [user, setUser] = useState([]);
  const [pageCount, setPageCount] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [signature, setSignature] = useState("");

  useEffect(() => {
    document.title = "TSD - إنشاء صلاحية ";
  }, []);

  useEffect(() => {
    dispatch(getUnitData());
    dispatch(getRankData());
    dispatch(getModules());
  }, []);

  useEffect(() => {
    if (fullUserList) {
      pageDataHandler(page);
      setPageCount(Math.ceil(fullUserList?.length / 10));
    }
  }, [fullUserList]);

  const pageDataHandler = (pag) => {
    setUser(fullUserList.slice(pag * 10, pag * 10 + 10));
  };

  const onUnitSelecthandler = (value) => {
    setUnit(value);
    let formData = new FormData();
    value?.forEach((item) => formData.append("unit[]", item._id));
    dispatch(
      getMultiSubunitData(formData, (data) => {
        let temp;
        temp = data?.filter((el) => {
          return subunit?.find((element) => {
            return element._id == el._id;
          });
        });
        setSubunit(temp);
      })
    );
  };

  const onSubUnitSelecthandler = (value) => {
    setSubunit(value);
    let formData = new FormData();
    value?.forEach((item) => formData.append("subunit[]", item._id));
    dispatch(
      getMultiWorkshiftData(formData, (data) => {
        let temp;
        temp = data?.filter((el) => {
          return workshift?.find((element) => {
            return element._id == el._id;
          });
        });
        setWorkShift(temp);
      })
    );
  };

  const onSearchHandler = (pag) => {
    setNodata(true);
    if (
      keyword?.length == 0 &&
      unit?.length == 0 &&
      subunit?.length == 0 &&
      workshift?.length == 0 &&
      rank?.length == 0
    ) {
      toast.warning("الرجاء إضافة بيانات البحث", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      let formData = new FormData();
      formData.append("keyword", keyword);
      unit?.forEach((item) => formData.append("unit[]", item._id));
      subunit?.forEach((item) => formData.append("subunit[]", item._id));
      workshift?.forEach((item) => formData.append("workshift[]", item._id));
      rank?.forEach((item) => formData.append("rank[]", item._id));
      dispatch(
        searchUser(formData, () => {
          setNodata(false);
        })
      );
    }
  };

  const selectAllUserHandler = (e) => {
    if (e.target.checked) {
      let id = [];
      fullUserList?.forEach((ele) => {
        id.push(ele._id);
      });
      setUserId([...id]);
    } else {
      setUserId([]);
    }
  };

  const onCreateRoleshandler = (e) => {
    e.preventDefault();
    if (module?.length == 0) {
      toast.warning("الرجاء تحديد الدور", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else if (userId?.length == 0) {
      toast.warning("الرجاء تحديد المستخدم", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else if (access?.length == 0) {
      toast.warning("الرجاء إختيار تحديد الدخول", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      let formData = new FormData();
      module.forEach((item) => formData.append("modules[]", item));
      userId.forEach((item) => formData.append("users[]", item));
      let type;
      if (access?.length == 2) {
        type = 3;
      } else if (access?.includes("ad")) {
        type = 1;
      } else if (access?.includes("manual")) {
        type = 2;
      } else {
        type = 0;
      }
      formData.append("type", type);
      formData.append("signature", signature);
      dispatch(
        insertuserRoles(formData, () => {
          toast.success("تمت إضافة دور المستخدم بنجاح", {
            position: "bottom-center",
            autoClose: 3000,
          });
          setSubunit([]);
          setUnit([]);
          setWorkShift([]);
          setRank([]);
          setModule([]);
          setAccess([]);
          setKeyword([]);
          setUserId([]);
          setPageCount(null);
          setUser([]);
          setPage(0);
          dispatch(updateRedux({ key: "users", value: [] }));
          setNodata(false);
          setSignature("");
        })
      );
    }
  };

  const onCancelHandler = (e) => {
    e.preventDefault();
    setSubunit([]);
    setUnit([]);
    setWorkShift([]);
    setRank([]);
    setModule([]);
    setAccess([]);
    setKeyword([]);
    setUserId([]);
    navigate("/manage-role");
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    pageDataHandler(Number(e.selected));
  };

  const onAddHandler = (value) => {
    setUserId([...userId, value._id]);
  };

  const onRemoveHandler = (value) => {
    setUserId(userId.filter((item) => item != value._id));
  };

  const onAddAllHandler = (e) => {
    let id = [];
    fullUserList?.forEach((ele) => {
      id.push(ele._id);
    });
    setUserId([...id]);
  };

  const onRemoveAllHandler = (e) => {
    setUserId([]);
  };

  return (
    <>
      <Header />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>إنشاء صلاحية </h1>
        </div>
        <section className="section template__section">
          <div className="row">
            <div className="col-lg-12">
              <div className="form__wizard">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between cardMtop align-items-center">
                      <h5 className="m-0 form__title">انشاء </h5>
                    </div>
                    <hr />
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            controlId="formSelectMultiple"
                            className="mb-3"
                          >
                            <Form.Label>الرتبة</Form.Label>
                            <Select
                              isMulti
                              name="rank"
                              placeholder="إختيار"
                              options={rankList}
                              hideSelectedOptions={false}
                              value={rank}
                              getOptionLabel={(option) => option.rank}
                              getOptionValue={(option) => option._id}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => setRank(e)}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlId="formSelectMultiple"
                            className="mb-3"
                          >
                            <Form.Label>القسم </Form.Label>
                            <Select
                              isMulti
                              name="unit"
                              placeholder="إختيار"
                              options={units}
                              hideSelectedOptions={false}
                              value={unit}
                              getOptionLabel={(option) => option.unit_name}
                              getOptionValue={(option) => option._id}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => onUnitSelecthandler(e)}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlId="formSelectMultiple"
                            className="mb-3"
                          >
                            <Form.Label>الشعبة </Form.Label>
                            <Select
                              isMulti
                              name="subunit"
                              placeholder="إختيار"
                              options={subUnitList}
                              hideSelectedOptions={false}
                              isDisabled={unit?.length ? false : true}
                              value={subunit}
                              getOptionLabel={(option) => option.subunit_name}
                              getOptionValue={(option) => option._id}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => onSubUnitSelecthandler(e)}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlId="formSelectMultiple"
                            className="mb-3"
                          >
                            <Form.Label>الفئة </Form.Label>
                            <Select
                              isMulti
                              name="workshift"
                              placeholder="إختيار"
                              options={workShiftList}
                              hideSelectedOptions={false}
                              value={workshift}
                              isDisabled={subunit?.length ? false : true}
                              getOptionLabel={(option) => option.workshift_name}
                              getOptionValue={(option) => option._id}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => setWorkShift(e)}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <Form.Label>بحث</Form.Label>
                            <div className="keyword__search">
                              <div className="form-group w-100">
                                <input
                                  type="text"
                                  className="form-control mb-0 border-left-0"
                                  placeholder="الكلمة المعرفة "
                                  onChange={(e) => setKeyword(e.target.value)}
                                />
                              </div>
                              <button type="submit" className="btn btn-search">
                                <img
                                  src="assets/img/icons/search-form.svg"
                                  alt=""
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                          <button
                            className="btn btn-primary mt-4"
                            onClick={(e) => onSearchHandler(page)}
                          >
                            البحث
                          </button>
                        </div>
                      </div>
                    </div>
                    {user?.length ? (
                      <div className="selected__users">
                        <Table striped bordered hover className="mt-5">
                          <thead>
                            <tr>
                              <th style={{ display: "flex" }}>
                                <Form.Check
                                  type="checkbox"
                                  id={`valueOne`}
                                  style={{ marginLeft: "5px" }}
                                  checked={
                                    fullUserList?.length == userId?.length
                                  }
                                  onClick={(e) => selectAllUserHandler(e)}
                                />
                                تحديد الكل
                              </th>
                              <th>الاسم</th>
                              <th>البريد الالكتروني</th>
                              <th>اسم المستخدم</th>
                              <th>الرقم الوظيفي</th>
                              <th>الوظيفة</th>
                              <th>
                                {fullUserList?.length == userId?.length ? (
                                  <button
                                    className="btn btn-sm btn-danger btn-table"
                                    onClick={() => onRemoveAllHandler()}
                                  >
                                    Remove All
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-sm btn-primary btn-table"
                                    onClick={() => onAddAllHandler()}
                                  >
                                    Select All
                                  </button>
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {user?.map((ele, i) => {
                              return (
                                <tr>
                                  <td>
                                    <Form.Check
                                      type="checkbox"
                                      id={`valueOne`}
                                      checked={
                                        userId.includes(ele._id) ? true : false
                                      }
                                      onClick={(e) =>
                                        e.target.checked
                                          ? setUserId([...userId, ele._id])
                                          : setUserId(
                                              userId.filter(
                                                (item) => item != ele._id
                                              )
                                            )
                                      }
                                    />
                                  </td>
                                  <td>{ele.name}</td>
                                  <td>{ele.email}</td>
                                  <td>{ele.username}</td>
                                  <td>{ele.employee_id}</td>
                                  <td>{ele.job_title}</td>
                                  <td>
                                    <button
                                      className={`btn btn-sm btn-table ${
                                        userId?.includes(ele._id)
                                          ? "btn-danger"
                                          : "btn-primary"
                                      }`}
                                      onClick={() =>
                                        userId?.includes(ele._id)
                                          ? onRemoveHandler(ele)
                                          : onAddHandler(ele)
                                      }
                                    >
                                      {userId?.includes(ele._id)
                                        ? "Remove"
                                        : "Select"}
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        <div className="paginationStyle">
                          {pageCount > 1 ? (
                            <ReactPaginate
                              previousLabel={<>❮</>}
                              nextLabel={<>❯</>}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              // breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              pageCount={pageCount}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={5}
                              initialPage={page}
                              onPageChange={(e) => handlePageChange(e)}
                              containerClassName="pagination"
                              activeClassName="pageActive"
                            />
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <>
                        {nodata ? (
                          <div className="noData">
                            <h3>اتوجد بيانات</h3>
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between cardMtop align-items-center">
                      <h6 className="m-0 form__title">حدد دور المستخدم</h6>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="module__list">
                        {moduleList?.map((ele, i) => {
                          return (
                            <div className="select__box">
                              <Form.Check
                                type="checkbox"
                                id={`${i}`}
                                checked={
                                  module.includes(ele.key) ? true : false
                                }
                                onClick={(e) =>
                                  e.target.checked
                                    ? setModule([...module, ele.key])
                                    : setModule(
                                        module.filter((item) => item != ele.key)
                                      )
                                }
                              />
                              <Form.Label>{ele.value}</Form.Label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between cardMtop align-items-center">
                      <h6 className="m-0 form__title">تحديد صلاحية الدخول</h6>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="module__list">
                        <div className="select__box">
                          <Form.Check
                            type="checkbox"
                            id={`DU1`}
                            checked={access.includes("manual") ? true : false}
                            onClick={(e) =>
                              e.target.checked
                                ? setAccess([...access, "manual"])
                                : setAccess(
                                    access.filter((item) => item != "manual")
                                  )
                            }
                          />
                          <Form.Label className="ml-2 mt-2">
                            تسجيل الدخول اليدوي
                          </Form.Label>
                        </div>
                        <div className="select__box">
                          <Form.Check
                            type="checkbox"
                            id={`DU2`}
                            checked={access.includes("ad") ? true : false}
                            onClick={(e) =>
                              e.target.checked
                                ? setAccess([...access, "ad"])
                                : setAccess(
                                    access.filter((item) => item != "ad")
                                  )
                            }
                          />
                          <Form.Label className="ml-2 mt-2">
                            احقية TSD{" "}
                          </Form.Label>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <textarea
                          rows={3}
                          className="form-control"
                          value={signature}
                          onChange={(e) => setSignature(e.target.value)}
                        ></textarea>
                        {/* <Form.Group className="mb-6" controlId="">
                                                    <Form.Label>Signature</Form.Label>
                                                    <Form.Control
                                                        type="textarea"
                                                        value={signature}
                                                        onChange={(e) => setSignature(e.target.value)}
                                                    />
                                                </Form.Group> */}
                      </div>
                      <div className="col-md-12 mt-3">
                        <button
                          className="btn btn-danger mt-2"
                          onClick={(e) => onCancelHandler(e)}
                        >
                          الغاء
                        </button>
                        <button
                          className="btn btn-primary mt-2"
                          onClick={(e) => onCreateRoleshandler(e)}
                        >
                          انشاء
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Sidebar />
    </>
  );
};

export default Role;
