import axios from 'axios'
import { toast } from 'react-toastify';
import { baseUrl } from '../Constants';
import { updateRedux } from './TemplateSlice'
import axiosInstance from "../utils/axiosInterceptor";
import axiosFormInstance from '../utils/axiosFormInterceptor';

export const getUnitData=()=>(dispatch)=>{
  axiosInstance.get(baseUrl+"getUnit").then((res)=>{
      if(res.data.status) {
        dispatch(updateRedux({key : "ListUnits", value : res.data.unit_details}))
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err)=>{
      console.log(err)
    })
}

export const getRankData=()=>(dispatch)=>{
  axiosInstance.get(baseUrl+"getRank").then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "RankLists", value : res.data.rank_details}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
    }).catch((err)=>{
      console.log(err)
    })
}

export const getDesignationData=()=>(dispatch)=>{
  axiosInstance.get(baseUrl+"getDesignation").then((res)=>{
      if(res.data.status) {
        dispatch(updateRedux({key : "DesignationLists", value : res.data.des_details}))
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err)=>{
      console.log(err)
    })
}


export const getStaffData=()=>(dispatch)=>{
    axiosInstance.get(baseUrl+"getStaff").then((res)=>{
      if(res.data.status) {
        dispatch(updateRedux({key : "StaffLists", value : res.data.staff_details}))
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err)=>{
      console.log(err)
    })
}

export const insertCampaignData = (data, callback) => (dispatch) => {
  axiosInstance.post(`${baseUrl}insertCampaign`,data).then(res => {
      if(res.data.status) {
        callback()
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err) => {
      console.log(err)
  })
}

export const getTemplateDetails = (page,search) => (dispatch) => {
  axiosInstance.get(baseUrl+`getTemplates/?search=${search}&page=${page}`).then((res)=>{
      if(res.data.status) {
        dispatch(updateRedux({key : "templatelists", value : res.data.template_details}))
        dispatch(updateRedux({key : "templateTotalCount", value : res.data.total_pages}))
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err)=>{
      console.log(err)
  })
}

export const insertSubUnit = (formData,unitId, callback) => (dispatch) => {
  axiosFormInstance.post(`${baseUrl}insertSubunit`,formData).then(res => {
    if(res.data.status) {
      toast.success('subunit Added Sucessfully', {
        position: "bottom-center",
        autoClose: 3000
    })
      dispatch(getSubunitData(unitId))
      callback()
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
}).catch((err) => {
    console.log(err)
  })
}

export const getSubunitData = (id) => (dispatch) => {
  axiosInstance.get(`${baseUrl}getSubunit/${id}`).then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "subunitlists", value : res.data.subunit_details}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const deleteSubunitData = (delId, unitId,callback) => (dispatch) => {
  const data = {
    del_id: delId
  }; 
  axiosInstance.post(baseUrl+'deletSubunit',data).then((res)=>{
    if(res.data.status) {
      dispatch(getSubunitData(unitId))
      toast.success('subunit deleted Sucessfully', {
        position: "bottom-center",
        autoClose: 3000
    })
    callback()
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const updateSubUnit = (formData,unitId, callback) => (dispatch) => {
  axiosFormInstance.post(`${baseUrl}updateSubunit`,formData).then(res => {
    if(res.data.status) {
      dispatch(getSubunitData(unitId))
      toast.success('subunit updated Sucessfully', {
        position: "bottom-center",
        autoClose: 3000
      })
      callback()
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
}).catch((err) => {
    console.log(err)
})
}

export const insertWorkShift = (formData,subUnitId, callback) => (dispatch) => {
  axiosFormInstance.post(`${baseUrl}insertWorkshift`,formData).then(res => {
    if(res.data.status) {
      dispatch(getWorkShiftData(subUnitId))
      toast.success('workshift Added Sucessfully', {
        position: "bottom-center",
        autoClose: 3000
      })
      callback()
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
}).catch((err) => {
    console.log(err)
})
}

export const getWorkShiftData = (id) => (dispatch) => {
  axiosInstance.get(`${baseUrl}getWorkshift/${id}`).then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "workshift_details", value : res.data.workshift_details}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const deleteWorkShiftData = (delId, id,callback) => (dispatch) => {
  const data = {
    del_id: delId
  }; 
  axiosInstance.post(baseUrl+'deletWorkshift',data).then((res)=>{
    if(res.data.status) {
      dispatch(getWorkShiftData(id))
      toast.success('workshift deleted Sucessfully', {
        position: "bottom-center",
        autoClose: 3000
      })
      callback()
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const updateWorkShift = (formData,id, callback) => (dispatch) => {
  axiosFormInstance.post(`${baseUrl}updateWorkshift`,formData).then(res => {
      if(res.data.status) {
        dispatch(getWorkShiftData(id))
        toast.success('workshift updated Sucessfully', {
          position: "bottom-center",
          autoClose: 3000
        })
        callback()
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err) => {
      console.log(err)
  })
}

export const getUser = (search,page) => (dispatch) => {
  axiosInstance.get(`${baseUrl}getUser/?search=${search}&page=${page}`).then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "userLoader", value : false}))
      dispatch(updateRedux({key : "user_details", value : res.data?.user_details}))
      dispatch(updateRedux({key : "totalPageCount", value : res.data?.total_pages}))
      dispatch(updateRedux({key : "currentPage", value : res.data?.current_page}))
      dispatch(updateRedux({key : "ad_count", value : res.data?.ad_count}))
      dispatch(updateRedux({key : "manual_count", value : res.data?.manual_count}))
      dispatch(updateRedux({key : "csv_count", value : res.data?.csv_count}))
    } else {
      dispatch(updateRedux({key : "userLoader", value : false}))
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}


export const insertUser = (data, callback) => (dispatch) => {
  axiosInstance.post(`${baseUrl}insertUser`,data).then(res => {
      if(res.data.status) {
        dispatch(getUser("",0))
        callback()
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err) => {
      callback(err.response.data.errors.details)
  })
}

export const deleteUser = (delId,searchKey,pageCount, callback) => (dispatch) => {
  const data = {
    del_id: delId
  }; 
  axiosInstance.post(baseUrl+'deleteUser',data).then((res)=>{
    if(res.data.status) {
      dispatch(getUser(searchKey,pageCount))
      callback()
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const updateUser = (data,searchKey,pageCount, callback) => (dispatch) => {
  axiosInstance.post(`${baseUrl}updateUser`,data).then(res => {
      if(res.data.status) {
        dispatch(getUser(searchKey,pageCount))
        callback()
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err) => {
      console.log(err)
  })
}

export const getModules = () => (dispatch) => {
  axiosInstance.get(`${baseUrl}getModules`).then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "modules", value : res.data.modules}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const getUserRoles = (page,search) => (dispatch) => {
  axiosInstance.get(`${baseUrl}getRoles/?search=${search}&page=${page}`).then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "userRoles", value : res.data.roles}))
      dispatch(updateRedux({key : "rolesTotalCount", value : res.data.total_pages}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const insertuserRoles = (formData,callback) => (dispatch) => {
  axiosFormInstance.post(`${baseUrl}insertRoles`,formData).then(res => {
      if(res.data.status) {
        dispatch(getUserRoles(0,""))
        dispatch(getModulePermission()) 
        callback()
      }else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err) => {
      console.log(err)
  })
}

export const deleteUserRoles = (delId,page,search, callback) => (dispatch) => {
  const data = {
    id : delId
  }; 
  axiosInstance.post(baseUrl+'deleteRoles',data).then((res)=>{
    if(res.data.status) {
      dispatch(getUserRoles(page,search))
      dispatch(getModulePermission())
      callback()
    }else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const updateUserRoles = (formData,callback) => (dispatch) => {
  axiosFormInstance.post(`${baseUrl}updateRoles`,formData).then(res => {
      if(res.data.status) {
        dispatch(getUserRoles(0,""))
        dispatch(getModulePermission())
        callback()
      }else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err) => {
      console.log(err)
  })
}

export const getUserRole = (search,page) => (dispatch) => {
  axiosInstance.get(`${baseUrl}getUser/?search=${search}&page=${page}`).then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "users", value : res.data.users}))
      dispatch(updateRedux({key : "userTotalCount", value : res.data.count}))
      dispatch(updateRedux({key : "userTotalPageCount", value : res.data.total_pages}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const searchUser = (formData,callback) => (dispatch) => {
  axiosFormInstance.post(`${baseUrl}searchUsers`,formData).then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "users", value : res.data.users}))
      if(res.data.users?.length == 0){
        callback(res.data.users)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const getMultiSubunitData = (formData,callback) => (dispatch) => {
  axiosFormInstance.post(`${baseUrl}getMultipleSubunit`,formData).then((res)=>{
      if(res.data.status) {
        dispatch(updateRedux({key : "multiSubunitLists", value : res.data.subunit_details}))
        if(callback) {
          callback(res.data.subunit_details)
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err)=>{
      console.log(err)
  })
}

export const getMultiWorkshiftData = (formData,callback) => (dispatch) => {
  axiosFormInstance.post(`${baseUrl}getMultipleWorkshift`,formData).then((res)=>{
      if(res.data.status) {
        dispatch(updateRedux({key : "multiWorkshiftLists", value : res.data.workshift_details}))
        if(callback) {
          callback(res.data.workshift_details)
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err)=>{
      console.log(err)
  })
}

export const loginUser = (data, callback) => (dispatch) => {
  axiosInstance.post(`${baseUrl}login`,data).then(res => {
      if(res.data.status) {
        localStorage.setItem('username', res.data.username);
        localStorage.setItem('token', res.data.accessToken);
        localStorage.setItem('refreshToken', res.data.refreshToken);
        if(res.data.user_role=='super_admin'){
          dispatch(updateRedux({key : "user_role", value : res.data.user_role}))
          localStorage.setItem('super_admin', res.data.user_role)
        }
        callback(res.data)
      }else{
          toast.error(res.data.message, {
            position: "bottom-center",
            autoClose: 3000
        })
      }
  }).catch((err) => {
      console.log(err)
  })
}

export const manualLogin = (data,callback) => (dispatch) => {
  axiosInstance.post(`${baseUrl}manualLogin`,data).then(res => {
    if(res.data.login_type==1) {
      callback(res.data)
    }else if(res.data.login_type==2){
      callback(res.data)
    }
    if(!res.data.status){
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
    }
  }).catch((err) => {
      console.log(err)
  })
}


export const createNewlogin = (data,callback) => (dispatch) => {
  axios.post(`${baseUrl}manualNewPasswordUpdation`,data).then(res => {
    if(res.data.status) {
      localStorage.setItem('username', res.data.username);
      localStorage.setItem('token', res.data.accessToken);
      localStorage.setItem('refreshToken', res.data.refreshToken);
      callback(res.data)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
      console.log(err)
  })
}

export const loginverification = (data,callback) =>  (dispatch) => {
  axios.post(`${baseUrl}manualPasswordVerification`,data).then(res => {
    if(res.data.status) {
      localStorage.setItem('username', res.data.username);
      localStorage.setItem('token',res.data.accessToken)
      localStorage.setItem('refreshToken',res.data.refreshToken )
      callback(res.data)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
    
  }).catch((err) => {
      console.log(err)
  })
}

export const searchContact = (formData) => (dispatch) => {
  axiosFormInstance.post(`${baseUrl}searchContacts`,formData).then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "userContactDetails", value : res.data.user_details}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const deleteContactBook = (delId,page,search, callback) => (dispatch) => {
  const data = {
    del_id : delId
  }; 
  axiosInstance.post(baseUrl+'deleteContacts',data).then((res)=>{
    if(res.data.status) {
      dispatch(getContactBookData(page,search))
      if(callback){
        callback()
      }
    }else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const insertContactBook = (data,callback) => (dispatch) => {
  axiosInstance.post(`${baseUrl}insertContacts`,data).then(res => {
      if(res.data.status) {
        // dispatch(updateRedux({key : "group_details", value : res.data.group_details}))
        dispatch(getContactBookData(0,""))
        callback()
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err) => {
      console.log(err)
  })
}

export const updateContactBook = (data,page,search,callback) => (dispatch) => {
  axiosInstance.post(`${baseUrl}updateContact`,data).then(res => {
      if(res.data.status) {
        dispatch(getContactBookData(page,search))
        if(callback){
          callback()
        }
      }else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err) => {
      console.log(err)
  })
}

export const getContactBookData = (page,search) => (dispatch) => {
  axiosInstance.get(`${baseUrl}getContactList/?search=${search}&page=${page}`).then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "contact_list", value : res.data.contact_list}))
      dispatch(updateRedux({key : "contactTotalPages", value : res.data.total_pages}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const getContactBookDetails = (id) => (dispatch) => {
  axiosInstance.get(`${baseUrl}getContactDetail/${id}`).then((res)=>{
    if(res.data.status) {
      console.log(res.data,"res")
      dispatch(updateRedux({key : "selectedContactList", value : res.data.contact_details}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const getGroupData = (id) => (dispatch) => {
  axiosInstance.get(`${baseUrl}`).then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "group_details", value : res.data.group_details}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const searchGroupContact = (formData) => (dispatch) => {
  dispatch(updateRedux({key : "userGroupContactDetails", value : []}))
  axiosFormInstance.post(`${baseUrl}searchContacts`,formData).then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "userGroupContactDetails", value : res.data.user_details}))
    }else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const getCampaignDetails = (id) => (dispatch) => {
  axiosInstance.get(`${baseUrl}campaignDetails/${id}`).then((res)=>{
    if(res.data.status) {
      console.log(res.data,"res")
      dispatch(updateRedux({key : "recieverList", value : res.data.campaign_details.response_details}))
      dispatch(updateRedux({key : "campaignName", value : res.data.campaign_details.message}))
      dispatch(updateRedux({key : "campaignMessage", value : res.data.campaign_details.name}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}


export const getModulePermission = (callback) => (dispatch) => {
  axiosInstance.get(`${baseUrl}getRoleUserPermissions`).then((res)=>{
    if(res.data.status){
      localStorage.setItem("permission", res.data.modules?.permissions);
      dispatch(updateRedux({key : "permissionModules", value : res.data.modules}))
      if(callback) {
        callback()
      }
    }else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err.response,"6")
  })
}

export const getuserCSV = (callback) => (dispatch) => {
  axiosInstance.get(`${baseUrl}getUserCsv`).then((res)=>{
    if(res.status == 200){
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `FileName.csv`,
      );
      document.body.appendChild(link);
      link.click();
    }else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
    console.log(err)
  })
}

export const uploadUserCSV = (formData,callback) => (dispatch) => {
  dispatch(updateRedux({key : "userLoader", value : true}))
  axiosFormInstance.post(`${baseUrl}employeeExcel`,formData).then(res => {
      if(res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
        // dispatch(updateRedux({key : "userLoader", value : true}))
        // setTimeout(()=> {
          dispatch(getUser("",0))
        // },2000)
        if(callback) {
          callback()
        }
      } else {
        dispatch(updateRedux({key : "userLoader", value : false}))
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err) => {
      console.log(err)
  })
}

export const getContactCSV = (callback) => (dispatch) => {
  axiosInstance.get(`${baseUrl}getContactCsv`).then((res)=>{
    if(res.status == 200){
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `contact.csv`,
      );
      document.body.appendChild(link);
      link.click();
    }else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
    console.log(err)
  })
}



export const getUserSampleCSV = (callback) => (dispatch) => {
  axios.get(
    `${baseUrl}getUserCsvSample`, 
        {
          responseType: 'arraybuffer',
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
    ).then((res)=>{
    if(res.status == 200){
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'users.xlsx',
      );
      document.body.appendChild(link);
      link.click();
      
    }else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
    console.log(err)
  })
}

export const getSampleCSV = (callback) => (dispatch) => {
  axiosInstance.get(`${baseUrl}getContactCsvSample`).then((res)=>{
    if(res.status == 200){
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `contact.csv`,
      );
      document.body.appendChild(link);
      link.click();
    }else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
    console.log(err)
  })
}


export const uploadContactCSV = (formData,callback) => (dispatch) => {
  axiosFormInstance.post(`${baseUrl}processContact`,formData).then(res => {
      if(res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
        dispatch(updateRedux({key : "csvContact", value : res.data.contacts}))
        callback()
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err) => {
      console.log(err)
  })
}

export const searchContactBook = (formData) => (dispatch) => {
  axiosFormInstance.post(`${baseUrl}searchContacts`,formData).then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "userContactBook", value : res.data.users}))
      dispatch(updateRedux({key : "userContactDetailsBook", value : res.data.user_details}))
      dispatch(updateRedux({key : "contactBookTotalCount", value : res.data.count}))
      dispatch(updateRedux({key : "contactBookTotalPageCount", value : res.data.total_pages}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const uploadCampaignCSV = (formData,callback) => (dispatch) => {
  console.log(formData,)
  axiosFormInstance.post(`${baseUrl}processContact`,formData).then(res => {
      if(res.data.status) {
        toast.success("File uploaded succesfully", {
          position: "bottom-center",
          autoClose: 3000
        })
        dispatch(updateRedux({key : "csvCampaign", value : res.data.contacts}))
        callback()
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err) => {
      console.log(err)
  })
}

export const getCampaignCSV = (callback) => (dispatch) => {
  axiosInstance.get(`${baseUrl}getContactCsv`).then((res)=>{
    console.log(res)
    if(res.status == 200){
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `contact.csv`,
      );
      document.body.appendChild(link);
      link.click();
    }else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
    console.log(err)
  })
}

export const searchCampaignContact = (formData) => (dispatch) => {
  dispatch(updateRedux({key : "userCampaignContactDetails", value : []}))
  axiosFormInstance.post(`${baseUrl}searchContacts`,formData).then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "userCampaignContactDetails", value : res.data.user_details}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const getContactFullList = () => (dispatch) => {
  axiosInstance.get(`${baseUrl}getContactListFull`).then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "contactFullList", value : res.data.contact_list}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const getTemplateFullList = (page,search) => (dispatch) => {
  axiosInstance.get(baseUrl+`getTemplatesFull`).then((res)=>{
      if(res.data.status) {
        dispatch(updateRedux({key : "templateFullLists", value : res.data.template_details}))
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err)=>{
      console.log(err)
  })
}

export const getJobList=()=>(dispatch)=>{
  axiosInstance.get(baseUrl+"getJobTitle").then((res)=>{
      if(res.data.status) {
        dispatch(updateRedux({key : "jobLists", value : res.data.job_title}))
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err)=>{
      console.log(err)
    })
}

export const syncAd=()=>(dispatch)=>{
  axiosInstance.get(baseUrl+"activeDirectorySync").then((res)=>{
      if(res.data.status) {
        dispatch(getUser("",0))
        toast.success("Active Directory Sync Succesfully", {
          position: "bottom-center",
          autoClose: 3000
        })
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err)=>{
      console.log(err)
    })
}



export const searchGroup = (formData) => (dispatch) => {
  dispatch(updateRedux({key : "userGroupContactDetails", value : []}))
  axiosFormInstance.post(`${baseUrl}getGroups`,formData).then((res)=>{
    if(res.data.status) {
      console.log(res.data)
      dispatch(updateRedux({key : "userGroupContactDetails", value : res.data.user_details}))
      dispatch(updateRedux({key : "userGroupNumberDetails", value : res.data.number_details}))
    }else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const searchEditContactBook = (formData) => (dispatch) => {
  axiosFormInstance.post(`${baseUrl}searchContacts`,formData).then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "editUserSearchdata", value : res.data.user_details}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}


export const getDashboardData=(data)=>(dispatch)=>{
  axiosInstance.post(baseUrl+"getDashboardList",data).then((res)=>{
      if(res.data.status) {
        dispatch(updateRedux({key : "DashboardDatas", value : res.data}))
        dispatch(updateRedux({key : "DashboardChart", value : res.data?.no_of_messages_month}))
        dispatch(updateRedux({key : "DashboardUserChart", value : res.data.user_list}))
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err)=>{
      console.log(err)
    })
}


export const getDateDashboardData=(data)=>(dispatch)=>{
  axiosInstance.post(baseUrl+"getDashboardList",data).then((res)=>{
      if(res.data.status) {
        dispatch(updateRedux({key : "dashboardDateChart", value : res.data?.no_of_messages_month}))
        dispatch(updateRedux({key : "dashboardAllChart", value : res.data}))
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err)=>{
      console.log(err)
    })
}

export const getDashboardUserData=()=>(dispatch)=>{
  axiosInstance.get(baseUrl+"getDashboardUserList").then((res)=>{
      if(res.data.status) {
        dispatch(updateRedux({key : "DashboardUsersLists", value : res?.data.data}))
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err)=>{
      console.log(err)
    })
}



export const getCampaign=(page,search)=>(dispatch)=>{
    axiosInstance.get(`${baseUrl}getCampaign/?search=${search}&page=${page}`).then((res)=>{
      if(res.data.status) {
        dispatch(updateRedux({key : "CampaignStatisticsLists", value : res.data.campaign_list}))
        dispatch(updateRedux({key : "campaignStatisticsTotalCount", value : res.data.total_pages}))
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err)=>{
      console.log(err)
    })
}

export const getTem= (data) => (dispatch) => {
  axiosInstance.post(baseUrl+`insertCampaign`,data).then((res)=>{
      if(res.data.status) {
        dispatch(updateRedux({key : "templatelists", value : res.data.template_details}))
        dispatch(updateRedux({key : "templateTotalCount", value : res.data.total_pages}))
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err)=>{
      console.log(err)
  })
}

export const searchPermittedUser = (formData,callback) => (dispatch) => {
  axiosFormInstance.post(`${baseUrl}getPermittedUsers`,formData).then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "permittedUsers", value : res.data.user_list}))
      if(res.data.user_list?.length == 0){
        callback(res.data.user_list)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const getCampaigns=(formData)=>(dispatch)=>{
  axiosFormInstance.post(`${baseUrl}getCampaigns`, formData).then((res)=>{
    if(res.data.status) {
      dispatch(updateRedux({key : "CampaignStatisticsLists", value : res.data.campaign_list}))
      dispatch(updateRedux({key : "campaignStatisticsTotalCount", value : res.data.total_pages}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
    console.log(err)
  })
}

export const getCampaignList=()=>(dispatch)=>{
  axiosInstance.get(baseUrl+"getCampaignNames").then((res)=>{
      if(res.data.status) {
        dispatch(updateRedux({key : "campaignList", value : res.data.campign_names}))
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err)=>{
      console.log(err)
    })
}

export const exportCampaign = (formData) => (dispatch) => {
  axios.post(`${baseUrl}exportCampaign`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization' : `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res)=>{
    if(res.status == 200){
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'Campaign.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    }else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
    console.log(err)
  })
}

export const getSignature = (page,search) => (dispatch) => {
  axiosInstance.get(`${baseUrl}getSignature`).then((res)=>{
      if(res.data.status) {
        dispatch(updateRedux({key : "signature_details", value : res.data.signature_details}))
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err)=>{
      console.log(err)
  })
}

export const updateSignature = (data, callback) => (dispatch) => {
  axiosInstance.post(`${baseUrl}updateSignature`,data).then(res => {
      if(res.data.status) {
        dispatch(getSignature())
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
        if(callback) {
          callback()
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
  }).catch((err) => {
      console.log(err)
  })
}
