import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Header() {

  const navigate = useNavigate();

  const openSidebar = () => {
    document.body.classList.add('toggle-sidebar');
  }

  const closeSidebar = () => {
    document.body.classList.remove('toggle-sidebar');
  }
  
  const logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('permission');
    localStorage.removeItem('super_admin');
    localStorage.removeItem('username');
    navigate('/login');
  }

  return(
    <React.Fragment>
      <header id = "header" 
        className = "header fixed-top d-flex align-items-center justify-content-between">
        <div className = "d-flex align-items-center justify-content-between">
          <i onClick = {openSidebar} className = "menu__open bi bi-list toggle-sidebar-btn" />
          <i onClick = {closeSidebar} className = "menu__close bi bi-x-lg toggle-sidebar-btn" />
        </div>
        <nav className = "header-nav">
          <ul className = "d-flex align-items-center">
            <li className = "nav-item dropdown pe-3">
              <a className = "nav-link nav-profile d-flex align-items-center pe-0" 
                href = "" 
                data-bs-toggle = "dropdown">
                <img src = "assets/img/icons/user.png" height = {20} alt = "User Profile" />
                <span className = "d-none d-md-block dropdown-toggle">{localStorage.getItem("username")}</span>
              </a>
              <ul className = "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li onClick = {logOut}>
                  <Link className = "dropdown-item d-flex align-items-center">
                    <span>تسجيل خروج</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
    </React.Fragment>
  )
};

export default Header;
