import React, { useEffect, useState } from "react";
import Header from "../componets/Header";
import Sidebar from "../componets/Sidebar";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "../styles/Campaign.css";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  insertCampaignData,
  getUnitData,
  getRankData,
  getMultiSubunitData,
  getMultiWorkshiftData,
  uploadCampaignCSV,
  getSampleCSV,
  searchCampaignContact,
  getContactFullList,
  getTemplateFullList,
  searchGroup,
} from "../redux/actionCreator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ListUnits,
  RankLists,
  multiSubunitLists,
  multiWorkshiftLists,
  userCampaignContactDetails,
  updateRedux,
  userGroupContactDetails,
  csvCampaign,
  contactFullList,
  templateFullLists,
  userGroupNumberDetails,
} from "../redux/TemplateSlice";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import { MultiSelect } from "react-multi-select-component";

const Campaign = () => {
  const dispatch = useDispatch();

  const templateList = useSelector(templateFullLists);
  const units = useSelector(ListUnits);
  const rankList = useSelector(RankLists);
  const subUnitList = useSelector(multiSubunitLists);
  const workShiftList = useSelector(multiWorkshiftLists);
  const fullContactList = useSelector(userCampaignContactDetails);
  const contactList = useSelector(contactFullList);
  const fullGroupData = useSelector(userGroupContactDetails);
  const fullContact = useSelector(csvCampaign);
  const groupNumberList = useSelector(userGroupNumberDetails);

  const [counter, setCounter] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneNumberArray, setPhoneNumberArray] = useState([]);
  const [err, setErr] = useState({
    phone: "",
  });
  const [details, setDetails] = useState("");
  const [message, setMessage] = useState("");
  const [countMessage, setCouMessage] = useState("");
  const [template, setTemplate] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [gender, setGender] = useState("");
  const [group, setGroup] = useState("");
  const [subunit, setSubunit] = useState([]);
  const [unit, setUnit] = useState([]);
  const [workshift, setWorkShift] = useState([]);
  const [rank, setRank] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [userId, setUserId] = useState([]);
  const [schedule, setSchedule] = useState(false);
  const [scheduledDate, setScheduleDate] = useState(new Date());
  const [userContactId, setUserContactId] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroupUsers, setSelectedGroupUsers] = useState([]);
  const [csv, setCSV] = useState([]);
  const [modalshow, setModalShow] = useState(false);
  const [modalGroupshow, setModalGroupShow] = useState(false);
  const [deleteID, setDelId] = useState("");
  const [usersDetails, setUsersDetails] = useState([]);
  const [pageCount, setPageCount] = useState(null);
  const [page, setPage] = useState(0);
  const [userGroupContactDetail, setUserGroupContactDetail] = useState([]);
  const [groupCount, setGroupCount] = useState(null);
  const [groupPage, setGroupPage] = useState(0);
  const [contact, setContact] = useState([]);
  const [csvCount, setCsvCount] = useState(null);
  const [csvPage, setCsvPage] = useState(0);
  const [csvSelected, setCSVSelected] = useState([]);
  const [selectedUserDetails, setSelectedUserDetails] = useState([]);
  const [selectedUserPageCount, setSelectedUserPageCount] = useState(null);
  const [selectedUserPage, setSelectedUserPage] = useState(0);
  const [selectedGroupDetails, setSelectedGroupDetails] = useState([]);
  const [selectedGroupPageCount, setSelectedGroupPageCount] = useState(null);
  const [selectedGroupPage, setSelectedGroupPage] = useState(0);
  const [selectedCsvDetails, setSelectedCsvDetails] = useState([]);
  const [selectedCsvPageCount, setSelectedCsvPageCount] = useState(null);
  const [selectedCsvPage, setSelectedCsvPage] = useState(0);
  const [csvModalShow, setCsvModalShow] = useState(false);
  const [delCsv, setDelCsv] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [username, setUsername] = useState("");
  const [sortColumn, setSortColumn] = useState("name");
  const [sortAscending, setSortAscending] = useState(true);
  const [finalPage, setFinalPage] = useState(0);
  const [finalUsersDetails, setFinalUsersDetails] = useState([]);
  const [finalPageCount, setFinalPageCount] = useState(null);
  const [finalSelectedUsers, setFinalSelectedUsers] = useState([]);
  const [finalUsers, setFinalUsers] = useState([]);
  const [finalUserId, setFinalUserId] = useState([]);

  useEffect(() => {
    document.title = "TSD - إدارة الرسائل";
  }, []);

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const handleGroupClose = () => setModalGroupShow(false);

  const handleCsvClose = () => setCsvModalShow(false);

  const deleteUserData = (id) => {
    setModalShow(true);
    setDelId(id);
  };

  const deleteUserGroupData = (id) => {
    setModalGroupShow(true);
    setDelId(id);
  };

  const changeMessage = (value) => {
    setMessage(value)
    setCouMessage(value.length)
  };

  const deleteUserCsvData = (ele) => {
    setDelCsv(ele);
    setCsvModalShow(true);
  };

  useEffect(() => {
    dispatch(getTemplateFullList());
    dispatch(getUnitData());
    dispatch(getRankData());
    dispatch(getContactFullList());
    dispatch(updateRedux({ key: "csvCampaign", value: [] }));
    dispatch(updateRedux({ key: "userGroupContactDetails", value: [] }));
    dispatch(updateRedux({ key: "userGroupNumberDetails", value: [] }));
    dispatch(updateRedux({ key: "userCampaignContactDetails", value: [] }));
    setUserId([]);
    setSelectedUsers([]);
    setCSVSelected([]);
    setSelectedGroupUsers([]);
    setSelectedUserDetails([]);
    setSelectedGroupDetails([]);
    setSelectedCsvDetails([]);
    setPageCount(null);
    setGroupCount(null);
    setCsvCount(null);
    setSelectedUserPageCount(null);
    setSelectedCsvPageCount(null);
    setSelectedGroupPageCount(null);
    setCSVSelected([]);
    setSelectedGroupUsers([]);
    setSelectedUsers([]);
    setSelectedUserDetails([]);
    setSelectedGroupDetails([]);
    setSelectedCsvDetails([]);
    setPageCount(null);
    setPage(0);
    setGroupCount(null);
    setGroupPage(0);
    setContact([]);
    setCsvCount(null);
    setCsvPage(0);
    setSelectedUserPageCount(null);
    setSelectedUserPage(0);
    setSelectedGroupPageCount(null);
    setSelectedGroupPage(0);
    setSelectedCsvPageCount(null);
    setSelectedCsvPage(0);
    setSelectedTemplate({});
  }, []);

  useEffect(() => {
    if (fullContactList) {
      pageDataHandler(page);
      setPageCount(Math.ceil(fullContactList?.length / 10));
      let id = [];
      fullContactList?.forEach((ele) => {
        id.push(ele._id);
      });
      setUserId([...id]);
      setSelectedUsers([...fullContactList]);
    }
  }, [fullContactList]);

  const pageDataHandler = (pag) => {
    setUsersDetails(fullContactList.slice(pag * 10, pag * 10 + 10));
  };

  const onMoveHandler = () => {
    setFinalUsers([...finalUsers, ...selectedUsers]);
    setKeyword("");
    setGender("Select Gender");
    setSubunit([]);
    setUnit([]);
    setWorkShift([]);
    setRank([]);
    setEmployeeId("");
    setUsername("");
    setSelectedUsers([]);
    setSelectedUserDetails([]);
    dispatch(updateRedux({ key: "userCampaignContactDetails", value: [] }));
    setUserId([]);
    setUsersDetails([]);
  };

  useEffect(() => {
    if (finalUsers) {
      finalPageDataHandler(page);
      setFinalPageCount(Math.ceil(finalUsers?.length / 10));
      let id = [];
      finalUsers?.forEach((ele) => {
        id.push(ele._id);
      });
      setFinalUserId([...id]);
      setFinalSelectedUsers([...finalUsers]);
    }
  }, [finalUsers]);

  const finalPageDataHandler = (pag) => {
    if (finalUsers.slice(pag * 10, pag * 10 + 10)?.length == 0 && pag != 0) {
      setFinalPage(pag - 1);
      setFinalUsersDetails(
        finalUsers.slice((pag - 1) * 10, (pag - 1) * 10 + 10)
      );
    } else {
      setFinalUsersDetails(finalUsers.slice(pag * 10, pag * 10 + 10));
    }
  };

  const handleFinalPageChange = (e) => {
    setFinalPage(e.selected);
    finalPageDataHandler(Number(e.selected));
  };

  const selectAllFinalUserHandler = (e) => {
    if (e.target.checked) {
      let id = [];
      finalUsers?.forEach((ele) => {
        id.push(ele._id);
      });
      setFinalUserId([...id]);
      setFinalSelectedUsers([...finalUsers]);
    } else {
      setFinalUserId([]);
      setFinalSelectedUsers([]);
      setFinalUsersDetails([])
      setFinalUsers([]);
    }
  };

  useEffect(() => {
    if (fullGroupData) {
      groupDataHandler(groupPage);
      setGroupCount(Math.ceil(fullGroupData?.length / 10));
      let id = [];
      fullGroupData?.forEach((ele) => {
        id.push(ele._id);
      });
      setUserContactId([...id]);
      setSelectedGroupUsers([...fullGroupData]);
    }
  }, [fullGroupData]);

  const groupDataHandler = (pag) => {
    setUserGroupContactDetail(fullGroupData.slice(pag * 10, pag * 10 + 10));
  };

  useEffect(() => {
    if (fullContact) {
      csvDataHandler(page);
      setCsvCount(Math.ceil(fullContact?.length / 10));
      setCSVSelected([...fullContact]);
    }
  }, [fullContact]);

  const csvDataHandler = (pag) => {
    setContact(fullContact.slice(pag * 10, pag * 10 + 10));
  };

  const onCounter3ChangeHandler = () => {
    setCounter(3);
    if (selectedUsers?.length && counter == 2) {
      userListPagination();
    }
    if (selectedGroupUsers?.length && counter == 2) {
      groupListPagination();
    }
    if (csvSelected?.length && counter == 2) {
      csvListPagination();
    }
  };

  const next = () => {
    if (counter < 3) {
      setCounter(counter + 1);
    }
    if (finalSelectedUsers?.length && counter == 2) {
      userListPagination();
    }
    if (selectedGroupUsers?.length && counter == 2) {
      groupListPagination();
    }
    if (csvSelected?.length && counter == 2) {
      csvListPagination();
    }
  };

  const prev = () => {
    if (counter > 1) {
      setCounter(counter - 1);
    }
    if (selectedUsers?.length && counter == 2) {
      userListPagination();
    }
    if (selectedGroupUsers?.length && counter == 2) {
      groupListPagination();
    }
    if (csvSelected?.length && counter == 2) {
      csvListPagination();
    }
  };

  const userListPagination = () => {
    userDataHandler(selectedUserPage);
    // setSelectedUserPageCount(Math.ceil(selectedUsers?.length/10));
    setSelectedUserPageCount(Math.ceil(finalSelectedUsers?.length / 10));
  };

  const userDataHandler = (pag, arr) => {
    if (arr) {
      if (arr.slice(pag * 10, pag * 10 + 10)?.length == 0 && pag != 0) {
        setSelectedUserPage(pag - 1);
        setSelectedUserDetails(arr.slice((pag - 1) * 10, (pag - 1) * 10 + 10));
      } else {
        setSelectedUserDetails(arr.slice(pag * 10, pag * 10 + 10));
      }
    } else {
      if (
        finalSelectedUsers.slice(pag * 10, pag * 10 + 10)?.length == 0 &&
        pag != 0
      ) {
        setSelectedUserPage(pag - 1);
        setSelectedUserDetails(
          finalSelectedUsers.slice((pag - 1) * 10, (pag - 1) * 10 + 10)
        );
      } else {
        setSelectedUserDetails(
          finalSelectedUsers.slice(pag * 10, pag * 10 + 10)
        );
      }
    }
  };

  const handleSelectedUserPageChange = (e) => {
    setSelectedUserPage(e.selected);
    userDataHandler(Number(e.selected));
  };

  const groupListPagination = () => {
    groupListDataHandler(selectedGroupPage);
    setSelectedGroupPageCount(Math.ceil(selectedGroupUsers?.length / 10));
  };

  const groupListDataHandler = (pag, arr) => {
    if (arr) {
      if (arr.slice(pag * 10, pag * 10 + 10)?.length == 0 && pag != 0) {
        setSelectedGroupPage(pag - 1);
        setSelectedGroupDetails(arr.slice((pag - 1) * 10, (pag - 1) * 10 + 10));
      } else {
        setSelectedGroupDetails(arr.slice(pag * 10, pag * 10 + 10));
      }
    } else {
      if (
        selectedGroupUsers.slice(pag * 10, pag * 10 + 10)?.length == 0 &&
        pag != 0
      ) {
        setSelectedGroupPage(pag - 1);
        setSelectedGroupDetails(
          selectedGroupUsers.slice((pag - 1) * 10, (pag - 1) * 10 + 10)
        );
      } else {
        setSelectedGroupDetails(
          selectedGroupUsers.slice(pag * 10, pag * 10 + 10)
        );
      }
    }
  };

  const handleSelectedGroupPageChange = (e) => {
    setSelectedGroupPage(e.selected);
    groupListDataHandler(Number(e.selected));
  };

  const csvListPagination = () => {
    csvListDataHandler(selectedCsvPage);
    setSelectedCsvPageCount(Math.ceil(csvSelected?.length / 10));
  };

  const csvListDataHandler = (pag, arr) => {
    if (arr) {
      if (arr.slice(pag * 10, pag * 10 + 10)?.length == 0 && pag != 0) {
        setSelectedCsvPage(pag - 1);
        setSelectedCsvDetails(arr.slice((pag - 1) * 10, (pag - 1) * 10 + 10));
      } else {
        setSelectedCsvDetails(arr.slice(pag * 10, pag * 10 + 10));
      }
    } else {
      if (csvSelected.slice(pag * 10, pag * 10 + 10)?.length == 0 && pag != 0) {
        setSelectedCsvPage(pag - 1);
        setSelectedCsvDetails(
          csvSelected.slice((pag - 1) * 10, (pag - 1) * 10 + 10)
        );
      } else {
        setSelectedCsvDetails(csvSelected.slice(pag * 10, pag * 10 + 10));
      }
    }
  };

  const handleSelectedCsvPageChange = (e) => {
    setSelectedCsvPage(e.selected);
    csvListDataHandler(Number(e.selected));
  };

  const onNumberChangeHandler = (e) => {
    if (e.key == "Enter") {
      let temp;
      let validNumber = [];
      let invalidNumber = [];
      temp = phoneNumber.split(",");
      temp.forEach((ele) => {
        if (ele.length < 9) {
          invalidNumber.push(ele);
          setErr({
            ...err,
            phone: "الرجاء إدخال رقم صحيح",
          });
        } else {
          if (ele.length == 9 || ele.length <= 13) {
            let str = ele.replace("+", "");
            if (str.match(/^[0-9]+$/)) {
              validNumber.push(ele);
              setErr({
                ...err,
                phone: "",
              });
            } else {
              invalidNumber.push(ele);
              setErr({
                ...err,
                phone: "الرجاء إدخال رقم صحيح",
              });
            }
          } else {
            invalidNumber.push(ele);
            setErr({
              ...err,
              phone: "الرجاء إدخال رقم صحيح",
            });
          }
        }
      });
      setPhoneNumberArray([...phoneNumberArray, ...validNumber]);
      if (invalidNumber.length) {
        setPhoneNumber(invalidNumber.join(","));
      } else {
        setPhoneNumber("");
      }
    }
  };

  const onDeleteNumberhandler = (num) => {
    let temp;
    temp = phoneNumberArray.filter((ele) => ele != num);
    setPhoneNumberArray([...temp]);
  };

  const onDeleteGroupNumberhandler = (num) => {
    let temp;
    temp = groupNumberList.filter((ele) => ele != num);
    dispatch(updateRedux({ key: "userGroupNumberDetails", value: [...temp] }));
  };

  const templateChangehandler = (e) => {
    let temp = templateList?.filter((ele) => ele._id == e.target.value);
    if (e.target.value == "Select template") {
      setMessage("");
      setCouMessage(0)
      setSelectedTemplate({});
      setTemplate("");
    } else {
      setTemplate(e.target.value);
      setSelectedTemplate(temp?.length ? temp[0] : {});
      setMessage(temp?.length ? temp[0]?.description : message);
      setCouMessage(temp[0]?.description?.length)
    }
  };

  const onClearHandler = () => {
    setKeyword("");
    setGender("Select Gender");
    setSubunit([]);
    setUnit([]);
    setWorkShift([]);
    setRank([]);
    setEmployeeId("");
    setUsername("");
    setSelectedUsers([]);
    setSelectedUserDetails([]);
    dispatch(updateRedux({ key: "userCampaignContactDetails", value: [] }));
    dispatch(updateRedux({ key: "multiWorkshiftLists", value: [] }))
    dispatch(updateRedux({ key: "multiSubunitLists", value: [] }))
  };

  const onSearchHandler = (pag, e) => {
    if (e) {
      e.preventDefault();
    }
    if (
      keyword?.length == 0 &&
      (gender?.length == 0 || gender == "Select Gender") &&
      unit?.length == 0 &&
      subunit?.length == 0 &&
      workshift?.length == 0 &&
      rank?.length == 0 &&
      username?.length == 0 &&
      employeeId?.length == 0
    ) {
      if (e) {
        toast.warning("الرجاء إضافة بيانات البحث", {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    } else {
      setSelectedUsers([]);
      setSelectedUserDetails([]);
      setUsersDetails([]);
      setUserId([]);
      let formData = new FormData();
      formData.append("keyword", keyword);
      formData.append("employee_id", employeeId);
      formData.append("username", username);
      if (gender?.length) {
        if (gender == "Select Gender") {
          formData.append("gender", "");
        } else {
          formData.append("gender", gender);
        }
      }
      if (group?.length) {
        group?.forEach((item) => formData.append("group[]", item._id));
      } else {
        formData.append("group", []);
      }
      unit?.forEach((item) => formData.append("unit[]", item._id));
      subunit?.forEach((item) => formData.append("subunit[]", item._id));
      workshift?.forEach((item) => formData.append("workshift[]", item._id));
      rank?.forEach((item) => formData.append("rank[]", item._id));
      dispatch(searchCampaignContact(formData));
    }
  };

  const onDeleteSelectedContactUser = () => {
    // let temp  =  selectedUsers?.filter((ele)  => ele._id !=  deleteID);
    // setSelectedUsers([...temp]);
    // setUserId(userId?.filter(ele  => ele !=  deleteID));
    let temp = finalSelectedUsers?.filter((ele) => ele._id != deleteID);
    setFinalSelectedUsers([...temp]);
    setFinalUserId(finalUserId?.filter((ele) => ele != deleteID));
    let data = selectedUserDetails?.filter((ele) => ele._id != deleteID);
    setSelectedUserDetails([...data]);
    userDataHandler(selectedUserPage, temp);
    setModalShow(false);
  };

  const onDeleteSelectedContactGroupUser = () => {
    let temp = selectedGroupUsers?.filter((ele) => ele._id != deleteID);
    setSelectedGroupUsers([...temp]);
    setUserContactId(userContactId.filter((ele) => ele != deleteID));
    groupListDataHandler(selectedGroupPage, temp);
    setModalGroupShow(false);
  };

  const onDeleteSelectedCsvUser = () => {
    let temp = csvSelected?.filter((ele) => ele != delCsv);
    setCSVSelected([...temp]);
    csvListDataHandler(selectedCsvPage, temp);
    setCsvModalShow(false);
  };

  const selectGroupHandler = (e, pag) => {
    setGroup(e);
    if (e.length) {
      let formData = new FormData();
      e?.forEach((item) => formData.append("group[]", item._id));
      dispatch(searchGroup(formData));
    } else {
      dispatch(updateRedux({ key: "userGroupContactDetails", value: [] }));
      dispatch(updateRedux({ key: "userGroupNumberDetails", value: [] }));
    }
  };

  const onUnitSelecthandler = (value) => {
    setUnit(value);
    setSubunit([]);
    setWorkShift([]);
    if (value?.length) {
      let formData = new FormData();
      value?.forEach((item) => formData.append("unit[]", item._id));
      dispatch(
        getMultiSubunitData(formData, (data) => {
          let temp;
          temp = data?.filter((el) => {
            return subunit?.find((element) => {
              return element._id == el._id;
            });
          });
          setSubunit(temp);
        })
      );
    } else {
      dispatch(updateRedux({ key: "multiSubunitLists", value: [] }))
    }
  };

  const onSubUnitSelecthandler = (value) => {
    setSubunit(value);
    setWorkShift([]);
    if (value?.length) {
      let formData = new FormData();
      value?.forEach((item) => formData.append("subunit[]", item._id));
      dispatch(
        getMultiWorkshiftData(formData, (data) => {
          let temp;
          temp = data?.filter((el) => {
            return workshift?.find((element) => {
              return element._id == el._id;
            });
          });
          setWorkShift(temp);
        })
      );
    } else {
      dispatch(updateRedux({ key: "multiWorkshiftLists", value: [] }))
    }
  };

  const onSendHandler = () => {
    if (details?.length == "") {
      toast.warning("الرجاء إدخال تفاصيل الرسالة", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else if (message?.length == "") {
      toast.warning("الرجاء إدخال الرسالة", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else if (
      phoneNumberArray?.length == 0 &&
      finalSelectedUsers?.length == 0 &&
      selectedGroupUsers?.length == 0 &&
      csvSelected?.length == 0
    ) {
      toast.warning("الرجاء إضافة أرقام", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      let numberList = [...phoneNumberArray, ...csvSelected];
      groupNumberList?.forEach((item) => numberList.push(item.phone));
      let userIds = [];
      finalSelectedUsers?.forEach((item) => userIds.push(item._id));
      selectedGroupUsers?.forEach((item) => userIds.push(item._id));
      let data = {
        number_list: numberList,
        user_ids: userIds,
        campaign_details: details,
        message: message,
        template_id: template == "Select template" ? "" : template,
      };
      if (schedule) {
        data = {
          ...data,
          schedule: scheduledDate,
        };
      }
      dispatch(
        insertCampaignData(data, () => {
          toast.success("الرسائل القصيرة المراد ارسالها", {
            position: "bottom-center",
            autoClose: 3000,
          });
          setCounter(1);
          setMessage("");
          setDetails("");
          setPhoneNumber("");
          setPhoneNumberArray([]);
          setSchedule(false);
          setScheduleDate(new Date());
          setSelectedGroupUsers([]);
          setUnit([]);
          setSubunit([]);
          setWorkShift([]);
          setKeyword("");
          setGender("");
          setGroup("");
          setCSVSelected([]);
          setSelectedGroupUsers([]);
          setSelectedUsers([]);
          setSelectedUserDetails([]);
          setSelectedGroupDetails([]);
          setSelectedCsvDetails([]);
          setPageCount(null);
          setPage(0);
          setGroupCount(null);
          setGroupPage(0);
          setContact([]);
          setCsvCount(null);
          setCsvPage(0);
          setSelectedUserPageCount(null);
          setSelectedUserPage(0);
          setSelectedGroupPageCount(null);
          setSelectedGroupPage(0);
          setSelectedCsvPageCount(null);
          setSelectedCsvPage(0);
          setSelectedTemplate({});
          setTemplate("Select template");
          dispatch(
            updateRedux({ key: "userCampaignContactDetails", value: [] })
          );
          dispatch(updateRedux({ key: "userGroupContactDetails", value: [] }));
          dispatch(updateRedux({ key: "csvCampaign", value: [] }));
          setFinalPage(0);
          setFinalUsersDetails([]);
          setFinalPageCount([]);
          setFinalSelectedUsers([]);
          setFinalUsers([]);
          setFinalUserId([]);
        })
      );
    }
  };

  const onScheduleHandler = (date) => {
    setScheduleDate(date);
  };

  const onCounter1Validation = (counter) => {
    if (details == "") {
      toast.warning("الرجاء إدخال تفاصيل الرسالة", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else if (message == "") {
      toast.warning("الرجاء إدخال الرسالة", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      next();
    }
  };

  const onCounter2Validation = (counter) => {
    if (
      phoneNumberArray?.length == 0 &&
      finalUsersDetails?.length == 0 &&
      userGroupContactDetail?.length == 0 &&
      groupNumberList?.length == 0 &&
      contact?.length == 0
    ) {
      toast.warning("الرجاء إضافة أرقام", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      next();
    }
  };

  const sideCounter1Changehandler = () => {
    if (details == "") {
      toast.warning("الرجاء إدخال تفاصيل الحملة", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else if (message == "") {
      toast.warning("الرجاء إدخال الرسالة", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      setCounter(2);
    }
  };

  const sideCounter2Changehandler = () => {
    if (
      phoneNumberArray?.length == 0 &&
      usersDetails?.length == 0 &&
      userGroupContactDetail?.length == 0 &&
      groupNumberList?.length == 0 &&
      contact?.length == 0
    ) {
      toast.warning("الرجاء إضافة أرقام", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      onCounter3ChangeHandler();
    }
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    pageDataHandler(Number(e.selected));
  };

  const handleGroupPageChange = (e) => {
    setGroupPage(e.selected);
    groupDataHandler(Number(e.selected));
  };

  const handleCsvPageChange = (e) => {
    setCsvPage(e.selected);
    csvDataHandler(Number(e.selected));
  };

  const selectAllUserHandler = (e) => {
    if (e.target.checked) {
      let id = [];
      fullContactList?.forEach((ele) => {
        id.push(ele._id);
      });
      setUserId([...id]);
      setSelectedUsers([...fullContactList]);
    } else {
      setUserId([]);
      setSelectedUsers([]);
    }
  };

  const selectAllUserContactHandler = (e) => {
    if (e.target.checked) {
      let id = [];
      fullGroupData?.forEach((ele) => {
        id.push(ele._id);
      });
      setUserContactId([...id]);
      setSelectedGroupUsers([...fullGroupData]);
    } else {
      setUserContactId([]);
      setSelectedGroupUsers([]);
      setUserGroupContactDetail([])
    }
  };

  const selectAllContactHandler = (e) => {
    if (e.target.checked) {
      setCSVSelected([...fullContact]);
    } else {
      setCSVSelected([]);
      setContact([])
    }
  };

  const progess =
    counter == 1 ? 33 : counter == 2 ? 66 : counter == 3 ? 100 : null;

  const onUploadHandler = (e) => {
    setCSV(e.target.files);
  };

  const onFileUploadHandler = () => {
    if (csv?.length) {
      let formData = new FormData();
      formData.append("contact_list", csv[0]);
      dispatch(
        uploadCampaignCSV(formData, () => {
          setCSV([]);
        })
      );
    } else {
      toast.warning("رجى تحميل ملف csv", {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
  };

  const onDownloadSample = () => {
    dispatch(getSampleCSV());
  };

  const tableSort = (type) => {
    setSortAscending(!sortAscending);
    setSortColumn(type);
    let temp = [...fullContactList];
    if (sortAscending == true) {
      temp = temp.sort((firstElement, secondElement) =>
        secondElement[type].localeCompare(firstElement[type])
      );
      dispatch(
        updateRedux({ key: "userCampaignContactDetails", value: [...temp] })
      );
    } else {
      temp = temp.sort((firstElement, secondElement) =>
        firstElement[type].localeCompare(secondElement[type])
      );
      dispatch(
        updateRedux({ key: "userCampaignContactDetails", value: [...temp] })
      );
    }
  };

  const onAddHandler = (value) => {
    setUserId([...userId, value._id]);
    setSelectedUsers([...selectedUsers, value]);
  };

  const onRemoveHandler = (value) => {
    setUserId(userId.filter((item) => item != value._id));
    setSelectedUsers(selectedUsers?.filter((item) => item._id != value._id));
  };

  const onAddAllHandler = () => {
    let id = [];
    fullContactList?.forEach((ele) => {
      id.push(ele._id);
    });
    setUserId([...id]);
    setSelectedUsers([...fullContactList]);
  };

  const onRemoveAllHandler = () => {
    setUserId([]);
    setSelectedUsers([]);
  };

  const onAddFinalHandler = (value) => {
    setFinalUserId([...finalUserId, value._id]);
    setFinalSelectedUsers([...finalSelectedUsers, value]);
  };

  const onRemoveFinalHandler = (value) => {
    setFinalUserId(finalUserId.filter((item) => item != value._id));
    setFinalSelectedUsers(
      finalSelectedUsers?.filter((item) => item._id != value._id)
    );
    setFinalUsersDetails(
      finalUsersDetails?.filter(
        (item) =>
          item._id !=
          value._id
      )
    );
  };

  const onAddAllFinalHandler = () => {
    let id = [];
    finalUsers?.forEach((ele) => {
      id.push(ele._id);
    });
    setFinalUserId([...id]);
    setFinalSelectedUsers([...finalUsers]);
  };

  const onRemoveAllFinalHandler = () => {
    setFinalUserId([]);
    setFinalSelectedUsers([]);
    setFinalUsersDetails([])
    setFinalUsers([]);
  };

  const onAddGroupHandler = (value) => {
    setUserContactId([...userContactId, value._id]);
    setSelectedGroupUsers([...selectedGroupUsers, value]);
  };

  const onRemoveGroupHandler = (value) => {
    setUserContactId(userContactId.filter((item) => item != value._id));
    setSelectedGroupUsers(
      selectedGroupUsers?.filter((item) => item._id != value._id)
    );
    setUserGroupContactDetail(
      userGroupContactDetail?.filter((item) => item._id != value._id)
    );
    if (userGroupContactDetail?.length == 1) {
      setGroup("")
    }
  };

  const onAddAllGroupHandler = () => {
    let id = [];
    fullGroupData?.forEach((ele) => {
      id.push(ele._id);
    });
    setUserContactId([...id]);
    setSelectedGroupUsers([...fullGroupData]);
  };

  const onRemoveAllGroupHandler = () => {
    setUserContactId([]);
    setSelectedGroupUsers([]);
    setUserGroupContactDetail([])
    setGroup("");
  };

  const onAddContactHandler = (value) => {
    setCSVSelected([...csvSelected, value]);
  };

  const onRemoveContactHandler = (value) => {
    setCSVSelected(csvSelected.filter((item) => item != value));
    setContact(
      contact.filter(
        (item) =>
          item != value
      )
    )
  };

  const onAddAllContactHandler = () => {
    setCSVSelected([...fullContact]);
  };

  const onRemoveAllContactHandler = () => {
    setCSVSelected([]);
    setContact([])
  };

  return (
    <>
      <Modal
        show={modalshow}
        centered
        className="removeModfal"
        onHide={handleClose}
      >
        <Modal.Body>هل تريد أن تحذف؟</Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-modalClose" onClick={handleClose}>
            اغلاق
          </Button>
          <Button
            className="btn btn-modalSubmit"
            onClick={() => onDeleteSelectedContactUser()}
          >
            حذف
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalGroupshow}
        centered
        className="removeModfal"
        onHide={handleGroupClose}
      >
        <Modal.Body>حذف مجموعة جهات الاتصال </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-modalClose" onClick={handleGroupClose}>
            اغلاق
          </Button>
          <Button
            className="btn btn-modalSubmit"
            onClick={() => onDeleteSelectedContactGroupUser()}
          >
            حذف
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={csvModalShow}
        centered
        className="removeModfal"
        onHide={handleCsvClose}
      >
        <Modal.Body>حذف جهات اتصال CSV </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-modalClose" onClick={handleCsvClose}>
            اغلاق
          </Button>
          <Button
            className="btn btn-modalSubmit"
            onClick={() => onDeleteSelectedCsvUser()}
          >
            حذف
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
      <Header />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>إدارة الرسائل</h1>
          <p></p>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="form__wizard">
                <div className="card">
                  <div className="card-body">
                    <div className="default__title">
                      <h2>رسالة نصية جديدة </h2>
                    </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                      <Row>
                        <div className="col-md-12">
                          <div className="row">
                            <Col lg={4}>
                              <div className="right__bar">
                                <div className="progess__bar">
                                  <ProgressBar
                                    now={progess}
                                    variant="primary"
                                  />
                                </div>
                                <Nav
                                  variant="pills"
                                  className="flex-column right__pagination"
                                >
                                  <Nav.Item>
                                    <Nav.Link
                                      onClick={() => setCounter(1)}
                                      className={`${counter == 1 ? "counterActive" : ""
                                        }`}
                                    >
                                      1
                                    </Nav.Link>
                                    <p>عنوان الرسالة</p>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link
                                      onClick={() =>
                                        sideCounter1Changehandler()
                                      }
                                      className={`${counter == 2 ? "counterActive" : ""
                                        }`}
                                    >
                                      2
                                    </Nav.Link>
                                    <p>إختيار جهة الاتصال</p>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link
                                      onClick={() =>
                                        sideCounter2Changehandler()
                                      }
                                      className={`${counter == 3 ? "counterActive" : ""
                                        }`}
                                    >
                                      3
                                    </Nav.Link>
                                    <p>إرسال الرسالة النصية </p>
                                  </Nav.Item>
                                </Nav>
                              </div>
                            </Col>
                            <Col lg={8}>
                              <Tab.Content id={`${counter}-eventkey`}>
                                {counter == 1 ? (
                                  <div className="row g-3 defaultForm__Style">
                                    <div className="col-md-12">
                                      <div className="row ">
                                        <div className="col-md-3">
                                          <label className="col-form-label">
                                            عنوان الرسالة النصية
                                            <span className="mandatory">*</span>
                                          </label>
                                        </div>
                                        <div className="col-md-9">
                                          <input
                                            type="text"
                                            value={details}
                                            className="form-control"
                                            placeholder=" العنوان لغرض الاستعلام"
                                            onChange={(e) =>
                                              setDetails(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="row ">
                                        <div className="col-md-3">
                                          <label className="col-form-label">
                                            إختيار نموذج الرسالة
                                          </label>
                                        </div>
                                        <div className="col-md-9">
                                          <div className="form-group">
                                            <div className="custom-select without-label">
                                              <select
                                                className="form-control form-select"
                                                aria-label="يختار"
                                                value={template}
                                                onChange={(e) =>
                                                  templateChangehandler(e)
                                                }
                                              >
                                                <option
                                                  selected
                                                  value={"Select template"}
                                                >
                                                  إختيار النموذج
                                                </option>
                                                {templateList?.map((ele, i) => {
                                                  return (
                                                    <option value={ele._id}>
                                                      {ele.title}
                                                    </option>
                                                  );
                                                })}
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="row ">
                                        <div className="col-md-3">
                                          <label className="col-form-label">
                                            الرسالة
                                            <span className="mandatory">*</span>
                                          </label>
                                        </div>
                                        <div className="col-md-9">
                                          <textarea
                                            className="form-control"
                                            value={message}
                                            rows={3}
                                            onChange={(e) =>
                                              //setMessage(e.target.value)
                                              changeMessage(e.target.value)
                                            }
                                          ></textarea>
                                          <p>{countMessage}</p>
                                          <p className="text-right">
                                            سيتم تسليم نص الرسالة ، يمكنك إرسال
                                            رسالة تصل إلى 160 حرفًا و 70 من
                                            الرموز النصية
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : counter == 2 ? (
                                  <div className="row g-3 defaultForm__Style wizard2">
                                    <h4>إختيار جهة الاتصال</h4>
                                    <h5>* طريقة الإرسال:</h5>
                                    <div className="col-md-12">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <Form.Group
                                            controlId="formSelectMultiple"
                                            className="mb-3"
                                          >
                                            <Form.Label>الرتبة</Form.Label>
                                            <MultiSelect
                                              options={rankList?.map((ele) => ({
                                                label: ele.rank,
                                                value: ele.rank,
                                                _id: ele._id
                                              }))}
                                              value={rank}
                                              onChange={(e) => setRank(e)}
                                              className="basic-multi-select"
                                              labelledBy={"Select"}
                                              style={{ direction: "rtl" }}
                                            />
                                            {/* <Select
                                              isMulti
                                              name="rank"
                                              options={rankList}
                                              hideSelectedOptions={false}
                                              value={rank}
                                              getOptionLabel={(option) =>
                                                option.rank
                                              }
                                              getOptionValue={(option) =>
                                                option._id
                                              }
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                              placeholder="إختيار"
                                              theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                  ...theme.colors,
                                                  primary25: "#31D68F",
                                                  primary: "#0D7B58",
                                                },
                                              })}
                                              onChange={(e) => setRank(e)}
                                            /> */}
                                          </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                          <Form.Group
                                            controlId="formSelectMultiple"
                                            className="mb-3"
                                          >
                                            <Form.Label>القسم</Form.Label>
                                            <MultiSelect
                                              options={units?.map((ele) => ({
                                                label: ele.unit_name,
                                                value: ele.unit_name,
                                                _id: ele._id
                                              }))}
                                              value={unit}
                                              onChange={(e) => onUnitSelecthandler(e)}
                                              className="basic-multi-select"
                                              labelledBy={"Select"}
                                              style={{ direction: "rtl" }}
                                            />
                                            {/* <Select
                                              isMulti
                                              name="unit"
                                              options={units}
                                              hideSelectedOptions={false}
                                              value={unit}
                                              getOptionLabel={(option) =>
                                                option.unit_name
                                              }
                                              getOptionValue={(option) =>
                                                option._id
                                              }
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                              placeholder="إختيار"
                                              theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                  ...theme.colors,
                                                  primary25: "#31D68F",
                                                  primary: "#0D7B58",
                                                },
                                              })}
                                              onChange={(e) =>
                                                onUnitSelecthandler(e)
                                              }
                                            /> */}
                                          </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                          <Form.Group
                                            controlId="formSelectMultiple"
                                            className="mb-3"
                                          >
                                            <Form.Label>الشعبة</Form.Label>
                                            <MultiSelect
                                              options={subUnitList?.map((ele) => ({
                                                label: ele.subunit_name,
                                                value: ele.subunit_name,
                                                _id: ele._id
                                              }))}
                                              value={subunit}
                                              onChange={(e) => onSubUnitSelecthandler(e)}
                                              className="basic-multi-select"
                                              labelledBy={"Select"}
                                              style={{ direction: "rtl" }}
                                            />
                                            {/* <Select
                                              isMulti
                                              name="subunit"
                                              isDisabled={
                                                unit?.length ? false : true
                                              }
                                              options={subUnitList}
                                              hideSelectedOptions={false}
                                              value={subunit}
                                              getOptionLabel={(option) =>
                                                option.subunit_name
                                              }
                                              getOptionValue={(option) =>
                                                option._id
                                              }
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                              placeholder="إختيار"
                                              theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                  ...theme.colors,
                                                  primary25: "#31D68F",
                                                  primary: "#0D7B58",
                                                },
                                              })}
                                              onChange={(e) =>
                                                onSubUnitSelecthandler(e)
                                              }
                                            /> */}
                                          </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                          <Form.Group
                                            controlId="formSelectMultiple"
                                            className="mb-3"
                                          >
                                            <Form.Label>الفئة</Form.Label>
                                            <MultiSelect
                                              options={workShiftList?.map((ele) => ({
                                                label: ele.workshift_name,
                                                value: ele.workshift_name,
                                                _id: ele._id
                                              }))}
                                              value={workshift}
                                              onChange={(e) => setWorkShift(e)}
                                              className="basic-multi-select"
                                              labelledBy={"Select"}
                                              style={{ direction: "rtl" }}
                                            />
                                            {/* <Select
                                              isMulti
                                              name="workshift"
                                              isDisabled={
                                                subunit?.length ? false : true
                                              }
                                              options={workShiftList}
                                              hideSelectedOptions={false}
                                              value={workshift}
                                              getOptionLabel={(option) =>
                                                option.workshift_name
                                              }
                                              getOptionValue={(option) =>
                                                option._id
                                              }
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                              placeholder="إختيار"
                                              theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                  ...theme.colors,
                                                  primary25: "#31D68F",
                                                  primary: "#0D7B58",
                                                },
                                              })}
                                              onChange={(e) => setWorkShift(e)}
                                            /> */}
                                          </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                          <Form.Group
                                            className="mb-3"
                                            controlId=""
                                          >
                                            <div className="custom-select">
                                              <Form.Label>الجنس</Form.Label>
                                              <select
                                                className="form-control form-select"
                                                value={gender}
                                                placeholder="إختيار"
                                                aria-label="Gender"
                                                onChange={(e) => {
                                                  if (
                                                    e.target.value ==
                                                    "Select Gender"
                                                  ) {
                                                    setGender("");
                                                  } else {
                                                    setGender(e.target.value);
                                                  }
                                                }}
                                              >
                                                <option
                                                  selected
                                                  value={"Select Gender"}
                                                >
                                                  إختيار الجنس{" "}
                                                </option>
                                                <option value="ذكر">ذكر</option>
                                                <option value="انثى">
                                                  انثى
                                                </option>
                                                {/* <option value="male">
                                                  Male
                                                </option>
                                                <option value="female">
                                                  Female
                                                </option> */}
                                              </select>
                                            </div>
                                          </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <Form.Label>
                                              اسم المستخدم
                                            </Form.Label>
                                            <div className="keyword__search">
                                              <div className="form-group w-100">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="اسم المستخدم"
                                                  value={username}
                                                  onChange={(e) =>
                                                    setUsername(e.target.value)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <Form.Label>
                                              الرقم الوظيفي
                                            </Form.Label>
                                            <div className="keyword__search">
                                              <div className="form-group w-100">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="الرقم الوظيفي"
                                                  value={employeeId}
                                                  onChange={(e) =>
                                                    setEmployeeId(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <Form.Label>
                                              الكلمة المعرفة
                                            </Form.Label>
                                            <div className="keyword__search">
                                              <div className="form-group w-100">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="الكلمة المعرفة"
                                                  value={keyword}
                                                  onChange={(e) =>
                                                    setKeyword(e.target.value)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <button
                                            className="btn btn-secondary ml-2"
                                            onClick={() => onClearHandler()}
                                          >
                                            مسح
                                          </button>
                                          <button
                                            className="btn btn-primary"
                                            onClick={(e) =>
                                              onSearchHandler(page, e)
                                            }
                                          >
                                            البحث
                                          </button>
                                        </div>
                                      </div>
                                      <div className="row">
                                        {usersDetails?.length ? (
                                          <Table
                                            striped
                                            bordered
                                            hover
                                            responsive
                                          >
                                            <thead>
                                              <tr>
                                                <th style={{ display: "flex" }}>
                                                  <Form.Check
                                                    type="checkbox"
                                                    id={`valueOne`}
                                                    style={{
                                                      marginLeft: "5px",
                                                    }}
                                                    checked={
                                                      fullContactList?.length ==
                                                      userId?.length
                                                    }
                                                    onClick={(e) =>
                                                      selectAllUserHandler(e)
                                                    }
                                                  />
                                                  تحديد الكل
                                                </th>
                                                <th
                                                  onClick={() =>
                                                    tableSort("name")
                                                  }
                                                >
                                                  {sortColumn == "name" && (
                                                    <i
                                                      className={`fa fa-sort-${sortAscending == true
                                                        ? "up"
                                                        : "down"
                                                        } mouse pl-5 filter__arrow`}
                                                    />
                                                  )}
                                                  الاسم
                                                </th>
                                                <th>رقم الهاتف</th>
                                                {/* <th>
                                                  {fullContactList?.length ==
                                                  userId?.length ? (
                                                    <button
                                                      className="btn btn-sm btn-danger btn-table"
                                                      onClick={() =>
                                                        onRemoveAllHandler()
                                                      }
                                                    >
                                                      Remove All
                                                    </button>
                                                  ) : (
                                                    <button
                                                      className="btn btn-sm btn-primary btn-table"
                                                      onClick={() =>
                                                        onAddAllHandler()
                                                      }
                                                    >
                                                      Select All
                                                    </button>
                                                  )}
                                                </th> */}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {usersDetails?.map((ele, i) => {
                                                return (
                                                  <tr>
                                                    <td>
                                                      <Form.Check
                                                        type="checkbox"
                                                        id={`valueOne`}
                                                        checked={
                                                          userId.includes(
                                                            ele._id
                                                          )
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={(e) => {
                                                          if (
                                                            e.target.checked
                                                          ) {
                                                            setUserId([
                                                              ...userId,
                                                              ele._id,
                                                            ]);
                                                            setSelectedUsers([
                                                              ...selectedUsers,
                                                              ele,
                                                            ]);
                                                          } else {
                                                            setUserId(
                                                              userId.filter(
                                                                (item) =>
                                                                  item !=
                                                                  ele._id
                                                              )
                                                            );
                                                            setSelectedUsers(
                                                              selectedUsers?.filter(
                                                                (item) =>
                                                                  item._id !=
                                                                  ele._id
                                                              )
                                                            );
                                                          }
                                                        }}
                                                      />
                                                    </td>
                                                    <td>{ele.name}</td>
                                                    <td>{ele.phone}</td>
                                                    {/* <td>
                                                      <button
                                                        className={`btn btn-sm btn-table ${
                                                          userId?.includes(
                                                            ele._id
                                                          )
                                                            ? "btn-danger"
                                                            : "btn-primary"
                                                        }`}
                                                        onClick={() =>
                                                          userId?.includes(
                                                            ele._id
                                                          )
                                                            ? onRemoveHandler(
                                                                ele
                                                              )
                                                            : onAddHandler(ele)
                                                        }
                                                      >
                                                        {userId?.includes(
                                                          ele._id
                                                        )
                                                          ? "Remove"
                                                          : "Select"}
                                                      </button>
                                                    </td> */}
                                                  </tr>
                                                );
                                              })}
                                            </tbody>
                                          </Table>
                                        ) : null}
                                      </div>
                                      <div className="paginationStyle">
                                        {pageCount > 1 ? (
                                          <ReactPaginate
                                            previousLabel={<>❮</>}
                                            nextLabel={<>❯</>}
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            // breakLabel = "..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            pageCount={pageCount}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={5}
                                            initialPage={page}
                                            onPageChange={(e) =>
                                              handlePageChange(e)
                                            }
                                            containerClassName="pagination"
                                            activeClassName="pageActive"
                                          />
                                        ) : null}
                                      </div>
                                      <hr />
                                      <Row>
                                        <Col lg={4}>
                                          <button
                                            className="btn btn-primary w-100"
                                            style={{ padding: "10px 30px" }}
                                            onClick={() => onMoveHandler()}
                                          >
                                            اضافة
                                          </button>
                                        </Col>
                                      </Row>
                                      {finalUsersDetails?.length ? (
                                        <Table
                                          striped
                                          bordered
                                          hover
                                          className="mt-5"
                                        >
                                          <thead>
                                            <tr>
                                              <th style={{ display: "flex" }}>
                                                <Form.Check
                                                  type="checkbox"
                                                  id={`valueOne`}
                                                  style={{ marginLeft: "5px" }}
                                                  checked={
                                                    // finalUsers?.length ==
                                                    finalUserId?.length
                                                  }
                                                  onClick={(e) =>
                                                    selectAllFinalUserHandler(e)
                                                  }
                                                />
                                                تحديد الكل
                                              </th>
                                              <th> الاسم</th>
                                              <th> رقم الهاتف</th>
                                              <th>
                                                {
                                                  // finalUsers?.length ==
                                                  finalUserId?.length ? (
                                                    <button
                                                      className="btn btn-sm btn-danger btn-table"
                                                      onClick={() =>
                                                        onRemoveAllFinalHandler()
                                                      }
                                                    >
                                                      Remove All
                                                    </button>
                                                  ) : (
                                                    <button
                                                      className="btn btn-sm btn-primary btn-table"
                                                      onClick={() =>
                                                        onAddAllFinalHandler()
                                                      }
                                                    >
                                                      Select All
                                                    </button>
                                                  )}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {finalUsersDetails?.map(
                                              (ele, i) => {
                                                return (
                                                  <tr>
                                                    <td>
                                                      <Form.Check
                                                        type="checkbox"
                                                        id={`valueOne`}
                                                        checked={
                                                          finalUserId.includes(
                                                            ele._id
                                                          )
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={(e) => {
                                                          if (
                                                            e.target.checked
                                                          ) {
                                                            setFinalUserId([
                                                              ...finalUserId,
                                                              ele._id,
                                                            ]);
                                                            setFinalSelectedUsers(
                                                              [
                                                                ...finalSelectedUsers,
                                                                ele,
                                                              ]
                                                            );
                                                          } else {
                                                            setFinalUserId(
                                                              finalUserId.filter(
                                                                (item) =>
                                                                  item !=
                                                                  ele._id
                                                              )
                                                            );
                                                            setFinalSelectedUsers(
                                                              finalSelectedUsers?.filter(
                                                                (item) =>
                                                                  item._id !=
                                                                  ele._id
                                                              )
                                                            );
                                                            setFinalUsersDetails(
                                                              finalUsersDetails?.filter(
                                                                (item) =>
                                                                  item._id !=
                                                                  ele._id
                                                              )
                                                            );
                                                          }
                                                        }}
                                                      />
                                                    </td>
                                                    <td>{ele.name}</td>
                                                    <td>{ele.phone}</td>
                                                    <td>
                                                      <button
                                                        className={`btn btn-sm btn-table ${finalUserId?.includes(
                                                          ele._id
                                                        )
                                                          ? "btn-danger"
                                                          : "btn-primary"
                                                          }`}
                                                        onClick={() =>
                                                          finalUserId?.includes(
                                                            ele._id
                                                          )
                                                            ? onRemoveFinalHandler(
                                                              ele
                                                            )
                                                            : onAddFinalHandler(
                                                              ele
                                                            )
                                                        }
                                                      >
                                                        {finalUserId?.includes(
                                                          ele._id
                                                        )
                                                          ? "حذف"
                                                          : "Select"}
                                                      </button>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </tbody>
                                        </Table>
                                      ) : null}
                                      <div className="paginationStyle">
                                        {finalPageCount > 1 ? (
                                          <ReactPaginate
                                            previousLabel={<>❮</>}
                                            nextLabel={<>❯</>}
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            // breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            pageCount={finalPageCount}
                                            initialPage={finalPage}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={5}
                                            onPageChange={(e) =>
                                              handleFinalPageChange(e)
                                            }
                                            containerClassName="pagination"
                                            activeClassName="pageActive"
                                            forcePage={page}
                                          />
                                        ) : null}
                                      </div>
                                      <hr />
                                      <div className="row mt-4">
                                        <div className="col-md-12">
                                          <Form.Group
                                            controlId="formSelectMultiple"
                                            className="mb-3"
                                          >
                                            <Form.Label>المجموعة </Form.Label>
                                            <Select
                                              isMulti
                                              name="contact"
                                              options={contactList}
                                              hideSelectedOptions={false}
                                              value={group}
                                              getOptionLabel={(option) =>
                                                option.name
                                              }
                                              getOptionValue={(option) =>
                                                option._id
                                              }
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                              placeholder="إختيار"
                                              onChange={(e) =>
                                                selectGroupHandler(e, groupPage)
                                              }
                                            />
                                          </Form.Group>
                                        </div>
                                      </div>
                                      <div className="row">
                                        {userGroupContactDetail?.length ? (
                                          <Table striped bordered hover>
                                            <thead>
                                              <tr>
                                                <th style={{ display: "flex" }}>
                                                  <Form.Check
                                                    type="checkbox"
                                                    id={`valueOne`}
                                                    style={{
                                                      marginLeft: "5px",
                                                    }}
                                                    checked={
                                                      fullGroupData?.length
                                                      // == userContactId?.length
                                                    }
                                                    onClick={(e) =>
                                                      selectAllUserContactHandler(
                                                        e
                                                      )
                                                    }
                                                  />
                                                  تحديد الكل
                                                </th>
                                                <th
                                                  style={{ minWidth: "100px" }}
                                                >
                                                  {" "}
                                                  المجموعة
                                                </th>
                                                <th
                                                  style={{ minWidth: "100px" }}
                                                >
                                                  {" "}
                                                  الاسم
                                                </th>
                                                <th
                                                  style={{ minWidth: "100px" }}
                                                >
                                                  {" "}
                                                  رقم الهاتف
                                                </th>
                                                <th>
                                                  {fullGroupData?.length
                                                    //  == userContactId?.length 
                                                    ? (
                                                      <button
                                                        className="btn btn-sm btn-danger btn-table"
                                                        onClick={() =>
                                                          onRemoveAllGroupHandler()
                                                        }
                                                      >
                                                        Remove All
                                                      </button>
                                                    ) : (
                                                      <button
                                                        className="btn btn-sm btn-primary btn-table"
                                                        onClick={() =>
                                                          onAddAllGroupHandler()
                                                        }
                                                      >
                                                        Select All
                                                      </button>
                                                    )}
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {userGroupContactDetail?.map(
                                                (ele, i) => {
                                                  return (
                                                    <tr>
                                                      <td
                                                        style={{
                                                          minWidth: "100px",
                                                        }}
                                                      >
                                                        <Form.Check
                                                          type="checkbox"
                                                          id={`valueOne`}
                                                          checked={
                                                            userContactId.includes(
                                                              ele._id
                                                            )
                                                              ? true
                                                              : false
                                                          }
                                                          onClick={(e) => {
                                                            if (
                                                              e.target.checked
                                                            ) {
                                                              setUserContactId([
                                                                ...userContactId,
                                                                ele._id,
                                                              ]);
                                                              setSelectedGroupUsers(
                                                                [
                                                                  ...selectedGroupUsers,
                                                                  ele,
                                                                ]
                                                              );
                                                            } else {
                                                              setUserContactId(
                                                                userContactId.filter(
                                                                  (item) =>
                                                                    item !=
                                                                    ele._id
                                                                )
                                                              );
                                                              setSelectedGroupUsers(
                                                                selectedGroupUsers?.filter(
                                                                  (item) =>
                                                                    item._id !=
                                                                    ele._id
                                                                )
                                                              );
                                                              setUserGroupContactDetail(
                                                                userGroupContactDetail?.filter(
                                                                  (item) =>
                                                                    item._id !=
                                                                    ele._id
                                                                ))
                                                              if (userGroupContactDetail?.length == 1) {
                                                                setGroup("")
                                                              }
                                                            }
                                                          }}
                                                        />
                                                      </td>
                                                      <td
                                                        style={{
                                                          minWidth: "100px",
                                                        }}
                                                      >
                                                        {ele.group}
                                                      </td>
                                                      <td
                                                        style={{
                                                          minWidth: "100px",
                                                        }}
                                                      >
                                                        {ele.name}
                                                      </td>
                                                      <td
                                                        style={{
                                                          minWidth: "100px",
                                                        }}
                                                      >
                                                        {ele.phone}
                                                      </td>
                                                      <td>
                                                        <button
                                                          className={`btn btn-sm btn-table ${userContactId?.includes(
                                                            ele._id
                                                          )
                                                            ? "btn-danger"
                                                            : "btn-primary"
                                                            }`}
                                                          onClick={() =>
                                                            userContactId?.includes(
                                                              ele._id
                                                            )
                                                              ? onRemoveGroupHandler(
                                                                ele
                                                              )
                                                              : onAddGroupHandler(
                                                                ele
                                                              )
                                                          }
                                                        >
                                                          {userContactId?.includes(
                                                            ele._id
                                                          )
                                                            ? "حذف"
                                                            : "Select"}
                                                        </button>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </tbody>
                                          </Table>
                                        ) : null}
                                      </div>
                                      <div className="paginationStyle">
                                        {groupCount > 1 ? (
                                          <ReactPaginate
                                            previousLabel={<>❮</>}
                                            nextLabel={<>❯</>}
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            // breakLabel = "..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            pageCount={groupCount}
                                            initialPage={groupPage}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={5}
                                            onPageChange={(e) =>
                                              handleGroupPageChange(e)
                                            }
                                            containerClassName="pagination"
                                            activeClassName="pageActive"
                                          />
                                        ) : null}
                                      </div>
                                      <div className="row">
                                        {groupNumberList?.map((ele) => {
                                          return (
                                            <div className="col-md-4">
                                              <div className="tags widthFit">
                                                <img
                                                  src="assets/img/icons/close.svg"
                                                  alt=""
                                                  onClick={() =>
                                                    onDeleteGroupNumberhandler(
                                                      ele
                                                    )
                                                  }
                                                />
                                                <p>{`${ele.phone}(${ele.group})`}</p>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                      <Row className="align-items-center mb-4 mt-4">
                                        <Form.Label>تحميل ملف CSV</Form.Label>
                                        <Col
                                          md={5}
                                          className="d-flex flexColumn"
                                        >
                                          <input
                                            type="file"
                                            name="uploadfile"
                                            id="img"
                                            onChange={(e) => onUploadHandler(e)}
                                            style={{ display: "none" }}
                                          />
                                          <label
                                            htmlFor="img"
                                            className="form-control mb-0"
                                          >
                                            {csv?.length
                                              ? csv[0].name
                                              : "انقر هنا للتحميل"}
                                          </label>
                                        </Col>
                                        <Col lg={4} className="p-0">
                                          <button
                                            className="btn btn-primary w-100"
                                            onClick={() =>
                                              onFileUploadHandler()
                                            }
                                          >
                                            تحميل ملف CSV
                                          </button>
                                        </Col>
                                        <Col lg={3} className="p-0">
                                          <button
                                            className="btn btn-secondary"
                                            style={{ marginRight: "10px" }}
                                            onClick={() => onDownloadSample()}
                                          >
                                            تنزيل نموذج CSV
                                          </button>
                                        </Col>
                                      </Row>
                                      <Row>
                                        {contact?.length ? (
                                          <Table borderless>
                                            <thead>
                                              <tr>
                                                <th style={{ display: "flex" }}>
                                                  <Form.Check
                                                    type="checkbox"
                                                    id={`valueOne`}
                                                    style={{
                                                      marginLeft: "5px",
                                                    }}
                                                    checked={
                                                      fullContact?.length ==
                                                      csvSelected?.length
                                                    }
                                                    onClick={(e) =>
                                                      selectAllContactHandler(e)
                                                    }
                                                  />
                                                  تحديد الكل
                                                </th>
                                                <th> رقم الهاتف</th>
                                                <th>
                                                  {fullContact?.length ==
                                                    csvSelected?.length ? (
                                                    <button
                                                      className="btn btn-sm btn-danger btn-table"
                                                      onClick={() =>
                                                        onRemoveAllContactHandler()
                                                      }
                                                    >
                                                      حذف الكل 
                                                    </button>
                                                  ) : (
                                                    <button
                                                      className="btn btn-sm btn-primary btn-table"
                                                      onClick={() =>
                                                        onAddAllContactHandler()
                                                      }
                                                    >
                                                      Select All
                                                    </button>
                                                  )}
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {contact?.map((ele, i) => {
                                                return (
                                                  <tr>
                                                    <td
                                                      style={{
                                                        minWidth: "100px",
                                                      }}
                                                    >
                                                      <Form.Check
                                                        type="checkbox"
                                                        id={`valueOne`}
                                                        checked={
                                                          csvSelected.includes(
                                                            ele
                                                          )
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={(e) => {
                                                          if (
                                                            e.target.checked
                                                          ) {
                                                            setCSVSelected([
                                                              ...csvSelected,
                                                              ele,
                                                            ]);
                                                          } else {
                                                            setCSVSelected(
                                                              csvSelected.filter(
                                                                (item) =>
                                                                  item != ele
                                                              )
                                                            );
                                                            setContact(
                                                              contact.filter(
                                                                (item) =>
                                                                  item != ele
                                                              )
                                                            )
                                                          }
                                                        }}
                                                      />
                                                    </td>
                                                    <td>{ele}</td>
                                                    <td>
                                                      <button
                                                        className={`btn btn-sm btn-table ${csvSelected?.includes(
                                                          ele
                                                        )
                                                          ? "btn-danger"
                                                          : "btn-primary"
                                                          }`}
                                                        onClick={() =>
                                                          csvSelected?.includes(
                                                            ele
                                                          )
                                                            ? onRemoveContactHandler(
                                                              ele
                                                            )
                                                            : onAddContactHandler(
                                                              ele
                                                            )
                                                        }
                                                      >
                                                        {csvSelected?.includes(
                                                          ele
                                                        )
                                                          ? "حذف"
                                                          : "Select"}
                                                      </button>
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                            </tbody>
                                          </Table>
                                        ) : null}
                                      </Row>
                                      <div className="paginationStyle">
                                        {csvCount > 1 ? (
                                          <ReactPaginate
                                            previousLabel={<>❮</>}
                                            nextLabel={<>❯</>}
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            // breakLabel = "..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            pageCount={csvCount}
                                            initialPage={csvPage}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={5}
                                            onPageChange={(e) =>
                                              handleCsvPageChange(e)
                                            }
                                            containerClassName="pagination"
                                            activeClassName="pageActive"
                                          />
                                        ) : null}
                                      </div>
                                      <hr />
                                      <div className="row mt-4">
                                        <div className="col-md-8">
                                          <div className="form-group">
                                            <Form.Label>إضافة يدوية</Form.Label>
                                            <div className="d-flex align-items-center">
                                              <input
                                                type="text"
                                                value={phoneNumber}
                                                onChange={(e) =>
                                                  setPhoneNumber(e.target.value)
                                                }
                                                onKeyDown={(e) =>
                                                  onNumberChangeHandler(e)
                                                }
                                                className="form-control"
                                                placeholder="إضافة يدوية"
                                              />
                                            </div>
                                            {err && (
                                              <p className="text-danger">
                                                {err.phone}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        {phoneNumberArray?.map((ele) => {
                                          return (
                                            <div className="col-md-3">
                                              <div className="tags">
                                                <img
                                                  src="assets/img/icons/close.svg"
                                                  alt=""
                                                  onClick={() =>
                                                    onDeleteNumberhandler(ele)
                                                  }
                                                />
                                                <p>{ele}</p>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                      <div className="row">
                                        <div className="col-md-5">
                                          <div className="form-check form-switch">
                                            <label
                                              className="form-check-label"
                                              htmlFor="flexSwitchCheckChecked"
                                            >
                                              {" "}
                                              جدولة الرسالة
                                            </label>
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              role="switch"
                                              id="flexSwitchCheckChecked"
                                              checked={schedule}
                                              onChange={(e) =>
                                                setSchedule(e.target.checked)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div
                                            className="form-check form-switch"
                                            style={{ opacity: "0.8" }}
                                          >
                                            <label
                                              className="form-check-label"
                                              htmlFor="flexSwitchCheckChecked"
                                            >
                                              check this to schedule campaign
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        {schedule ? (
                                          <DatePicker
                                            dateFormat=" MMM d, yyyy hh:mm aa"
                                            showTimeSelect
                                            selected={new Date(scheduledDate)}
                                            minDate={new Date()}
                                            onChange={(date) =>
                                              onScheduleHandler(date)
                                            }
                                          />
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                ) : counter == 3 ? (
                                  <div className="row g-3 defaultForm__Style wizard2">
                                    <h4>تأكيد الرسالة</h4>
                                    <div className="col-md-12">
                                      <div className="row">
                                        <label className="col-form-label">
                                          عنوان الرسالة
                                        </label>
                                        <p>{details}</p>
                                        <div className="editDivImgStyle">
                                          <img
                                            src="assets/img/icons/table-edit.png"
                                            alt=""
                                            onClick={() => setCounter(1)}
                                          />
                                        </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                        <label className="col-form-label">
                                          محتوى الرسالة{" "}
                                        </label>
                                        <p>{message}</p>
                                        <div className="editDivImgStyle">
                                          <img
                                            src="assets/img/icons/table-edit.png"
                                            alt=""
                                            onClick={() => setCounter(1)}
                                          />
                                        </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                        <p>جهات الاتصال</p>
                                        <div className="details__table">
                                          {selectedUserDetails?.length ? (
                                            <Table striped bordered hover>
                                              <thead>
                                                <tr>
                                                  <th> الاسم</th>
                                                  <th> رقم الهاتف</th>
                                                  <th>اجراء</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {selectedUserDetails?.map(
                                                  (ele, i) => {
                                                    return (
                                                      <tr>
                                                        <td>{ele.name}</td>
                                                        <td>{ele.phone}</td>
                                                        <td>
                                                          <button
                                                            className="btn btn-sm"
                                                            onClick={(e) =>
                                                              deleteUserData(
                                                                ele._id,
                                                                e
                                                              )
                                                            }
                                                          >
                                                            <img
                                                              src="assets/img/icons/table-delete.png"
                                                              alt=""
                                                            />
                                                          </button>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                            </Table>
                                          ) : null}
                                        </div>
                                        <div className="paginationStyle">
                                          {selectedUserPageCount > 1 ? (
                                            <ReactPaginate
                                              previousLabel={<>❮</>}
                                              nextLabel={<>❯</>}
                                              pageClassName="page-item"
                                              pageLinkClassName="page-link"
                                              previousClassName="page-item"
                                              previousLinkClassName="page-link"
                                              nextClassName="page-item"
                                              nextLinkClassName="page-link"
                                              // breakLabel = "..."
                                              breakClassName="page-item"
                                              breakLinkClassName="page-link"
                                              pageCount={selectedUserPageCount}
                                              initialPage={selectedUserPage}
                                              marginPagesDisplayed={1}
                                              pageRangeDisplayed={5}
                                              onPageChange={(e) =>
                                                handleSelectedUserPageChange(e)
                                              }
                                              containerClassName="pagination"
                                              activeClassName="pageActive"
                                              forcePage={selectedUserPage}
                                            />
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="row">
                                        <p>مجموعة جهات الاتصال</p>
                                        <div className="details__table">
                                          {selectedGroupDetails?.length ? (
                                            <Table striped bordered hover>
                                              <thead>
                                                <tr>
                                                  <th> المجموعة</th>
                                                  <th> الاسم</th>
                                                  <th> رقم الهاتف</th>
                                                  <th>اجراء</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {selectedGroupDetails?.map(
                                                  (ele, i) => {
                                                    return (
                                                      <tr>
                                                        <td>{ele.group}</td>
                                                        <td>{ele.name}</td>
                                                        <td>{ele.phone}</td>
                                                        <td>
                                                          <button
                                                            className="btn btn-sm"
                                                            onClick={(e) =>
                                                              deleteUserGroupData(
                                                                ele._id,
                                                                e
                                                              )
                                                            }
                                                          >
                                                            <img
                                                              src="assets/img/icons/table-delete.png"
                                                              alt=""
                                                            />
                                                          </button>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                            </Table>
                                          ) : null}
                                        </div>
                                        <div className="paginationStyle">
                                          {selectedGroupPageCount > 1 ? (
                                            <ReactPaginate
                                              previousLabel={<>❮</>}
                                              nextLabel={<>❯</>}
                                              pageClassName="page-item"
                                              pageLinkClassName="page-link"
                                              previousClassName="page-item"
                                              previousLinkClassName="page-link"
                                              nextClassName="page-item"
                                              nextLinkClassName="page-link"
                                              // breakLabel = "..."
                                              breakClassName="page-item"
                                              breakLinkClassName="page-link"
                                              pageCount={selectedGroupPageCount}
                                              initialPage={selectedGroupPage}
                                              marginPagesDisplayed={1}
                                              pageRangeDisplayed={5}
                                              onPageChange={(e) =>
                                                handleSelectedGroupPageChange(e)
                                              }
                                              containerClassName="pagination"
                                              activeClassName="pageActive"
                                              forcePage={selectedGroupPage}
                                            />
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="row">
                                        {groupNumberList?.map((ele) => {
                                          return (
                                            <div className="col-md-4">
                                              <div className="tags widthFit">
                                                <img
                                                  src="assets/img/icons/close.svg"
                                                  alt=""
                                                  onClick={() =>
                                                    onDeleteGroupNumberhandler(
                                                      ele
                                                    )
                                                  }
                                                />
                                                <p>{`${ele.phone}(${ele.group})`}</p>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                      <div className="row">
                                        <p>جهات الاتصال CSV</p>
                                        <div className="details__table">
                                          {selectedCsvDetails?.length ? (
                                            <Table striped bordered hover>
                                              <thead>
                                                <tr>
                                                  <th> رقم الهاتف</th>
                                                  <th>اجراء</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {selectedCsvDetails?.map(
                                                  (ele, i) => {
                                                    return (
                                                      <tr>
                                                        <td>{ele}</td>
                                                        <td>
                                                          <button
                                                            className="btn btn-sm"
                                                            onClick={(e) =>
                                                              deleteUserCsvData(
                                                                ele,
                                                                e
                                                              )
                                                            }
                                                          >
                                                            <img
                                                              src="assets/img/icons/table-delete.png"
                                                              alt=""
                                                            />
                                                          </button>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                            </Table>
                                          ) : null}
                                        </div>
                                        <div className="paginationStyle">
                                          {selectedCsvPageCount > 1 ? (
                                            <ReactPaginate
                                              previousLabel={<>❮</>}
                                              nextLabel={<>❯</>}
                                              pageClassName="page-item"
                                              pageLinkClassName="page-link"
                                              previousClassName="page-item"
                                              previousLinkClassName="page-link"
                                              nextClassName="page-item"
                                              nextLinkClassName="page-link"
                                              // breakLabel = "..."
                                              breakClassName="page-item"
                                              breakLinkClassName="page-link"
                                              pageCount={selectedCsvPageCount}
                                              initialPage={selectedCsvPage}
                                              marginPagesDisplayed={1}
                                              pageRangeDisplayed={5}
                                              onPageChange={(e) =>
                                                handleSelectedCsvPageChange(e)
                                              }
                                              containerClassName="pagination"
                                              activeClassName="pageActive"
                                              forcePage={selectedCsvPage}
                                            />
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="tag__head">
                                          <h4>إضافة يدوية</h4>
                                        </div>
                                        {phoneNumberArray?.map((ele) => {
                                          return (
                                            <div className="col-md-3">
                                              <div className="tags">
                                                <img
                                                  src="assets/img/icons/close.svg"
                                                  alt=""
                                                  onClick={() =>
                                                    onDeleteNumberhandler(ele)
                                                  }
                                                />
                                                <p>{ele}</p>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </Tab.Content>
                            </Col>
                          </div>
                          <div className="col-md-12">
                            <Nav variant="pills" className="navigation__button">
                              <div className="row">
                                <div className="col-md-12">
                                  {counter === 1 ? null : (
                                    <Nav.Item>
                                      <Nav.Link
                                        eventKey={counter}
                                        className="btn btn-secondary"
                                        onClick={prev}
                                      >
                                        <img
                                          src="assets/img/icons/next.svg"
                                          className="next"
                                          alt=""
                                        />{" "}
                                        إلى الوراء
                                      </Nav.Link>
                                    </Nav.Item>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6 justify">
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey={counter}
                                    className="btn btn-primary"
                                    onClick={
                                      counter == 3
                                        ? () => onSendHandler()
                                        : counter == 2
                                          ? () => onCounter2Validation(counter)
                                          : counter == 1
                                            ? () => onCounter1Validation(counter)
                                            : next
                                    }
                                  >
                                    {counter == 3 ? "أرسل" : "الاستمرار"}
                                    <img
                                      src="assets/img/icons/arrowLeft.svg"
                                      alt=""
                                    />
                                  </Nav.Link>
                                </Nav.Item>
                              </div>
                            </Nav>
                          </div>
                        </div>
                      </Row>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Sidebar />
    </>
  );
};

export default Campaign;
